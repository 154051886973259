"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const text = {};
text["ERR_400"] = "Fehlerhafte Anfrage";
text["ERR_401"] = "Der Token zur Authentifizierung ist ungültig";
text["ERR_403"] = "Der Zugriff wurde verweigert";
text["ERR_UNKNOWN"] = "Unbekannter Fehler";
text["ERR_ARGS_MISSING"] = "Fehlerhafte Anfrage (Argumente fehlen)";
text["ERR_ARGS_INVALID"] = "Fehlerhafte Anfrage (Argumente ungültig)";
text["ERR_NOT_ALLOWED"] = "Nicht erlaubte Aktion";
text["ERR_IMPERSONATE_NOT_ALLOWED"] = "Impersonate nicht erlaubt";
text["ERR_USER_INVALID"] = "Ungültiger Benutzer";
text["ERR_USER_INACTIVE"] = "Inaktiver Benutzer";
text["ERR_USER_NOT_UNIQUE"] = "E-Mail-Adresse ist bereits vergeben";
text["ERR_COURSE_INVALID"] = "Ungültiger Fernlehrgang";
text["ERR_COURSE_NOT_ALLOWED"] = "Nicht zugewiesener Fernlehrgang";
text["ERR_MODULE_INVALID"] = "Ungültiges Modul";
text["ERR_MODULE_NOT_ALLOWED"] = "Nicht zugewiesenes Modul";
text["ERR_JWT_MISSING"] = "Fehlender Token zur Authentifizierung";
text["ERR_JWT_INVALID"] = "Ungültiger Token zur Authentifizierung";
text["ERR_JWT_UNKNOWN"] = "Unbekannter Token zur Authentifizierung";
text["ERR_JWT_EXPIRED"] = "Abgelaufener Token zur Authentifizierung";
text["ERR_UID_MISSING"] = "Die Sitzung ist nicht mehr gültig";
text["ERR_UID_MISSMATCH"] = "Die Sitzung ist nicht mehr gültig";
text["ERR_PRT_MISSING"] = "Fehlender Token";
text["ERR_PRT_INVALID"] = "Der Link ist ungültig";
text["ERR_PRT_UNKNOWN"] = "Der Link ist unbekannt";
text["ERR_PRT_EXPIRED"] = "Der Link ist abgelaufen";
text["ERR_PWD_INVALID"] = "Falsches Passwort";
text["ERR_PWD_WEAK"] = "Schwaches Passwort";
text["ERR_NO_ADMIN"] = "Benutzer nicht berechtigt";
function getErrorText(code) {
    if (!code)
        return code;
    const candidate = text[code];
    return candidate ? candidate : code;
}
exports.default = getErrorText;
