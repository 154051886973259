import React from "react";
import {Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTelescope} from "@fortawesome/pro-duotone-svg-icons";

interface IListViewTemplateProps {
    busy: boolean;
    redirect?: string;
    buttons?: JSX.Element;
    filter?: JSX.Element;
    info?: JSX.Element;
    header?: JSX.Element;
    rows?: JSX.Element[];
    dialog?: JSX.Element;
    pagination?: JSX.Element;
}

export const ListViewTemplate: React.FunctionComponent<IListViewTemplateProps> = (props) => {

    if (props.redirect) return <Navigate to={props.redirect}/>;

    let content: JSX.Element | undefined = undefined;
    if (props.rows && props.rows.length) {
        content = (
            <>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        {props.buttons}
                    </div>
                    <div>
                        {props.pagination}
                        <div style={{textAlign: "right"}}>
                            {props.info}
                        </div>
                    </div>
                </div>
                <table className="table is-fullwidth is-hoverable is-narrow mt-2">
                    <thead>
                    {props.header}
                    </thead>
                    <tbody>
                    {props.rows}
                    </tbody>
                </table>
            </>
        );
    } else {
        content = (
            <>
                <div>
                    <div>
                        {props.buttons}
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    paddingTop: "15vh",
                    paddingBottom: "15vh",
                }}>
                    <div style={{fontSize: "6rem"}}><FontAwesomeIcon icon={faTelescope}/></div>
                    <div style={{textAlign: "center"}}><span style={{display: "inline-block"}}>Zu Ihrer Eingabe konnten keine Daten gefunden werden.</span>
                    </div>
                </div>
            </>
        );
    }

    const blocks: JSX.Element[] = [];
    if (props.filter) {
        blocks.push(
            <div key="filter" className="box mt-5 py-5">
                {props.filter}
            </div>
        );
    }

    blocks.push(
        <div key="content" className="box mt-5 py-5">
            {content}
        </div>
    );

    return (
        <div className="container">
            {props.dialog}
            {blocks}
        </div>
    );
}

export default ListViewTemplate;
