import React from 'react';
import {Link} from 'react-router-dom';
import {get} from "../../misc/communication";
import failureController from "../../controller/failure";
import SearchText from "../../components/Search";
import {TextMarker} from "../../components/TextMarker";
import {Pagination} from "../../components/Pagination";
import {Amount} from "../../components/Amount";
import {IUser} from "@institutsitya/sitya-common/types/model/user";
import ListViewTemplate from "../../templates/ListViewTemplate";
import navigationController from "../../controller/navigation";
import {IGetGenericResponse, IStats} from "@institutsitya/sitya-common/types/api/stats";

interface IUserListViewProps {
}

interface IUserListViewState {
    busy: boolean;
    filter: string | undefined;
    redirect?: string;
    records: IUser[];
    stats?: IStats;
}

let lastFilter: string | undefined = "";
let lastLimit = 50;
let lastSkip = 0;

export class UserListView extends React.Component<IUserListViewProps, IUserListViewState> {

    private mounted = false;

    state: IUserListViewState = {
        filter: lastFilter,
        busy: true,
        records: [],
    };

    componentDidMount() {
        this.mounted = true;
        this.fetch(this.state.filter);
    }

    private async fetch(filter: string | undefined, options?: { limit?: number, skip?: number }) {
        try {

            let url = `/api/users`;
            url += `?filter=${filter}`;
            if (options?.limit !== undefined) url += `&limit=${options.limit}`;
            if (options?.skip !== undefined) url += `&skip=${options.skip}`;

            this.setState({busy: true, filter: filter});

            const records = await get(url) as IGetGenericResponse<IUser>;

            this.setState({busy: false, records: records.data, stats: records.stats});

        } catch (error) {
            failureController.failure("InvoiceListView.tsx/fetch", error);
            this.setState({busy: false, records: []});
        }
    }

    private selectRecord(record: IUser) {
        const url = `/users/detail/${record._id}`;

        navigationController
          .reset()
          .records(this.state.records.map((r) => r._id!));

        this.setState({redirect: url});
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private getFilter() {
        return (
            <SearchText filter={this.state.filter} hint="Suche nach Name oder E-Mail-Adresse"
                        onTextChanged={(filter) => {
                            this.applyFilter(filter)
                        }} autoFocus={true}/>
        );
    }

    private getButtons() {
        return (
            <Link to="/users/detail/neu" className="button is-purple">
                Neuen Benutzer anlegen
            </Link>
        );
    }

    private getHeader() {
        return (
            <tr>
                <th>Name</th>
                <th>E-Mail-Adresse</th>
                <th>Rolle</th>
                <th>Freischaltung</th>
                <th>Status</th>
            </tr>
        );
    }

    private getRows() {

        const rows = this.state.records.map((record) => {

            const role = record.role === "user" ? "Benutzer" : "Administrator";

            return (
                <tr key={record._id} onClick={() => this.selectRecord(record)} style={{cursor: "pointer"}}>
                    <td><TextMarker text={record.name} highlight={this.state.filter}/></td>
                    <td><TextMarker text={record.mail} highlight={this.state.filter}/></td>
                    <td><span>{role}</span></td>
                    <td>{record.courses.length ? <Amount value={record.courses.length} singular="Kurs" plural="Kurse"/> : <span>-</span>} </td>
                    <td><span>{record.active ? "aktiv" : "gesperrt"}</span></td>
                </tr>
            );
        });

        return rows;
    }

    private getInfo() {
        if (!this.state.stats) return undefined;
        return <Amount value={this.state.stats.count} singular="Benutzer"/>
    }

    private getPagination() {

        if (!this.state.stats) return undefined;

        return (
            <Pagination
                disabled={this.state.busy}
                limit={this.state.stats.limit}
                skip={this.state.stats.skip}
                count={this.state.stats.count}
                onChange={(limit: number, skip: number) => this.applyPagination(limit, skip)}
            />
        );
    }

    render() {
        return (<ListViewTemplate
                pagination={this.getPagination()}
                busy={this.state.busy}
                buttons={this.getButtons()}
                redirect={this.state.redirect}
                filter={this.getFilter()}
                header={this.getHeader()}
                info={this.getInfo()}
                rows={this.getRows()}/>
        );
    }

    private applyPagination(limit: number, skip: number) {
        lastLimit = limit;
        lastSkip = skip;
        this.fetch(this.state.filter, {limit: limit, skip: skip});
    }

    private applyFilter(filter: string | undefined) {
        lastFilter = filter;
        this.fetch(filter);
    }
}