import {faSearch, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import React from 'react';

interface ISearchTextState {
    text: string;
}

interface ISearchTextProps {
    autoFocus?: boolean;
    hint?: string;
    onTextChanged: (s: string) => void;
    filter?: string;
}

export default class SearchText extends React.Component<ISearchTextProps, ISearchTextState> {

    public state: ISearchTextState = {
        text: this.props.filter || ''
    }

    private ismounted = false;
    private timeout?: NodeJS.Timeout;

    private ticks = 0;
    private filter = this.props.filter || '';

    public componentDidMount() {

        this.ismounted = true;

        this.onKeyPressed.bind(this);
        this.onTextChanged.bind(this);
        this.onClear.bind(this);

        this.timeout = setInterval(() => {
            if (this.ismounted) {
                if (++this.ticks > 5) this.fire();
            }
        }, 100);
    }

    public componentWillUnmount() {

        this.ismounted = false;
        if (this.timeout) clearInterval(this.timeout);
    }

    componentDidUpdate(prevProps: Readonly<ISearchTextProps>, prevState: Readonly<ISearchTextState>, snapshot?: any) {
        if (prevProps.filter !== this.props.filter) {
            this.setState({text: this.props.filter || ""});
        }
    }

    private fire() {
        if (this.filter !== this.state.text) {
            this.ticks = 0;
            this.filter = this.state.text;
            this.props.onTextChanged(this.filter);
        }
    }

    private onKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            this.fire();
            e.preventDefault();
        }
    }

    private onTextChanged(text: string) {
        this.ticks = 0;
        this.setState({text: text});
    }

    private onClear(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        this.setState({text: ""});
        this.fire();
    }

    render() {
        return (
            <form>
                <div className="field has-addons" style={{width: "100%"}}>
                    <div className="control has-icons-left" style={{width: "100%"}}>
                        <input data-tip data-for="tooltip-quick-search" style={{marginBottom: 0}} id="filter"
                               autoComplete="off"
                               className="input block" type=" text" placeholder={this.props.hint ?? "Filter eingeben"}
                               value={this.state.text} onChange={(e) => this.onTextChanged(e.target.value)}
                               onKeyPress={(e) => this.onKeyPressed(e)}
                               autoFocus={this.props.autoFocus}
                        />
                        <span className="icon is-small is-left">
                            <FontAwesomeIcon icon={faSearch}/>
                        </span>
                    </div>
                    <div className="control">
                        <button className="button is-outlined" onClick={(e) => this.onClear(e)}
                                disabled={this.state.text.trim() === ""}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}
