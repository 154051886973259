import React from "react";
import {ReferencesContainer} from "./References";
import {HistoryContainer} from "./History";
import {IReferences} from "@institutsitya/sitya-common/types/api/references";
import {IHistory} from "@institutsitya/sitya-common/types/model/history";

interface ISideBarProps {
    references?: IReferences;
    history?: IHistory;
}

export const SideBar: React.FunctionComponent<ISideBarProps> = (props) => {
    return (
        <div style={{fontSize: "0.9rem", marginTop: "-1rem"}}>
            <ReferencesContainer references={props.references}/>
            <HistoryContainer history={props.history}/>
        </div>
    );
}