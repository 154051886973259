import React from 'react';
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {formatCurrency} from "../misc/currency";
import {post} from "../misc/communication";
import failureController from "../controller/failure";
import {Label} from "../components/Label";
import DialogTemplate from "../templates/DialogTemplate";
import {getAuthController} from "../controller/auth";

interface IOrderRefundDialogState {
    busy: boolean;
    invalid: boolean;
    value: string;
    text: string;
    valueEur?: number;
    valueInfo?: string;
    valueError?: string;
    valueType: string;
}

interface IOrderRefundDialogProps {
    order: IOrder;
    onCancel: () => void;
    onDone: () => void;
}

export class OrderRefundDialog extends React.Component<IOrderRefundDialogProps, IOrderRefundDialogState> {

    private mounted = false;

    state: IOrderRefundDialogState = {
        busy: false,
        invalid: true,
        value: "",
        valueType: "eur",
        text: "Persönliche Gutschrift"
    }

    constructor(props: IOrderRefundDialogProps) {
        super(props);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    componentDidMount() {
        // @ts-ignore
        document.addEventListener("keydown", this.onKeyPressed);
        this.mounted = true;

        this.verify(this.state.value, this.state.valueType);
    }

    componentWillUnmount() {

        this.mounted = false;

        // @ts-ignore
        document.removeEventListener("keydown", this.onKeyPressed);
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (this.mounted && (e.key === 'Escape')) this.onCancel();
    }

    private onCancel() {
        if (this.state.busy) return;
        this.props.onCancel();
    }

    private async onAction() {

        this.setState({busy: true});

        try {
            const body = {
                value: this.state.valueEur,
                text: this.state.text
            }
            await post(`/api/orders/${this.props.order._id}/refund`, body);
            this.props.onDone();
        } catch (error) {
            failureController.failure("OrderRefundDialog.tsx/onAction", error);
            this.setState({busy: false});
        }
    }

    private onValueChanged(val: string) {
        this.verify(val, this.state.valueType);
    }

    private onValueTypeChanged(val: string) {
        this.verify(this.state.value, val);
    }

    private verify(value: string, valueType: string) {

        let hint: string | undefined = undefined;
        let error: string | undefined = undefined;
        let invalid = true;

        let valueAsEur = 0;
        let valueAsPercent = 0;

        if (!value || !value.length) {
            hint = "Bitte Wert eingeben";
        } else {

            let valueInternal = value.trim().replace(".", "");
            valueInternal = valueInternal.replace(",", ".");

            let valueAsNumber = parseFloat(valueInternal);

            const valueOrder = this.props.order.totalValueGross + this.props.order.totalRefundGross;

            if (isNaN(valueAsNumber) || (valueAsNumber <= 0)) {
                error = "Bitte gültigen Wert eingeben";
            } else {
                if (valueType === "eur") {
                    if (valueOrder !== 0) {
                        valueAsEur = valueAsNumber;
                        valueAsPercent = valueAsNumber / valueOrder * 100;
                        hint = `entspricht ${(Math.round(valueAsPercent * 100) / 100).toFixed(2)}%`;
                        hint = hint.replace(".", ",");
                    } else {
                        hint = "-";
                    }

                } else {
                    valueAsEur = valueAsNumber / 100 * valueOrder;
                    hint = `entspricht ${formatCurrency(valueAsEur)}`;
                }

                if (Math.round(valueAsEur * 100) > Math.round(valueOrder * 100)) {
                    hint = "";
                    error = "Betrag zu hoch";
                } else {
                    invalid = false;
                }
            }
        }

        this.setState({
            value: value,
            valueType: valueType,
            valueInfo: hint,
            valueError: error,
            invalid: invalid,
            valueEur: valueAsEur
        });
    }

    private getContent() {
        let word = this.props.order.totalRefundGross ? " verbleibenden " : "";
        let hint = `Sie erstellen eine Gutschrift zur Bestellung ${this.props.order.name} im ${word} Wert von ${formatCurrency(this.props.order.totalValueGross + this.props.order.totalRefundGross)} brutto. Diese Aktion kann nicht rückgängig gemacht werden.`;

        let valueHint: JSX.Element;
        if (this.state.valueInfo) valueHint = <span style={{fontSize: "smaller"}}>{this.state.valueInfo}</span>;
        else valueHint =
            <span style={{fontSize: "smaller", color: "red"}}>{this.state.valueError || "Unbekannter Fehler"}</span>;

        let content: JSX.Element | undefined = undefined;
        content = (
            <div>
                <div className="mt-4">
                    <div>
                        <Label text="Text"/>
                    </div>
                    <div className="mt-1">
                        <input className="input" type="text" value={this.state.text}
                               onChange={(e) => this.setState({text: e.target.value})}/>
                    </div>
                    <div className="mt-2">
                        <Label text="Gutschrift"/>
                    </div>
                    <div className="mt-1">
                        <div className="field has-addons">
                            <div className="control" style={{width: "150px"}}>
                                <input className="input" type="text" value={this.state.value}
                                       onChange={(e) => this.onValueChanged(e.target.value)}/>
                            </div>
                            <div className="control">
                                <div className="select">
                                    <select onChange={(e) => this.onValueTypeChanged(e.target.value)}>
                                        <option value="eur">EUR brutto</option>
                                        <option value="percent">%</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: "-0.50rem"}}>
                            {valueHint}
                        </div>
                    </div>
                </div>
            </div>
        );

        return (

            <div>
                <div className="mt-5">
                    <span>{hint}</span>
                </div>
                {content}
            </div>
        );
    }

    private getButtons() {
        return (
            <div className="buttons" style={{justifyContent: "flex-end"}}>
                <button disabled={this.state.busy} className="button is-text" style={{width: "120px"}}
                        onClick={() => this.onCancel()}>Abbrechen
                </button>
                <button disabled={this.state.busy || this.state.invalid}
                        className="button is-purple"
                        style={{width: "120px"}} onClick={() => this.onAction()}>Erstellen
                </button>
            </div>
        );
    }

    render() {
        return (
            <DialogTemplate
                content={this.getContent()}
                buttons={this.getButtons()}
                title={"Gutschrift zu Bestellung " + this.props.order.name}
                onCancel={() => this.onCancel()}
            />
        );
    }
}