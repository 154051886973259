import React from 'react';
import {Label} from "../../components/Label";
import {IPayment} from "@institutsitya/sitya-common/types/model/payment";
import {formatDate} from "../../misc/date";
import {formatCurrency} from "../../misc/currency";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/pro-light-svg-icons";

interface IOrderDetailPaymentProps {
  payments: IPayment[];
  onDelete?: (id: string) => void;
}

interface IOrderDetailIPaymentState {
}

export class OrderDetailPayment extends React.Component<IOrderDetailPaymentProps, IOrderDetailIPaymentState> {

  private getRows() {
    return this.props.payments.map((p => {
      return (
        <tr key={p._id!}>
          <td>
            <span>{formatDate(p.date)}</span>
          </td>
          <td>
            <span>{p.history.createdby}</span>
          </td>
          <td>
            <span>{formatCurrency(p.amount)}</span>
          </td>
          <td style={{minWidth: "200px"}}>
            <span>{p.comment}</span>
          </td>
          <td>
            <span style={{cursor: "pointer"}} onClick={() => {
              if (this.props.onDelete) this.props.onDelete(p._id!);
            }}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </td>
        </tr>);
    }))
  }

  render() {

    if (!this.props.payments || !this.props.payments.length) {
      return <div><span style={{fontSize: "0.9rem"}}>Bisher wurden keine Zahlungen erfasst.</span></div>
    }

    return (
      <div>
        <table className="table is-narrow mt-2 is-bordered" style={{fontSize: "0.9rem"}}>
          <thead>
          <tr>
            <td><Label text="Datum"/></td>
            <td><Label text="Benutzer"/></td>
            <td><Label text="Betrag"/></td>
            <td colSpan={2} style={{minWidth: "250px"}}><Label text="Referenz"/></td>
          </tr>
          </thead>
          <tbody>
          {this.getRows()}
          </tbody>
        </table>
      </div>
    );
  }
}
