import React, {useEffect, useRef, useState} from 'react';
import Calendar from "./Calendar";
import {add, format, startOfMonth} from "date-fns";
import {de} from "date-fns/locale";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/pro-light-svg-icons";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";

interface IDatePickerProps {
    position?: "left" | "right";
    onChanged?: (date?: Date) => void;
    date?: Date;
}

interface IOverlayProps {
    onCancel: () => void;
    onOk: (date?: Date) => void;
    date?: Date;
}

export const DatePicker: React.FunctionComponent<IDatePickerProps> = (props) => {

    const [overlay, setOverlay] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const handler = (e: MouseEvent) => {
            if (ref.current && e.target && e.target instanceof Node) {
                if (!ref.current.contains(e.target as Node)) {
                    setOverlay(false);
                }
            } else {
                setOverlay(false);
            }
        }

        window.addEventListener("click", handler);

        return () => {
            window.removeEventListener("click", handler);
        }
    });

    const style: React.CSSProperties = {
        position: "absolute",
        top: "-120px",
        zIndex: 10
    };

    if (props.position === "left") {
        style.right = "calc(100% + 5px)";
    } else {
        style.left = "calc(100% + 5px)";
    }

    const o = overlay ? <div style={style}><Overlay
        date={props.date}
        onCancel={() => setOverlay(false)}
        onOk={(date?: Date) => {
            setOverlay(false);
            if (props.onChanged) props.onChanged(date);
        }}
    /></div> : undefined;

    let value = "";
    if (props.date) value = format(props.date, "dd.MM.yyyy");

    return (
        <div ref={ref} className="field has-addons" style={{position: "relative"}}>
            <div className="control">
                <input style={{width: "150px"}} readOnly={true} className="input" type="text" placeholder=""
                       value={value}/>
            </div>
            <div className="control">
                <button onClick={() => {
                    setOverlay(!overlay);
                }} className="button">
                    <FontAwesomeIcon icon={faCalendar}/>
                </button>
            </div>
            {o}
        </div>
    );
}

export const Overlay: React.FunctionComponent<IOverlayProps> = (props) => {

    const [month, setMonth] = useState<Date>(add(startOfMonth(props.date || new Date()), {months: 0, days: 10}));

    return (
        <div className="p-4" style={{background: "white", border: "1px solid gainsboro", borderRadius: "3px"}}>
            <div style={{display: "flex", userSelect: "none"}}>
                <div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div>
                            <button className="button is-small" onClick={() => {
                                setMonth(add(month, {months: -1}));
                            }}><FontAwesomeIcon size="xs" icon={faChevronLeft}/>
                            </button>
                            <button className="ml-2 button is-small" onClick={() => {
                                setMonth(add(month, {months: +1}));
                            }}><FontAwesomeIcon size="xs" icon={faChevronRight}/>
                            </button>
                        </div>
                        <span className="ml-2">{format(month, "MMMM yyyy", {locale: de})}</span>
                    </div>
                    <Calendar month={month} start={props.date} selected={true} onSelect={(date) => {
                        props.onOk(date);
                    }}/>
                </div>
            </div>
        </div>
    );
}
export default DatePicker;