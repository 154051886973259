import React from 'react';
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {SELBSTSTUDIUM} from "../../controller/courses";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {Label} from "../../components/Label";

interface IOrderDetailCoursesProps {
    order: IOrder;
    courses: ICourse[];
}

interface IOrderDetailCoursesState {
}

export class OrderDetailCourses extends React.Component<IOrderDetailCoursesProps, IOrderDetailCoursesState> {

    render() {

        const positions = this.props.order.courses.map((pos) => {

            const key = (pos.key === "humanenergetiker") ? "humanenergetiker-6" : pos.key;
            const name = this.props.courses!.find((c) => c.key === key)?.name || "Fernlehrgang";
            const style = (pos.style === SELBSTSTUDIUM) ? "im Selbststudium" : "mit Lernbegleitung";

            return (
                <tr key={key}>
                    <td>
                        <span>{name}</span>
                    </td>
                    <td>
                        <span>{style}</span>
                    </td>
                </tr>);
        });

        return (
            <div>
                <table className="table is-narrow mt-2 is-bordered" style={{fontSize: "0.9rem"}}>
                    <thead>
                    <tr>
                        <td><Label text="Fernlehrgang"/></td>
                        <td><Label text="Studienart"/></td>
                    </tr>
                    </thead>
                    <tbody>
                    {positions}
                    </tbody>
                </table>
            </div>
        );
    }
}

