import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faAngleDoubleRight, faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";

interface IPaginationProps {
    limit: number;
    count: number;
    skip: number;
    disabled?: boolean;
    onChange?: (limit: number, skip: number) => void;
}

export const Pagination: React.FunctionComponent<IPaginationProps> = (props) => {

    const pages = Math.ceil(props.count / props.limit);
    const page = Math.ceil(props.skip / props.limit);

    const links: JSX.Element[] = [];

    const targets: number[] = [];

    targets.push(1);
    const width = 3;
    for (let i = page - width; i <= (page + width + 2); i++) {
        if ((i > 1) && (i < pages)) targets.push(i);
    }
    if (pages > 1) targets.push(pages);

    let last = -1;

    for (let i = 0; i < targets.length; i++) {

        let cn = "";
        if ((last >= 0) && (last + 1 !== targets[i])) cn = "ml-2";
        last = targets[i];

        links.push(
            <button
                key={`pagination-page-${i}`}
                style={{width: "30px"}}
                className={`${cn} mr-1 button is-small ${(targets[i] === page + 1) ? "is-purple" : ""}`}
                onClick={() => props.onChange && props.onChange(props.limit, (targets[i] - 1) * props.limit)}>
                <span>{targets[i]}</span>
            </button>
        );
    }

    let prev: JSX.Element;
    let next: JSX.Element;

    prev = (
        <button
            disabled={page === 0}
            className="button is-small mr-1"
            onClick={() => props.onChange && props.onChange(props.limit, (page - 1) * props.limit)}>
            <span className="icon" style={{color: "#444"}}><FontAwesomeIcon size="sm" icon={faChevronLeft}/></span>
        </button>
    );

    next = (
        <button
            disabled={page + 1 >= pages}
            className="button is-small"
            onClick={() => props.onChange && props.onChange(props.limit, (page + 1) * props.limit)}>
            <span className="icon" style={{color: "#444"}}><FontAwesomeIcon size="sm" icon={faChevronRight}/></span>
        </button>
    );

    return (
        <div style={{display: "flex", justifyContent: "flex-end"}}>
            {links}
            <div className="ml-2">
                {prev}
                {next}
            </div>
        </div>
    );
}