const iso3166_de = require("i18n-iso-countries/langs/de.json");

export function getCountryName(key: string) {

    if (!key) return "";

    const code = key.toUpperCase().trim();

    if (code === "GB-NIR") return "Nordirland";
    if (code === "PT-20") return "Portugal (Azoren)";
    if (code === "PT-30") return "Portugal (Madeira)";

    const country = iso3166_de.countries[key];
    if (!country) return key;
    if (Array.isArray(country)) return country[0];
    return country;
}

export function getCountries() {

    const countries: {key: string, name: string}[] = [];

    countries.push({key: "gb-nir", name: "Nordirland"});
    countries.push({key: "pt-20", name: "Portugal (Azoren)"});
    countries.push({key: "pt-30", name: "Portugal (Madeira)"});

    for (const [key, value] of Object.entries(iso3166_de.countries)) {
        if (Array.isArray(value)) countries.push({key: key, name: value[0] as string});
        else countries.push({key: key, name: value as string});
    }

    return countries;
}