import React from 'react';
import {get} from "../../misc/communication";
import {Spinner} from "../../components/Spinner";
import failureController from './../../controller/failure';
import {getCourses} from "../../controller/courses";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {IActivity} from "@institutsitya/sitya-common/types/model/activity";
import {FormatterFactory} from "./ActivityFormatter";
import {Label} from "../../components/Label";

interface IContactDetailOrdersProps {
    uid: string;
}

interface IContactDetailOrdersState {
    busy: boolean;
    courses?: ICourse[];
    activities?: IActivity[];
}

export class ContactDetailActivities extends React.Component<IContactDetailOrdersProps, IContactDetailOrdersState> {

    private mounted = false;

    state: IContactDetailOrdersState = {
        busy: true
    };

    componentDidMount() {

        this.mounted = true;
        this.fetch();
    }

    componentDidUpdate(prevProps: Readonly<IContactDetailOrdersProps>, prevState: Readonly<IContactDetailOrdersState>, snapshot?: any) {
        if (prevProps.uid !== this.props.uid) {
            this.fetch();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private async fetch() {
        try {

            const p1 = get(`/api/contacts/${this.props.uid}/activities`);
            const p2 = getCourses();

            const [activities, courses] = await Promise.all([p1, p2]);

            this.setState({busy: false, activities: activities, courses: courses});

        } catch (error) {
            failureController.failure("ContactDetailActivities.tsx/fetch", error);
            this.setState({busy: false});
        }
    }

    render() {

        let content: JSX.Element;

        if (!this.state.activities || !this.state.courses) {
            content =
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}><Spinner text="Daten werden geladen"/></div>;
        } else {
            const tableRows: JSX.Element[] = [];

            let idx = 0;

            for (const a of this.state.activities) {
                const formatter = FormatterFactory.getFormatter(a, this.state.courses);
                const rows = formatter.getRows(idx);
                idx += rows.length;
                tableRows.push(...rows);
            }

            content = <div>
                <table className="table is-narrow is-bordered" style={{fontSize: "0.9rem"}}>
                    <thead>
                    <tr>
                        <td><Label text="Zeitpunkt"/></td>
                        <td><Label text="Benutzer"/></td>
                        <td><Label text="Aktion"/></td>
                        <td><Label text="Details"/></td>
                    </tr>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </table>
            </div>;
        }

        return (
            <>
                {content}
            </>
        );
    }
}