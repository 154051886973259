import React from "react";
import {IHistory} from "@institutsitya/sitya-common/types/model/history";
import {IReferences} from "@institutsitya/sitya-common/types/api/references";
import {Spinner} from "../components/Spinner";
import {RecordInfo} from "../components/RecordInfo";
import {Link, Navigate} from "react-router-dom";
import {RecordHeader} from "../components/RecordHeader";
import {SideBar} from "../components/SideBar";
import {faArrowAltToTop, faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getErrorText from "@institutsitya/sitya-common/misc/errors";
import {usePrompt} from "./blocker";
import navigationController, {INavigationController} from "../controller/navigation";

export interface ITag {
    text: string;
    color: string;
}

interface IDetailViewTemplateProps {
    busy: boolean;
    dirty?: boolean;
    title?: string;
    link?: string;
    info?: string;
    tags?: ITag[];
    hint?: string;
    redirect?: string;
    id?: string;
    buttons?: JSX.Element;
    content?: JSX.Element;
    dialog?: JSX.Element;
    history?: IHistory;
    references?: IReferences;

    onNavigate?: (id: string) => void;
}

export const DetailViewTemplate: React.FunctionComponent<IDetailViewTemplateProps> = (props) => {

    usePrompt('Sie haben Ihre Eingabe noch nicht gespeichert. Wollen Sie die Seite wirklich verlassen?', props.dirty ?? false);

    if (props.redirect) return <Navigate to={props.redirect}/>;

    if (props.busy) {
        return (
          <div style={{
              width: "100%",
              height: "calc(100vh - 300px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
          }}>
              <Spinner text="Daten werden geladen"/>
          </div>
        );
    }

    const recordInfo = (props.history || (props.hint !== undefined)) ?
      <RecordInfo history={props.history} hint={getErrorText(props.hint)}/> : undefined;
    const sidebar = (props.history || (props.references)) ?
      <SideBar history={props.history} references={props.references}/> : undefined;
    const gridTemplateColumns = sidebar ? "1fr auto" : "1fr";

    const back = props.link ? <Link className="button" to={props.link}><FontAwesomeIcon size="sm" icon={faArrowAltToTop}/></Link> : undefined;

    const top = props.buttons || recordInfo;
    const bottom = props.buttons ? recordInfo : undefined;

    let left: JSX.Element | undefined = undefined;
    let right: JSX.Element | undefined = undefined;

    if (props.onNavigate && props.id && navigationController.has(props.id)) {

        const next = navigationController.hasNext(props.id);
        const prev = navigationController.hasPrevious(props.id);

        left = <button onClick={() => {
          const target = navigationController.getPrevious(props.id!);
          if (target) props.onNavigate!(target);
        }} disabled={!prev || props.dirty} className="button ml-2"><FontAwesomeIcon size="sm" icon={faChevronLeft}/></button>;

        right = <button onClick={() => {
          const target = navigationController.getNext(props.id!);
          if (target) props.onNavigate!(target);
        }} disabled={!next || props.dirty} className="button ml-1"><FontAwesomeIcon size="sm" icon={faChevronRight}/></button>;
    }

    return (
      <div className="container">
          {props.dialog}
          <div className="mt-5" style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
              <div>
                  {top}
              </div>
              <div style={{display: "flex", justifyContent: "flex-end", alignItems: "flex-end"}}>
                  {back}
                  {left}
                  {right}
              </div>
          </div>

          {bottom}

          <div className="mt-4"
               style={{display: "grid", gridTemplateColumns: gridTemplateColumns, gridColumnGap: "1.25rem"}}>
              <div className="mb-0 box">
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                      <RecordHeader title={props.title || ""} info={props.info}/>
                      <div>
                          {props.tags?.map((t) => <span className={`ml-2 tag is-${t.color}`}>{t.text}</span>)}
                      </div>
                  </div>
                  <div className="mt-5 mb-2" style={{borderBottom: "2px solid #f5f5f5"}}></div>
                  {props.content}
              </div>
              {sidebar}
          </div>
      </div>
    );
};

export default DetailViewTemplate;
