import React from 'react';
import {useParams} from 'react-router-dom';
import {get} from "../../misc/communication";
import failureController from './../../controller/failure';
import {IReferences} from "@institutsitya/sitya-common/types/api/references";
import {formatDate} from "../../misc/date";
import {IContact} from "@institutsitya/sitya-common/types/model/contact";
import {getContactMail, getContactName} from "../../controller/contacts";
import {getCountryName} from "../../misc/country";
import {ContactDetailOrders} from "./ContactDetailOrders";
import {ContactDetailActivities} from "./ContactDetailActivities";
import {Label} from "../../components/Label";
import DetailViewTemplate, {ITag} from "../../templates/DetailViewTemplate";
import {ContactEditDialog} from "../../dialogs/ContactEditDialog";

interface IContactDetailViewProps {
    uid: string;
}

interface IContactDetailViewState {
    busy: boolean;
    uid: string;
    redirect?: string;
    contact?: IContact;
    editing?: boolean;
    selectedTab: "orders" | "activities";
    references?: IReferences;
}

export const ContactDetailViewWrapper: React.FunctionComponent = (props) => {
    const {uid} = useParams();
    if (uid) return <ContactDetailView uid={uid}/>;
    return <div/>;
}

export class ContactDetailView extends React.Component<IContactDetailViewProps, IContactDetailViewState> {

    private mounted = false;

    state: IContactDetailViewState = {
        uid: this.props.uid,
        busy: true,
        editing: false,
        selectedTab: "orders"
    };

    componentDidMount() {

        this.mounted = true;
        this.fetch(this.props.uid);
    }

    componentDidUpdate(prevProps: Readonly<IContactDetailViewProps>, prevState: Readonly<IContactDetailViewState>, snapshot?: any) {
        if (prevProps.uid !== this.props.uid) {
            this.fetch(this.props.uid);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private async fetch(uid: string) {
        try {

            const p1 = get(`/api/contacts/${uid}`);
            const p2 = get(`/api/contacts/${uid}/references`);

            const [contact, references] = await Promise.all([p1, p2]);

            this.setState({busy: false, uid: uid, contact: contact, references: references});

        } catch (error) {
            failureController.failure("ContactDetailView.tsx/fetch", error);
            this.setState({busy: false});
        }
    }

    private getContent() {

        if (!this.state.contact) return undefined;

        let tab: JSX.Element | undefined;
        if (this.state.selectedTab === "orders") tab = <ContactDetailOrders uid={this.state.uid}/>;
        if (this.state.selectedTab === "activities") tab = <ContactDetailActivities uid={this.state.uid}/>;

        const address: JSX.Element[] = [];

        if (this.state.contact.postalCode && this.state.contact.city) {
            address.push(
                <div key="address-city">
                    <span>{`${this.state.contact.postalCode} ${this.state.contact.city}`}</span>
                </div>
            );
        }

        if (this.state.contact.country) {
            address.push(
                <div key="address-country">
                    <span>{getCountryName(this.state.contact.country)}</span>
                </div>
            );
        }

        return (
            <div>
                <div style={{display: "flex"}}>
                    <div className="pr-6 mr-6" style={{borderRight: "2px solid #f5f5f5"}}>
                        <Label text="Adresse"/>
                        <div>
                            <span>{this.state.contact.fullName}</span>
                        </div>
                        <div>
                            <span>{this.state.contact.street}</span>
                        </div>
                        {address}
                    </div>
                    <div className="pr-6 mr-6">
                        <Label text="Geburtsdatum"/>
                        <div>{this.state.contact.birthDay ? formatDate(this.state.contact.birthDay) : "-"}</div>

                        <Label className="mt-2" text="Kontakt"/>
                        <div><span>{this.state.contact.phone}</span></div>
                        <div><span>{this.state.contact.mail}</span></div>
                    </div>
                </div>
                <div className="mt-2 tabs">
                    <ul>
                        <li className={this.state.selectedTab === "orders" ? "is-active" : ""}><a
                            onClick={(e) => this.setState({selectedTab: "orders"})}>Bestellungen
                        </a></li>
                        <li className={this.state.selectedTab === "activities" ? "is-active" : ""}><a
                            onClick={(e) => this.setState({selectedTab: "activities"})}>
                            Aktivitäten
                        </a></li>
                    </ul>
                </div>
                {tab}
            </div>
        );
    }

    private getButtons() {

        return (
            <div className="buttons">
                <button onClick={() => this.setState({editing: true})}
                        className="button" style={{width: "150px"}}>Bearbeiten
                </button>
            </div>
        );
    }

    private getDialog() {

        if (!this.state.contact) return undefined;

        if (this.state.editing) {
            return <ContactEditDialog contact={this.state.contact}
                                      onDone={() => {
                                          this.setState({editing: false});
                                          this.fetch(this.state.uid);
                                      }}
                                      onCancel={() => this.setState({editing: false})}
            />;
        }

        return undefined;
    }

    render() {

        const tags: ITag[] = [];
        if (this.state.contact?.category === "customer") tags.push({color: "purple", text: "Kunde"});
        if (this.state.contact?.category === "lead") tags.push({color: "dark", text: "Interessent"});

        const name = getContactName(this.state.contact || this.props.uid) || "Kontakt";
        let info = `${getContactMail(this.state.contact || this.props.uid)} | ${this.state.contact?.person}`;

        return <DetailViewTemplate
            busy={this.state.busy}
            redirect={this.state.redirect}
            references={this.state.references}
            id={this.state.uid}
            onNavigate={async (uid: string) => {
                await this.fetch(uid);
                window.history.pushState({}, "", `/contacts/detail/${uid}`);
            }}
            history={this.state.contact?.history}
            title={name}
            tags={tags}
            info={info}
            link="/contacts/list"
            buttons={this.getButtons()}
            dialog={this.getDialog()}
            content={this.getContent()}
        />;
    }
}