import * as uuid from 'uuid';
import {getAuthController} from './../controller/auth';

export interface IUpload {
    uid: string;
    size: number;
    name: string;
    target: string | undefined;
    status: 'pending' | 'active' | 'done' | 'failed';
    message?: string;
    progress: number;
    lastModified: Date;
}

export type EventType = 'change';

type CallBackType = (err: any, data?: IUpload) => void;

const isdevelopment =  (process.env.NODE_ENV !== 'production');
export const host = isdevelopment ? "http://localhost" : "";

export default class Uploader {

    uploads: IUpload[] = [];
    onChangeListeners: CallBackType[] = [];

    files: { [uid: string]: File } = {};
    requests: { [uid: string]: XMLHttpRequest } = {};

    cancelled = false;

    public constructor(files: FileList, unavailableFileNames?: string[]) {

        for (let i = 0; i < files.length; i++) {

            const f = files.item(i);

            if (f) {

                const uid = uuid.v4();
                const upload: IUpload = {
                    lastModified: new Date(f.lastModified),
                    uid: uid,
                    size: f.size,
                    name: f.name,
                    status: 'pending',
                    message: '',
                    target: undefined,
                    progress: 0
                }

                if (f.size > 20 * 1024 *1024) {
                    upload.status = 'failed';
                    upload.message = 'Datei zu groß';
                }

                if (f.size <= 0) {
                    upload.status = 'failed';
                    upload.message = 'Datei leer';
                }

                if (unavailableFileNames && unavailableFileNames.find((u) => u === f.name)) {
                    upload.status = 'failed';
                    upload.message = 'Dateiname Konflikt';
                }

                this.uploads.push(upload);
                this.files[uid] = f;
            }
        }
    }

    public reset() {
        this.uploads = [];
        this.onChangeListeners = [];
        this.files = {};
    }

    public cancel() {

        this.cancelled = true;

        this.uploads
            .filter((u) => u.status !== 'done' && u.status !== 'failed')
            .forEach((u) => {

                if (u.status === 'pending') {
                    u.status = 'failed';
                    u.message = 'Abgebrochen';
                    this.emitOnChange(u);
                }

                else if (u.status === 'active') {

                    const xhr = this.requests[u.uid];
                    if (xhr) xhr.abort();
                }
            });
    }

    public isFinished() {
        return this.uploads.every((upload) => (upload.status === 'failed' || upload.status === 'done'));
    }

    public isCancelled() {
        return this.cancelled;
    }

    public getUploads() {
        return JSON.parse(JSON.stringify(this.uploads)) as IUpload[];
    }

    private emitOnChange(upload?: IUpload) {
        this.onChangeListeners.forEach((cb) => cb(null, upload));
    }

    public on(event: EventType, callback: CallBackType): (() => void) {

        this.onChangeListeners.push(callback);

        return () => {
            const index = this.onChangeListeners.indexOf(callback);
            if (index >= 0) this.onChangeListeners.splice(index, 1);
        };
    }

    private async upload(upload: IUpload) {

        try {

            if (upload.status === 'pending') {

                upload.status = 'active';
                this.emitOnChange(upload);

                const formData = new FormData();
                formData.append('file', this.files[upload.uid]);

                const request = new XMLHttpRequest();
                this.requests[upload.uid] = request;
                
                request.onload = (e) => {

                    var status = request.status;
                    
                    if (status === 200) {
                        const res = JSON.parse(request.responseText);
                        upload.status = 'done';
                        upload.target = res.filename;
                    } else {
                        upload.status = 'failed';
                        upload.message = `Fehlgeschlagen (${status})`;
                        upload.target = undefined;
                    }

                    upload.progress = upload.size;

                    this.emitOnChange(upload);
                    this.next();
                }

                const onAbort = () => {
                    upload.status = 'failed';
                    upload.message = 'Abgebrochen';
                    this.emitOnChange(upload);
                }

                const onError = (e: ProgressEvent<EventTarget>) => {
                    upload.status = 'failed';
                    upload.message = "Fehlgeschlagen";
                    this.emitOnChange(upload);
                    this.next();
                }

                request.onerror = onError;
                request.upload.onerror = onError;

                request.onabort = onAbort;
                request.upload.onabort = onAbort;

                request.upload.onprogress = (e) => {

                    upload.progress = e.loaded;
                    this.emitOnChange(upload);
                };

                request.open('POST', `${host}/api/upload/elearning`);
                request.setRequestHeader('Authorization', `Bearer ${await getAuthController().getAccessToken()}`);
                request.send(formData);
            }

        } catch (error) {

            console.error(error);
            upload.status = 'failed';
            this.emitOnChange(upload);
            this.next();
        }
    }

    private next() {
        const upload = this.uploads.find((upload) => upload.status === 'pending');
        if (upload) this.upload(upload);
    }

    public start() {

        this.uploads.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        let started = 0;

        for (let i = 0; i < this.uploads.length; i++) {
            
            const u = this.uploads[i];
            if (u.status === 'pending') {
                this.upload(u);
                if (++started >= 5) break;
            }
        }

        if (started === 0) this.emitOnChange(undefined);
    }
}