import React from 'react';
import {useParams} from 'react-router-dom';
import {get, host} from "../../misc/communication";
import failureController from './../../controller/failure';
import {IInvoice} from "@institutsitya/sitya-common/types/model/invoice";
import {getInvoiceDate} from "../../controller/invoices";
import {IReferences} from "@institutsitya/sitya-common/types/api/references";
import {formatDate, formatDateTime} from "../../misc/date";
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/pro-light-svg-icons";
import {Currency} from "../../components/Currency";
import {Label} from "../../components/Label";
import DetailViewTemplate from "../../templates/DetailViewTemplate";

interface IInvoiceDetailViewProps {
    uid: string;
}

interface IInvoiceDetailViewState {
    uid: string;
    busy: boolean;
    redirect?: string;
    invoice?: IInvoice;
    order?: IOrder;
    references?: IReferences;
}

export const InvoiceDetailViewWrapper: React.FunctionComponent = (props) => {
    const {uid} = useParams();
    if (uid) return <InvoiceDetailView uid={uid}/>;
    return <div/>;
}

export class InvoiceDetailView extends React.Component<IInvoiceDetailViewProps, IInvoiceDetailViewState> {

    private mounted = false;

    state: IInvoiceDetailViewState = {
        uid: this.props.uid,
        busy: true
    };

    componentDidMount() {

        this.mounted = true;
        this.fetch(this.props.uid);
    }

    componentDidUpdate(prevProps: Readonly<IInvoiceDetailViewProps>, prevState: Readonly<IInvoiceDetailViewState>, snapshot?: any) {
        if (prevProps.uid !== this.props.uid) {
            this.fetch(this.props.uid);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private async fetch(uid: string) {
        try {

            const p1 = get(`/api/invoices/${uid}`);
            const p2 = get(`/api/invoices/${uid}/references`);

            const [invoice, references] = await Promise.all([p1, p2]);

            this.setState({busy: false, uid: uid, invoice: invoice, order: invoice.order, references: references});

        } catch (error) {
            failureController.failure("InvoiceDetailView.tsx/fetch", error);
            this.setState({busy: false});
        }
    }

    private getMainSection() {

        if (!this.state.invoice) return undefined;

        let vatInfo: JSX.Element | undefined;

        if (this.state.invoice.vatId) {
            vatInfo = <>
                <Label className="mt-4" text="UID"/>
                <div>
                    <span>{this.state.invoice.vatId}</span>
                </div>
            </>;
        }

        return (
            <div>
                <div style={{display: "flex"}}>
                    <div className="pr-6 mr-6" style={{borderRight: "2px solid #f5f5f5"}}>
                        <Label text="Adresse"/>
                        <>{this.state.invoice.address.split('\n').map((line, idx) => <div
                            key={`address-line-${idx}`}>{line}</div>)}</>
                        {vatInfo}
                    </div>
                    <div className="pr-6 mr-6" style={{borderRight: "2px solid #f5f5f5"}}>
                        <Label text="Rechnungsdatum"/>
                        <div>
                            <span>{formatDate(this.state.invoice.invoiceDate)}</span>
                        </div>
                        <Label className="mt-4" text="Zahlungsziel"/>
                        <div>
                            <span>{formatDate(this.state.invoice.targetDate)}</span>
                        </div>
                    </div>
                    <div className="pr-6 mr-6" style={{borderRight: "2px solid #f5f5f5"}}>
                        <Label text="Summe Netto"/>
                        <div>
                            <Currency value={this.state.invoice.totalNet}/>
                        </div>
                        <Label className="mt-4" text="Summe Brutto"/>
                        <div>
                            <Currency value={this.state.invoice.totalGross}/>
                        </div>
                    </div>
                    <div className="mr-6">
                        <Label className="" text="Rechnung"/>
                        <div>
                            <a className="link"
                               href={`${host}/api/invoices/${this.state.invoice._id}/pdf`}
                               target="_blank" rel="noopener noreferrer">
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span className="icon is-small is-left mr-2"
                                          style={{marginBottom: "-0.1rem"}}><FontAwesomeIcon icon={faFilePdf}/></span>
                                    <span>{`${this.state.invoice.name}.pdf`}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    private getPositions() {

        if (!this.state.invoice) return undefined;

        const positions: JSX.Element[] = [];
        this.state.invoice.positions.forEach((pos, i) => {

            let gross = pos.price * pos.amount * (1 + pos.tax / 100);
            let net = pos.price * pos.amount;

            let text: JSX.Element[] = [<div>{pos.text}</div>]
            if (pos.information && pos.information.length) {
                pos.information.forEach((p, j) => {
                    text.push(<div key={`pos-${i}-info-${j}`} style={{fontSize: "smaller"}}><span>{p}</span></div>);
                });
            }

            pos.discounts?.forEach((discount, j) => {
                gross += (discount.amount * discount.price * (1 + pos.tax / 100));
                net += (discount.amount * discount.price);
                text.push(<div key={`pos-${i}-discount-text-${j}`} style={{fontSize: "smaller"}}>
                    <span>{discount.text}</span></div>);
            });

            positions.push(
                <tr key={`pos-${i}`}>
                    <td>
                        <span>{pos.pos}</span>
                    </td>
                    <td>
                        {text}
                    </td>
                    <td style={{textAlign: "right"}}>
                        <span>{pos.tax}%</span>
                    </td>
                    <td style={{textAlign: "right"}}>
                        <Currency value={net}/>
                    </td>
                    <td style={{textAlign: "right"}}>
                        <Currency value={gross}/>
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <div style={{fontSize: "larger"}}>
                    <span>Positionen</span>
                </div>
                <div>
                    <table className="table is-narrow mt-2 is-bordered" style={{fontSize: "0.9rem"}}>
                        <thead>
                        <tr>
                            <td><Label text="Position"/></td>
                            <td><Label text="Beschreibung"/></td>
                            <td style={{textAlign: "right"}}><Label text="Steuer"/></td>
                            <td style={{textAlign: "right"}}><Label text="Netto"/></td>
                            <td style={{textAlign: "right"}}><Label text="Brutto"/></td>
                        </tr>
                        </thead>
                        <tbody>
                        {positions}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    private getContent() {
        return (
            <>
                <div className="mt-5">
                    {this.getMainSection()}
                </div>
                <div className="mt-5">
                    {this.getPositions()}
                </div>
            </>
        );
    }

    render() {

        let type = "Rechnung";
        if (this.state.invoice?.type === "cancellation") type = "Storno";
        if ((this.state.invoice?.type as any) === "refund") type = "Gutschrift";

        const name = type + " " + this.state.invoice?.name;
        const date = getInvoiceDate(this.state.invoice || this.props.uid);
        const info = date ? formatDateTime(date) : undefined;

        return <DetailViewTemplate
            busy={this.state.busy}
            redirect={this.state.redirect}
            content={this.getContent()}
            id={this.state.uid}
            onNavigate={async (uid: string) => {
                await this.fetch(uid);
                window.history.pushState({}, "", `/invoices/detail/${uid}`);
            }}
            history={this.state.invoice?.history}
            references={this.state.references}
            title={name}
            info={info}
            link="/invoices/list"
        />;
    }
}