// Create a super simple hash
// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export default function hash(text: string) {
    var hash = 0, i, chr;
    for (i = 0; i < text.length; i++) {
        chr = text.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return hash;
}