export function formatCurrency(val: number, symbol: boolean = true) {

    const negative = (val < 0);

    val = Math.round(val * 100) / 100;
    val = Math.abs(val);

    const millions = Math.floor(val / 1000000);
    const thousands = Math.floor((val - millions * 1000000) / 1000);
    const rest = Math.floor(val - millions * 1000000 - thousands * 1000);
    const cents = Math.round((val - Math.floor(val)) * 100);

    let result = "";
    if (millions) result = `${millions}.${thousands.toString().padStart(3, "0")}.${rest.toString().padStart(3, "0")},${cents.toString().padStart(2, "0")}`;
    else if (thousands) result = `${thousands}.${rest.toString().padStart(3, "0")},${cents.toString().padStart(2, "0")}`;
    else result = `${rest.toString()},${cents.toString().padStart(2, "0")}`;

    if (symbol) result += " €";

    if (negative) return `-${result}`;
    else return result;
}