import React from 'react';
import {get} from "../../misc/communication";

import format from 'date-fns/format'
import {getCourses, SELBSTSTUDIUM} from "../../controller/courses";
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {getOrderName, getStatusColor, getStatusText, rememberOrder} from "../../controller/orders";
import failureController from "../../controller/failure";
import {TextMarker} from "../../components/TextMarker";
import {Pagination} from "../../components/Pagination";
import {formatCurrency} from "../../misc/currency";
import {Currency} from "../../components/Currency";
import {Amount} from "../../components/Amount";
import {Status} from "../../components/Status";
import ListViewTemplate from "../../templates/ListViewTemplate";
import {Filter} from "../../components/Filter";
import {FilterController, IFilter, IFilterObserver} from "../../controller/filter";
import {add, startOfDay, startOfMonth, startOfToday, startOfWeek} from "date-fns";

import navigationController from "../../controller/navigation";
import {IGetGenericResponse, IStats} from "@institutsitya/sitya-common/types/api/stats";
import {hasFeature} from "../../misc/features";

interface IOrderListViewProps {
}

interface IOrderListViewState {
    busy: boolean;
    extended: boolean;

    redirect?: string;
    orders: IOrder[];
    courses: ICourse[];
    stats?: IStats;
}

let lastLimit = 50;
let lastSkip = 0;

export class OrderListView extends React.Component<IOrderListViewProps, IOrderListViewState> implements IFilterObserver {

    private mounted = false;
    private filterController = new FilterController("orders");

    state: IOrderListViewState = {
        extended: false,
        busy: true,
        orders: [],
        courses: []
    };

    public constructor(props: IOrderListViewProps) {
        super(props);

        const now = new Date();

        this.filterController.addPreset("today", "Heute", [{name: "start", value: startOfToday().toISOString()}]);
        this.filterController.addPreset("yesterday", "Gestern", [{
            name: "start",
            value: startOfDay(add(now, {days: -1})).toISOString()
        }, {name: "end", value: startOfToday().toISOString()}]);
        this.filterController.addPreset("week", "Woche", [{
            name: "start",
            value: startOfWeek(now, {weekStartsOn: 1}).toISOString()
        }]);
        this.filterController.addPreset("month", "Monat", [{name: "start", value: startOfMonth(now).toISOString()}]);

        /*
        this.filterController.addPreset("pending", "Offen", [{name: "payment", value: "pending"}]);
        this.filterController.addPreset("overdue", "Überfällig", [{name: "payment", value: "overdue"}]);
        */

        this.filterController.addDate({mode: "range", key: "orderDate", start: "start", end: "end", name: "Datum"});

        const values = [{key: "prepared", value: "Vorbereitet"}, {key: "created", value: "Neu"}];
        values.push({key: "active", value: "Bezahlt"});
        values.push({key: "cancelled", value: "Storniert"});
        this.filterController.addSelector({key: "status", name: "Status", values});

        this.filterController.addSelector({
            key: "country", name: "Land", values: [
                {key: "at", value: "Österreich"},
                {key: "de", value: "Deutschland"},
                {key: "ch", value: "Schweiz"},
                {key: "it", value: "Italien"}
            ]
        });
    }

    componentDidMount() {
        this.mounted = true;
        this.filterController.addHandler(this);
        this.fetch(this.filterController.getFilter());
    }

    componentWillUnmount() {
        this.mounted = false;
        this.filterController.removeHandler(this);
    }

    onFilterChanged(): void {
        this.fetch(this.filterController.getFilter());
    }

    private async fetch(filter: IFilter[], options?: { limit?: number, skip?: number }) {
        try {

            let url = `/api/orders`;
            let first = true;

            const addParam = (name: string, value: any) => {
                url += first ? "?" : "&";
                url += `${name}=${value}`;
                first = false;
            }

            for (const f of filter) addParam(f.name, f.value);
            if (options?.limit !== undefined) addParam("limit", options.limit);
            if (options?.skip !== undefined) addParam("skip", options.skip);

            const p1 = get(url) as Promise<IGetGenericResponse<IOrder>>;
            const p2 = getCourses();

            const [orders, courses] = await Promise.all([p1, p2]);

            this.setState({busy: false, orders: orders.data, courses: courses, stats: orders.stats});

        } catch (error) {
            failureController.failure("OrderDetailView.tsx/fetch", error);
            this.setState({busy: false, orders: [], courses: []});
        }
    }

    private selectOrder(order: IOrder) {

        rememberOrder(order);

        navigationController
            .reset()
            .records(this.state.orders.map((o) => o._id!));

        const url = `/orders/detail/${order._id}`;
        this.setState({redirect: url});
    }

    private getPagination() {

        if (!this.state.stats) return undefined;

        return (
            <Pagination
                disabled={this.state.busy}
                limit={this.state.stats.limit}
                skip={this.state.stats.skip}
                count={this.state.stats.count}
                onChange={(limit: number, skip: number) => this.applyPagination(limit, skip)}
            />
        );
    }

    private getFilter() {
        return <Filter controller={this.filterController} hint="Suche nach Bestellung oder Kunde"/>;
    }

    private getHeader() {
        return (
            <tr>
                <th>Status</th>
                <th>Bestellung</th>
                <th>Datum</th>
                <th>Kunde</th>
                <th>Fernlehrgang</th>
                <th>Wert</th>
            </tr>
        );
    }

    private getRows() {

        if (!this.state.orders) return undefined;

        return this.state.orders.map((order) => {

            const courses = order.courses.map((pos) => {

                let key = (pos.key === "humanenergetiker") ? "humanenergetiker-6" : pos.key;
                let name = this.state.courses.find((c) => c.key === key)?.name || "Fernlehrgang";
                let style = (pos.style === SELBSTSTUDIUM) ? "" : " mit Lernbegleitung";

                return <li key={pos.key}>{name}{style}</li>;
            });

            return (
                <tr key={order._id} onClick={() => this.selectOrder(order)} style={{cursor: "pointer"}}>
                    <td>
                        <Status color={getStatusColor(order.status)} text={getStatusText(order.status)}/>
                    </td>
                    <td><TextMarker text={getOrderName(order) || ""} highlight={this.filterController.getText()}/></td>
                    <td><span>{format(new Date(order.orderDate), "dd.LL.yyyy HH:mm")}</span></td>
                    <td><TextMarker text={order.fullName}
                                    highlight={this.filterController.getText()}/>
                    </td>
                    <td><span><ul>{courses}</ul></span></td>
                    <td><span>{formatCurrency(order.totalValueGross + order.totalRefundGross)}</span></td>
                </tr>
            );
        });
    }

    private getInfo() {

        if (!this.state.stats) return undefined;

        return (
            <>
                <Amount value={this.state.stats.count} singular="Bestellung" plural="Bestellungen"/>
                <Currency className="ml-4"
                          value={(this.state.stats.data?.totalValueGross || 0) + (this.state.stats.data?.totalRefundGross || 0)}/>
            </>
        );
    }

    render() {

        return (<ListViewTemplate
                pagination={this.getPagination()}
                busy={this.state.busy}
                redirect={this.state.redirect}
                filter={this.getFilter()}
                header={this.getHeader()}
                info={this.getInfo()}
                rows={this.getRows()}/>
        );
    }

    private applyPagination(limit: number, skip: number) {
        lastLimit = limit;
        lastSkip = skip;
        this.fetch(this.filterController.getFilter(), {limit: limit, skip: skip});
    }
}
