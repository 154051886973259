import React from 'react';
import {get} from "../../misc/communication";
import failureController from "../../controller/failure";
import {IContact} from "@institutsitya/sitya-common/types/model/contact";
import {getContactName, rememberContact} from "../../controller/contacts";
import {getCountryName} from "../../misc/country";
import SearchText from "../../components/Search";
import {TextMarker} from "../../components/TextMarker";
import {Pagination} from "../../components/Pagination";
import {Amount} from "../../components/Amount";
import ListViewTemplate from "../../templates/ListViewTemplate";
import navigationController from "../../controller/navigation";
import {IGetGenericResponse, IStats} from "@institutsitya/sitya-common/types/api/stats";

interface IContactListViewProps {
}

interface IContactListViewState {
    busy: boolean;
    redirect?: string;
    filter?: string;
    stats?: IStats;
    records: IContact[];
}

let lastLimit = 50;
let lastSkip = 0;
let lastFilter = "";

export class ContactListView extends React.Component<IContactListViewProps, IContactListViewState> {

    private mounted = false;

    state: IContactListViewState = {
        filter: lastFilter,
        busy: true,
        records: [],
    };

    componentDidMount() {
        this.mounted = true;
        this.fetch(lastFilter);
    }

    componentDidUpdate(prevProps: Readonly<IContactListViewProps>, prevState: Readonly<IContactListViewState>, snapshot?: any) {
    }

    private async fetch(filter?: string, options?: { limit?: number, skip?: number }) {
        try {

            this.setState({busy: true, filter: filter});

            let url = `/api/contacts?filter=${filter}`;

            if (options?.limit !== undefined) url += `&limit=${options.limit}`;
            if (options?.skip !== undefined) url += `&skip=${options.skip}`;

            const records = await get(url) as IGetGenericResponse<IContact>;

            this.setState({busy: false, records: records.data, stats: records.stats});

        } catch (error) {
            failureController.failure("ContactListView.tsx/fetch", error);
            this.setState({busy: false, records: []});
        }
    }

    private applyFilter(filter: string) {
        lastFilter = filter;
        this.fetch(filter);
    }

    private applyPagination(limit: number, skip: number) {
        lastLimit = limit;
        lastSkip = skip;
        this.fetch(this.state.filter, {limit: limit, skip: skip});
    }

    private selectContact(contact: IContact) {

        rememberContact(contact);

        navigationController
          .reset()
          .records(this.state.records.map((r) => r._id!));

        const url = `/contacts/detail/${contact._id}`;
        this.setState({redirect: url});
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private getPagination() {

        if (!this.state.stats) return undefined;

        return (
            <Pagination
                disabled={this.state.busy}
                limit={this.state.stats.limit}
                skip={this.state.stats.skip}
                count={this.state.stats.count}
                onChange={(limit: number, skip: number) => this.applyPagination(limit, skip)}
            />
        );
    }

    private getFilter() {
        return (
            <div>
                <SearchText hint="Suche nach Name oder E-Mail-Adresse" filter={this.state.filter}
                            onTextChanged={(filter) => {
                                this.applyFilter(filter)
                            }} autoFocus={true}/>
            </div>
        );
    }

    private getHeader() {
        return (
            <tr>
                <th>Name</th>
                <th>E-Mail-Adresse</th>
                <th>Telefon</th>
                <th>Kategorie</th>
                <th>Land</th>
                <th>Ort</th>
            </tr>
        );
    }

    private getRows() {

        if (!this.state.records) return undefined;

        const getCategory = (category?: string) => {
            if (category === "lead") return "Interessent";
            if (category === "customer") return "Kunde";
            return "-";
        }

        return this.state.records.map((contact) => {
            return (
                <tr key={contact._id} onClick={() => this.selectContact(contact)} style={{cursor: "pointer"}}>
                    <td><TextMarker highlight={this.state.filter} text={getContactName(contact) || ""}/></td>
                    <td><TextMarker highlight={this.state.filter} text={contact.mail}/></td>
                    <td><TextMarker highlight={this.state.filter} text={contact.phone}/></td>
                    <td><TextMarker highlight={this.state.filter} text={getCategory(contact.category)}/></td>
                    <td><TextMarker highlight={this.state.filter} text={contact.country ? getCountryName(contact.country) : "-"}/></td>
                    <td><TextMarker highlight={this.state.filter} text={contact.postalCode && contact.city ? `${contact.postalCode} ${contact.city}` : "-"}/>
                    </td>
                </tr>
            );
        });
    }

    private getInfo() {

        if (!this.state.stats) return undefined;

        return (
            <>
                <Amount value={this.state.stats.count} singular="Kontakt" plural="Kontakte"/>
            </>
        );
    }

    render() {

        return (
            <ListViewTemplate
                pagination={this.getPagination()}
                busy={this.state.busy}
                redirect={this.state.redirect}
                filter={this.getFilter()}
                header={this.getHeader()}
                info={this.getInfo()}
                rows={this.getRows()}/>
        );
    }
}