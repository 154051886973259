import React from 'react';
import {get, post} from "../misc/communication";
import DialogTemplate from "../templates/DialogTemplate";
import {IJob} from "@institutsitya/sitya-common/types/model/job";
import {getAuthController} from "../controller/auth";

interface ICalculatePricesDialogState {
    jobStatus?: string;
    jobId?: string;
    failed?: boolean;
}

interface ICalculatePricesDialogProps {
    onClose: () => void;
}

export class CalculatePricesDialog extends React.Component<ICalculatePricesDialogProps, ICalculatePricesDialogState> {

    private mounted = false;
    private timer: NodeJS.Timer | undefined;

    state: ICalculatePricesDialogState = {
    }

    async componentDidMount() {
        this.mounted = true;

        try {
            const job = await post('/api/jobs/hubspot/prices', undefined) as IJob;
            if (job) {
                this.setState({jobId: job._id, jobStatus: job.status});
                this.timer = setInterval(async () => {

                    if (this.state.jobId) {
                        try {
                            const job = await get(`/api/jobs/${this.state.jobId}`, undefined);
                            if (job) {
                                if (this.mounted && job && job.status !== this.state.jobStatus) this.setState({jobStatus: job.status});
                            }
                        } catch (e) {}
                    }
                }, 1000);
            }
            else this.setState({failed: true});
        }
        catch (e) {
            this.setState({failed: true});
        }
    }

    componentWillUnmount() {
        this.mounted = false;

        if (this.timer) {
            clearInterval(this.timer);
            this.timer = undefined;
        }
    }

    private onClose() {
        this.props.onClose();
    }

    private getContent() {

        let color = "";
        let value = 0;

        let text = "";
        if (this.state.jobId) {
            switch (this.state.jobStatus) {
                case "PENDING":
                    value = 25;
                    text = "wurde geplant, jedoch noch nicht gestartet...";
                    break;

                case "ACTIVE":
                    value = 50;
                    text = "wird ausgeführt.";
                    break;

                case "DONE":
                    value = 100;
                    color = "is-success";
                    text = "ist abgeschlossen.";
                    break;

                case "FAILED":
                case "SKIPPED":
                    value = 100;
                    color = "is-danger";
                    text = "ist fehlgeschlagen.";
                    break;
            }
        } else {
            if (this.state.failed) {
                value = 100;
                color = "is-danger";
                text = "konnte nicht eingeplant werden.";
            } else {
                value = 0;
                text = "wird eingeplant...";
            }
        }

        return (
            <div>
                <div className="mt-4">
                    Die Preisberechnung {text}
                </div>
                <div className="mt-4">
                    <progress className={`progress is-small ${color}`} value={value} max="100">{value}%</progress>
                </div>
            </div>
        );
    }

    private getButtons() {
        return (
            <div className="buttons" style={{justifyContent: "flex-end"}}>
                <button className="button is-text" style={{width: "120px"}} onClick={() => this.onClose()}>
                    Schliessen
                </button>
            </div>
        );
    }

    render() {
        return (
            <DialogTemplate
                content={this.getContent()}
                buttons={this.getButtons()}
                title={"Hubspot Preisbereichnung"}
                onCancel={() => this.onClose()}
            />
        );
    }
}