import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faKey} from "@fortawesome/pro-regular-svg-icons";
import {Logo} from "../../components/Logo";
import getErrorText from "@institutsitya/sitya-common/misc/errors";
import {getAuthController} from "../../controller/auth";

interface ILoginViewProps {
}

interface ILoginViewState {
    username: string,
    password: string;
    busy: boolean;
    hint: string;
}

export class LoginView extends React.Component<ILoginViewProps, ILoginViewState> {

    public state: ILoginViewState = {
        username: "",
        password: "",
        busy: false,
        hint: ""
    }

    private mounted = false;

    private refUserName = React.createRef<HTMLInputElement>();
    private refPassword = React.createRef<HTMLInputElement>();

    constructor(props: ILoginViewProps) {
        super(props);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    public componentDidMount() {

        this.mounted = true;

        // @ts-ignore
        document.addEventListener("keydown", this.onKeyPressed);
    }

    public componentWillUnmount() {

        this.mounted = false;

        // @ts-ignore
        document.removeEventListener("keydown", this.onKeyPressed);
    }

    public render() {

        return (

            <div style={{
                width: "100vw",
                height: "100vh",
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <form>
                    <div className="box p-4" style={{
                        width: "20rem",
                        background: "white",
                        marginBottom: "10vh",
                    }}>

                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",

                        }}>
                            <Logo width="8rem" />
                        </div>
                        <div className="control has-icons-left mt-5">
                            <input ref={this.refUserName} id="username" className="input" type=" text"
                                   placeholder="E-Mail-Adresse"
                                   autoComplete="on"
                                   onChange={(e) => this.setState({username: e.target.value})}
                                   disabled={this.state.busy}
                                   value={this.state.username}/>
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faEnvelope}/>
                            </span>
                        </div>
                        <div className="control has-icons-left mt-2">
                            <input ref={this.refPassword} id="password" className="input" type="password"
                                   placeholder="Passwort"
                                   autoComplete="on"
                                   onChange={(e) => this.setState({password: e.target.value})}
                                   disabled={this.state.busy}
                                   value={this.state.password}/>
                            <span className="icon is-small is-left">
                                <FontAwesomeIcon icon={faKey}/>
                            </span>
                        </div>
                        <div className="mt-5">
                            <button className={`button is-fullwidth is-purple ${this.state.busy ? "is-loading" : ""}`}
                                    disabled={this.state.busy}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.login();
                                    }}>Anmelden
                            </button>
                        </div>
                        <div className="mt-4" style={{height: "1.5rem"}}>
                            <span style={{color: "#f14668"}}>{getErrorText(this.state.hint)}</span>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (this.mounted) {
            if (e.key === 'Enter') {
                e.preventDefault();
                this.login();
            }
        }
    }

    private async login() {

        if (this.state.username && this.state.password) {

            this.setState({busy: true, hint: ""});

            getAuthController()
                .login(this.state.username, this.state.password, true)
                .then((u) => {

                    // No redirection required, as this is handled within App.tsx
                    if (this.mounted) this.setState({busy: false, hint: ""});
                })
                .catch((error) => {

                    this.setState({busy: false, hint: error.message});

                    if ((error.message === "ERR_USER_INVALID") && this.refUserName.current) this.refUserName.current.focus();
                    else if ((error.message === "ERR_PWD_INVALID") && this.refPassword.current) this.refPassword.current.focus();
                });

        } else {

            this.setState({hint: "Eingabe unvollständig"});

            if (this.state.username && this.refPassword.current) this.refPassword.current.focus();
            else if (this.refUserName.current) this.refUserName.current.focus();
        }
    }
}