import React from 'react';
import {AuthChangedArgs, getAuthController, IAuthObserver} from '../../controller/auth';

interface ILogoutViewProps {
}

interface ILogoutViewState {
    busy: boolean;
}

export class LogoutView extends React.Component<ILogoutViewProps, ILogoutViewState> implements IAuthObserver {

    private mounted = false;

    state = {busy: true};

    componentDidMount() {
        this.mounted = true;

        // No redirection required, as this is handled within App.tsx
        getAuthController().logout();
    }

    public onAuthChanged(args: AuthChangedArgs) {
        if (!args.user && this.mounted) this.setState({busy: false});
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {

        if (this.state.busy) {
            return (
                <div>
                <span>
                    Sie werden abgemeldet
                </span>
                </div>
            );
        }

        return <div></div>;
    }
}