import "./calendar.scss";

// based on https://medium.com/@jain.jenil007/building-a-calendar-in-react-2c53b6ca3e96
// see https://hypeserver.github.io/react-date-range/

import React from "react";
import {add, addDays, endOfMonth, endOfWeek, format, isSameDay, isSameMonth, startOfMonth, startOfWeek} from "date-fns";
import {de} from "date-fns/locale";

interface ICalendarProps {
    start: Date | undefined;
    end?: Date | undefined;
    month: Date;
    selected: boolean;
    onSelect: (date: Date) => void;
    onHover?: (date: Date) => void;
}

export const Calendar: React.FunctionComponent<ICalendarProps> = (props) => {

    const generateWeekdays = () => {
        const weekStartDate = startOfWeek(props.month, {weekStartsOn: 1});
        const weekDays = [];
        for (let day = 0; day < 7; day++) {
            weekDays.push(
                <div key={"weekday-" + day} className="day weeks">
                    {format(addDays(weekStartDate, day), "EEEEEE", {locale: de})}
                </div>
            );
        }
        return weekDays;
    };

    const generateDay = (date: Date) => {

        let cn = "day";
        if (isSameMonth(date, props.month)) {
            if (props.start && props.end) {
                if ((props.start <= date) && (props.end >= date)) cn += " highlight";
                if (props.selected) cn += " selected";
                if (isSameDay(date, props.start)) cn += " start";
                if (isSameDay(date, props.end)) cn += " end";
            }
            if (props.start && !props.end && props.selected) {
                if (isSameDay(date, props.start)) cn += " highlight selected start end";
            }

            if (props.start && !props.end && isSameDay(date, props.start)) cn += " start end highlight";
            if (props.end && !props.start && isSameDay(date, props.end)) cn += " start end highlight";
        }

        if (!isSameMonth(date, props.month)) cn += " inactive";
        if (isSameDay(date, new Date())) cn += " today";

        return (
            <div key={date.toString()}
                 className={cn}
                 onClick={() => {if (isSameMonth(date, props.month)) props.onSelect(date)}}
                 onMouseOver={() => {if (isSameMonth(date, props.month)) {
                     if (props.onHover) props.onHover(date);
                 }}}>
                <span>{format(date, "d")}</span>
            </div>
        );
    };

    const cells = [];

    cells.push(generateWeekdays());

    const start = startOfWeek(startOfMonth(props.month), {weekStartsOn: 1});
    const end = endOfWeek(endOfMonth(props.month), {weekStartsOn: 1});

    let date = start;

    while (date <= end) {
        cells.push(generateDay(date));
        date = addDays(date, 1);
    }

    return (
        <div className="calendar">
            {cells}
        </div>
    );
};

export default Calendar;
