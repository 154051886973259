import React from "react";
import {Label} from "./Label";
import DateRangePicker from "./DateRangePicker";
import SearchText from "./Search";
import {IFilterController, IFilterObserver} from "../controller/filter";
import {parseISO, startOfDay} from "date-fns";
import {endOfDay} from "date-fns/esm";

interface IFilterProps {
  controller: IFilterController;
  hint?: string;
}

interface IFilterState {
}

export class Filter extends React.Component<IFilterProps, IFilterState> implements IFilterObserver {
  state: IFilterState = {
    preset: this.props.controller.getPreset(),
    extended: false
  }

  componentDidMount() {
    this.props.controller.addHandler(this);
  }

  componentWillUnmount() {
    this.props.controller.removeHandler(this);
  }

  render() {

    const controller = this.props.controller;

    let extendedFilter: JSX.Element | undefined = undefined;
    if (!controller.getPreset()) {

      const dates = this.props.controller.getDates();
      const controls1 = dates.map((s) => {

        let start = this.props.controller.getValue(s.start);
        let end = this.props.controller.getValue(s.end);

        return (
          <div key={s.key}>
            <div><Label text="Datum"/></div>
            <DateRangePicker start={start ? parseISO(start) : undefined} end={end ? parseISO(end) : undefined} onChanged={(start, end) => {
              this.props.controller.setValues([
                {key: s.start, value: start ? startOfDay(start).toISOString() : undefined},
                {key: s.end, value: end ? endOfDay(end).toISOString() : undefined}
              ]);
            }}/>
          </div>
        );
      });

      const selectors = this.props.controller.getSelectors();
      const controls2 = selectors.map((s) => {

        const options: JSX.Element[] = [<option key="blank" value=""></option>];
        s.values.forEach((o) => {
          options.push(
            <option key={o.key} value={o.key}>{o.value}</option>
          );
        });

        return (
          <div key={s.key} className="ml-2">
            <div><Label text={s.name}/></div>
            <div className="select is-small">
              <select value={this.props.controller.getValue(s.key) || ""} onChange={(e) => {
                this.props.controller.setValue(s.key, e.target.value)
              }}>
                {options}
              </select>
            </div>
          </div>
        );
      });

      extendedFilter = (
        <div style={{display: "flex", fontSize: "0.9rem", alignItems: "flex-end", justifyContent: "space-between"}}
             className="mt-2">
          <div style={{display: "flex"}}>
            {controls1}
            {controls2}
          </div>
          <button onClick={() => {
            this.props.controller.clear();
          }} className="button is-text is-small ml-4"><span>Zurücksetzen</span></button>
        </div>
      );
    }

    const presets = this.props.controller.getPresets();
    const buttons = presets.map((p) => {
      return (
        <button
          key={p.key}
          onClick={() => {
            this.props.controller.setPreset(p.key);
            this.setState({preset: p.key, extended: false});
          }}
          className={`button is-small ${(controller.getPreset() === p.key) ? "is-purple" : ""}`}
          style={{width: "100px"}}>{p.name}
        </button>
      );
    });

    return (
      <div>
        <SearchText filter={this.props.controller.getText()} hint={this.props.hint}
                    onTextChanged={(filter) => {
                      this.props.controller.setText(filter);
                    }} autoFocus={true}/>
        <div className="pt-2" style={{display: "flex", justifyContent: "flex-end"}}>
          <div className="buttons">
            {buttons}
            <button className={`button is-small ${!controller.getPreset() ? "is-purple" : ""}`}
                    style={{width: "100px"}}
                    onClick={() => {
                      this.props.controller.setPreset("");
                      this.setState({extended: true});
                    }}>Erweitert
            </button>
          </div>
        </div>
        {extendedFilter}
      </div>
    );
  }

  onFilterChanged() {
    this.setState({});
  }
}