// const isDevelopment =  (process.env.NODE_ENV !== 'production');
// export const host = isDevelopment ? "http://localhost:8080" : "";

import {getAuthController} from "../controller/auth";

export const host = "";

export async function post(endpoint: string, body?: any) {
    return communicate('POST', endpoint, body);
}

export async function put(endpoint: string, body?: any) {
    return communicate('PUT', endpoint, body);
}

export async function del(endpoint: string, body?: any) {
    return communicate('DELETE', endpoint, body);
}

export async function get(endpoint: string, body?: any) {
    return communicate('GET', endpoint, body);
}

export async function patch(endpoint: string, body?: any) {
    return communicate('PATCH', endpoint, body);
}

export interface IApiError {

    source: "api";

    context: {
        user?: string;
        type: string;
        id: string,
        url: string,
        method: string
    };

    code: string;
    status: number;
    message: string;
}

export async function communicate(method: string, endpoint: string, body?: any) {

    const token = await getAuthController().getAccessToken();
    const options: RequestInit = {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    options.credentials = 'include';
    if (body) options.body = JSON.stringify(body);

    let errorMessage = "";

    try {

        const response = await fetch(host + endpoint, options);

        let body: any = null;

        try {
            if (response.status !== 204) body = await response.json();
        } catch (e) {
            console.error(e);
        }

        if ((response.status >= 200) && (response.status < 300)) return body;
        else errorMessage = body?.error?.code;

    } catch (e) {

        console.error(e);
        errorMessage = "ERR_CONNECTION";
    }

    throw new Error(errorMessage);
}

