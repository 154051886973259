import React from "react";

import {IHistory} from "@institutsitya/sitya-common/types/model/history";
import {formatDistance} from "date-fns";
import de from "date-fns/locale/de";

interface IRecordInfoProps {
    history: IHistory | undefined;
    hint?: string;
    modified?: boolean;
}

export const RecordInfo: React.FunctionComponent<IRecordInfoProps> = (props) => {
    if (props.hint) return <span style={{fontSize: "smaller", color: "red"}}>{props.hint}</span>
    if (props.modified) return <span style={{fontSize: "smaller"}}>Der Datensatz wurde geändert aber noch nicht gespeichert.</span>

    if (props.history?.modifiedby && props.history?.modifiedat) {
        const now = new Date();
        return <span style={{fontSize: "smaller"}}>Der Datensatz wurde {formatDistance(new Date(props.history.modifiedat), now, { locale: de, addSuffix: true })} von {props.history.modifiedby} geändert.</span>
    }

    if (props.history?.createdby && props.history?.createdat) {
        const now = new Date();
        return <span style={{fontSize: "smaller"}}>Der Datensatz wurde {formatDistance(new Date(props.history.createdat), now, { locale: de, addSuffix: true })} von {props.history.createdby} angelegt.</span>
    }

    return <span style={{fontSize: "smaller"}}>Der Datensatz wurde noch nicht angelegt.</span>
}