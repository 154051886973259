import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {get} from "../../misc/communication";
import failureController from "../../controller/failure";
import {IUser} from "@institutsitya/sitya-common/types/model/user";
import {IActivity} from "@institutsitya/sitya-common/types/model/activity";
import {FormatterFactory} from "../contact/ActivityFormatter";
import {RecordHeader} from "../../components/RecordHeader";
import {format} from "date-fns";

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import DetailViewTemplate from "../../templates/DetailViewTemplate";
import PdfPreviewDialog, {IPdfProvider} from "../../dialogs/PdfPreviewDialog";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {getCourses} from "../../controller/courses";

interface IUserActivityViewProps {
    uid: string;
}

interface IUserActivityViewState {
    busy: boolean;
    pdfPreview: boolean;
    pdfData: string;
    redirect?: string;
    category: "full" | "elearning";
    courses?: ICourse[];
    activities?: IActivity[];
    user?: IUser;
}

export const UserActivityViewWrapper: React.FunctionComponent = (props) => {
    const {uid} = useParams();
    if (uid) return <UserActivityView uid={uid}/>;
    return <div/>;
}

export class UserActivityView extends React.Component<IUserActivityViewProps, IUserActivityViewState> implements IPdfProvider {

    private mounted = false;

    state: IUserActivityViewState = {
        category: "elearning",
        busy: true,
        pdfPreview: false,
        pdfData: "",
    };

    public constructor(props: IUserActivityViewProps) {
        super(props);
        this.getPdfBlob = this.getPdfBlob.bind(this);
    }

    componentDidMount() {
        this.mounted = true;

        this.updatePageTitle();

        this.fetch("elearning");
    }

    private async fetch(category: "elearning" | "full") {
        try {

            if (this.mounted) {
                this.setState({busy: true, category: category});
                let url = `/api/users/${this.props.uid}/activities`;
                if (category && category !== "full") url += `?category=${category}`;

                const p1 = get(url);
                const p2 = getCourses();

                const [response, courses] = await Promise.all([p1, p2]);

                if (this.mounted) {
                    this.setState({busy: false, activities: response.activities, courses, user: response.user});
                    this.updatePageTitle(response.user.name);
                }
            }

        } catch (err) {
            failureController.failure("UserActivityView.tsx/fetch", err);
            this.setState({busy: false});
        }
    }

    private updatePageTitle(username?: string) {
        if (username) document.title = `${username} | SITYA E-Learning`;
        else document.title = "Log | SITYA E-Learning";
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private getListHintText() {

        if (this.state.category === "elearning") {
            if (this.state.activities && this.state.activities.length >= 1000) return "Die Liste zeigt aktuell die letzten 1000 Zugriffe auf Lehrunterlagen.";
            return "Die Liste zeigt aktuell alle Zugriffe auf Lehrunterlagen.";
        }

        if (this.state.activities && this.state.activities.length >= 1000) return "Die Liste zeigt aktuell die letzten 1000 Einträge.";
        return "Die Liste zeigt aktuell alle Zugriffe.";
    }

    public onExport() {
        this.setState({pdfPreview: true});
    }

    public getPdfBlob() {

        const doc = new jsPDF({orientation: "landscape", format: "a4"});

        doc.setFont("helvetica", "normal");
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.text(`Zugriffsstatistik für ${this.state.user?.name}`, 14, 15, {
            align: "left",
            baseline: "alphabetic"
        });

        doc.setFontSize(10);
        doc.text(this.getListHintText().replace("aktuell ", ""), 14, 22, {align: "left", baseline: "alphabetic"});

        const now = new Date();
        let pageCount = 1;

        autoTable(doc, {
            html: '#activityTable',
            theme: "grid",
            headStyles: {
                fillColor: "black",
                textColor: "white"
            },
            styles: {
                lineColor: "black",
                textColor: "black",
                cellPadding: {
                    top: 1.0,
                    bottom: 0.5,
                    left: 1,
                    right: 1
                },
                fontSize: 10
            },
            startY: 28,
            margin: {
                bottom: 20
            },
            didDrawPage: (data) => {
                if (data.pageNumber > pageCount) pageCount = data.pageNumber;
            },
        });

        // Draw footer with pagenumbers
        for (let i = 1; i <= pageCount; i++) {

            doc.setPage(i);

            doc.setDrawColor("#000000");
            doc.line(14, 200, 297 - 14, 200);

            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.setFont("courier", "normal");
            doc.text(`Druckdatum: ${format(now, "dd. LLL yyyy HH:mm:ss")}`, 14, 204, {
                align: "left",
                baseline: "alphabetic"
            });
            doc.text(`Seite ${i} von ${pageCount}`, 297 - 14, 204, {align: "right", baseline: "alphabetic"});
            doc.text(`Institut SITYA`, 297 / 2, 204, {align: "center", baseline: "alphabetic"});
        }

        doc.setProperties({
            title: this.state.user?.name ? `Zugriffsstatistik für ${this.state.user?.name}` : "Zugriffsstatistik"
        });

        return doc.output("blob");
    }

    private getButtons() {

        const cnButton1 = "button" + (this.state.category === "elearning" ? " is-dark" : "");
        const cnButton2 = "button" + (this.state.category === "full" ? " is-dark" : "");

        return (
            <div className="buttons">
                <button disabled={this.state.busy} className="button is-purple mr-2"
                        style={{width: "150px"}} onClick={() => this.fetch(this.state.category)}>
                    <span className="translate">Aktualisieren</span>
                </button>
                <button disabled={this.state.busy} onClick={() => this.fetch("elearning")}
                        style={{width: "120px"}} className={cnButton1}>Einfach
                </button>
                <button disabled={this.state.busy} onClick={() => this.fetch("full")}
                        style={{width: "120px"}} className={cnButton2}>Detailiert
                </button>
                <button disabled={this.state.busy} className="button"
                        style={{width: "120px"}} onClick={() => this.onExport()}>
                    <span className="translate">Drucken</span>
                </button>
            </div>
        );
    }

    private getDialog() {
        if (this.state.pdfPreview) return <PdfPreviewDialog title="Druckvorschau" provider={this}
                                                            onClose={() => this.setState({pdfPreview: false})}/>;
        return undefined;
    }

    private getContent() {
        const tableRows: JSX.Element[] = [];

        let idx = 0;

        this.state.activities?.forEach((a) => {
            const formatter = FormatterFactory.getFormatter(a, this.state.courses || []);
            const rows = formatter.getRows(idx);
            tableRows.push(...rows);
            idx += rows.length;
        });

        return (
            <table id="activityTable" className="table block is-narrow" style={{width: "100%"}}>
                <thead>
                <tr>
                    <th><span className="translate">Zeitpunkt</span></th>
                    <th><span className="translate">Benutzer</span></th>
                    <th><span className="translate">Aktion</span></th>
                    <th><span className="translate">Details</span></th>
                </tr>
                </thead>
                <tbody>
                {tableRows}
                </tbody>
            </table>
        );
    }

    render() {

        return (
            <DetailViewTemplate
                busy={this.state.busy}
                redirect={this.state.redirect}
                title={this.state.user?.name ? `Zugriffsstatistik für ${this.state.user?.name}` : "Zugriffsstatistik"}
                info={this.getListHintText()}
                link={`/users/detail/${this.props.uid}`}
                content={this.getContent()}
                dialog={this.getDialog()}
                buttons={this.getButtons()} />
        );
    }
}