import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {get, post, put} from "../../misc/communication";
import failureController from './../../controller/failure';
import {IReferences} from "@institutsitya/sitya-common/types/api/references";
import {
    CourseAssignmentStyle,
    ICourseAssignment
} from "@institutsitya/sitya-common/types/model/user";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {getCourses, isSameCourse} from "../../controller/courses";
import stringify from 'json-stable-stringify';
import {faAngleDown} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserCourseAssignment} from "./UserCourseAssignment";
import {isValidEMail} from "@institutsitya/sitya-common/misc/validator";
import {PasswortSetDialog} from "../../dialogs/PasswordSetDialog";
import {Label} from "../../components/Label";
import DetailViewTemplate from "../../templates/DetailViewTemplate";
import {IHistory} from "@institutsitya/sitya-common/types/model/history";
import hash from "../../misc/hash";
import {IOrder} from "@institutsitya/sitya-common/types/model/order";

interface IUserDetailViewProps {
    uid: string;
}

interface IUserDetailViewState {
    busy: boolean;
    uid: string | undefined;
    redirect?: string;
    history?: IHistory;
    courses: ICourseAssignment[];
    orders: IOrder[];
    references?: IReferences;
    message: string;
    showPasswordDialog: boolean;
    active: boolean;
    nomails: boolean;
    name: string;
    person?: number;
    mail: string;
    role: string;
}

interface IEditableUserData {
    _id?: string;
    mail: string;
    name: string;
    nomails: boolean;
    role: string;
    active: boolean;
    courses: ICourseAssignment[];
};

export const UserDetailViewWrapper: React.FunctionComponent = (props) => {
    const {uid} = useParams();
    if (uid) return <UserDetailView uid={uid}/>;
    return <div/>;
}

export class UserDetailView extends React.Component<IUserDetailViewProps, IUserDetailViewState> {

    private courses: ICourse[] = [];

    private checksum = 0;
    private mounted = false;

    state: IUserDetailViewState = {
        busy: true,
        uid: undefined,
        courses: [],
        orders: [],
        message: "",
        showPasswordDialog: false,
        active: true,
        nomails: false,
        name: '',
        mail: '',
        role: 'user'
    };

    componentDidMount() {
        this.mounted = true;
        this.fetch(this.props.uid);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps: Readonly<IUserDetailViewProps>, prevState: Readonly<IUserDetailViewState>, snapshot?: any) {
        if (prevProps.uid !== this.props.uid) {
            this.fetch(this.props.uid);
        }
    }

    private getStateValue() {
        let result = this.state.active ? "aktiv" : "gesperrt";
        return result;
    }

    private isDisabled() {
        if (this.state.busy) return true;
        return false;
    }

    private isNewRecord() {
        return this.state.uid === undefined;
    }

    private isChanged() {

        if (this.isNewRecord()) return true;

        const user: IEditableUserData = {
            name: this.state.name,
            mail: this.state.mail,
            nomails: this.state.nomails,
            role: this.state.role,
            active: this.state.active,
            courses: this.state.courses
        };

        const checksum = this.calcChecksum(user);
        return checksum !== this.checksum;
    }

    private setIdentity() {
        const data = {
            uid: this.state.uid,
            mail: this.state.mail,
            name: this.state.name,
            role: this.state.role
        };

        const token = window.btoa(JSON.stringify(data));
        const host = window.location.host;

        let target = "https://elearning.institut-sitya.at";
        if (host.indexOf("localhost") >= 0) target = "http://localhost:3001";
        if (host.indexOf("minaxus.dev") >= 0) target = "https://elearning.sitya.minaxus.dev";

        window.open(`${target}/?user=${token}`, '_blank');
    }

    private getButtons() {

        const changed = this.isChanged();

        const buttons: JSX.Element[] = [];

        if (!this.isNewRecord()) {

            buttons.push(
                <button key="impersonate" className="button mr-2" style={{width: "120px"}}
                        disabled={changed || this.isDisabled() || (this.state.active === false) || (this.state.role === "admin")}
                        onClick={() => this.setIdentity()}>
                    <span className="translate">Identität</span>
                </button>);

            buttons.push(
                <button key="pwd" className="button mr-2" style={{width: "120px"}}
                        disabled={this.isNewRecord() || this.isDisabled()}
                        onClick={() => this.setState({showPasswordDialog: true})}>
                    <span className="translate">Passwort</span>
                </button>);

            buttons.push(
                <button key="activate" className="button mr-2" style={{width: "120px"}}
                        disabled={this.isNewRecord() || this.isDisabled()}
                        onClick={() => this.setState({active: !this.state.active})}>
                    <span className="translate">{this.state.active ? 'Sperren' : 'Entsperren'}</span>
                </button>);
        }

        return (
            <div className="buttons">
                <button className="button is-purple mr-2" style={{width: "150px"}}
                        disabled={!changed || this.isDisabled()} onClick={() => this.save()}>
                    <span className="translate">Speichern</span>
                </button>
                {this.createAssignmentDropdown()}
                {buttons}
            </div>);
    }

    private getDialog() {

        if (this.state.showPasswordDialog) {
            return <PasswortSetDialog
                username={this.state.mail}
                name={this.state.name}
                onDone={() => {
                    this.setState({showPasswordDialog: false})
                }}
                onCancel={() => this.setState({showPasswordDialog: false})}
            />;
        }

        return undefined;
    }

    private createAssignmentDropdown() {

        const dropdown = this.courses?.map((c) => {

            const assignment = this.state.courses?.find((course) => course.key === c.key);
            const handler = !assignment ? () => {
                const tmpCourses = JSON.parse(JSON.stringify(this.state.courses)) as ICourseAssignment[];
                tmpCourses.push({
                    name: c.name,
                    key: c.key,
                    style: CourseAssignmentStyle.WithoutSupport
                });
                this.setState({courses: tmpCourses});
            } : undefined;

            return <span key={c.key}
                         className={"dropdown-item link navbar-item " + (!assignment ? "menulink" : "is-disabled")}
                         onClick={handler}>
                {c.name}
            </span>;
        });

        const menu = !this.isDisabled() ?
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content" style={{width: "360px", maxHeight: "min(60vh, calc(100vh - 300px))", overflow: "auto"}}>
                    {dropdown}
                </div>
            </div> : undefined;

        return (
            <div className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                    <button className="button is-purple mr-2" aria-haspopup="true" aria-controls="dropdown-menu"
                            style={{width: "150px"}} disabled={this.isDisabled()}>
                        <span className="translate">Zuweisen</span>
                        <span className="icon is-small" style={{color: "white"}}>
                            <FontAwesomeIcon className="mt-1" icon={faAngleDown}/>
                        </span>
                    </button>
                </div>
                {menu}
            </div>
        );
    }

    private getUserDetailsSection() {

        const hasContact = (this.state.references?.contacts?.length || 0) > 0;

        return (
            <div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        {this.getStateValue()}
                    </div>
                </div>
                <div className="mt-2">
                    <input className="input" type="text" placeholder="Vorname Nachname"
                           disabled={this.isDisabled() || hasContact}
                           value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
                </div>
                <div className="mt-2">
                    <input className="input" type="text" placeholder="E-Mail-Adresse"
                           disabled={this.isDisabled() || hasContact}
                           value={this.state.mail}
                           onChange={(e) => this.setState({mail: e.target.value.toLowerCase()})}/>
                </div>
                <div className="mt-2 control">
                    <div className="select" style={{width: "100%"}}>
                        <select value={this.state.role} disabled={this.isDisabled()}
                                style={{width: "100%"}} onChange={(e) => this.setState({role: e.target.value})}>
                            <option key="user" value="user">Schüler</option>
                            <option key="admin" value="admin">Administrator</option>
                        </select>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="pretty p-default p-curve p-primary">
                        <input type="checkbox" disabled={this.isDisabled()} checked={!this.state.nomails}
                               onChange={(e: any) => this.setState({nomails: (!this.state.nomails)})}/>
                        <div className="state p-primary">
                            <label>Benutzer erhält alle E-Mails</label>
                        </div>
                    </div>
                </div>
            </div>);
    }

    private getUserAssignmentSection() {

        const tmpCourses = JSON.parse(JSON.stringify(this.state.courses)) as ICourseAssignment[];
        tmpCourses.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        const courses = tmpCourses.length ? tmpCourses.map((c, index) => {

            const course = this.courses?.find((ac) => ac.key === c.key);
            if (!course) return <div><span style={{color: "red"}}>{`Ungültiger Kurs ${c.key}`}</span></div>;

            let order: IOrder | undefined = undefined;
            for (const o of this.state.orders) {
                if (o.status !== "cancelled" && o.courses.find((x) => isSameCourse(x.key, c.key))) {
                    order = o;
                    break;
                }
            }

            return (
                <div key={c.key}>
                    <UserCourseAssignment
                        order={order}
                        disabled={this.isDisabled()}
                        course={course}
                        name={c.name}
                        style={c.style}
                        onDelete={() => {

                            tmpCourses.splice(index, 1);
                            this.setState({courses: tmpCourses});
                        }}
                        onToggleStyle={() => {
                            c.style = (c.style === CourseAssignmentStyle.WithSupport) ? CourseAssignmentStyle.WithoutSupport : CourseAssignmentStyle.WithSupport;
                            this.setState({courses: tmpCourses});
                        }}/>

                    {(index < this.state.courses!.length - 1) ? <hr/> : null}
                </div>);
        }) : <div><span className="translate">Noch keine Kurse zugewiesen.</span></div>

        return (
            <div>
                {courses}
            </div>);
    }

    private getContent() {
        return (
            <div className="mt-4" style={{display: "grid", gridTemplateColumns: "1fr auto 1fr"}}>
                <div>
                    <div><Label text="Stammdaten"/></div>
                    <div className="mt-4">
                        {this.getUserDetailsSection()}
                    </div>
                </div>
                <div className="mx-6" style={{borderLeft: "2px solid #f5f5f5"}}>
                </div>
                <div>
                    <div><Label text="Zugewiesene Kurse"/></div>
                    <div className="mt-4">
                        {this.getUserAssignmentSection()}
                    </div>
                </div>
            </div>
        );
    }

    private calcChecksum(user: IEditableUserData) {

        const courses = user.courses ? JSON.parse(JSON.stringify(user.courses)) as ICourseAssignment[] : [];

        // Sort by keys to get a consistent hash
        courses.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));

        // Use a deterministic stringify for constant sort order of keys to get a consistent hash
        // Trim/lowercase to mimic backend behaviour
        const value = stringify({
            name: user.name.trim(),
            mail: user.mail.trim().toLowerCase(),
            nomails: user.nomails,
            role: user.role.trim().toLowerCase(),
            active: user.active,
            courses: courses.map((x) => {
                return ({
                    key: x.key,
                    style: x.style
                });
            })
        });

        return hash(value);
    }

    async fetch(uid: string) {

        try {

            const p1 = uid !== 'neu' ?
                get(`/api/users/${uid}`) :
                new Promise<IEditableUserData>((r, j) => {
                    const user: IEditableUserData = {
                        name: '',
                        mail: '',
                        nomails: false,
                        active: true,
                        courses: [],
                        role: 'user'
                    };
                    r(user);
                });

            const p2 = getCourses();

            const p3 = this.props.uid !== 'neu' ?
                get(`/api/users/${uid}/references`) :
                new Promise<IReferences | undefined>((r, j) => r(undefined));

            const [user, courses, references] = await Promise.all([p1, p2, p3]);

            let orders: IOrder[] = [];
            const contacts = (references as IReferences)?.contacts;
            if (contacts && contacts.length === 1) orders = await get(`/api/contacts/${contacts[0].id}/orders`);

            if (this.mounted) {

                if (!user.courses) user.courses = [];
                if (user.active === undefined) user.active = true;
                if (user.nomails === undefined) user.nomails = false;

                this.checksum = this.calcChecksum(user);
                this.courses = courses;

                this.setState({
                    busy: false,
                    message: '',
                    references: references,
                    uid: user._id,
                    name: user.name,
                    person: user.person,
                    mail: user.mail,
                    role: user.role,
                    nomails: user.nomails,
                    courses: user.courses,
                    orders,
                    active: user.active,
                    history: user.history
                });
            }
        } catch (error) {
            failureController.failure("UserDetailView.tsx/fetch", error);
            this.setState({busy: false});
        }
    }

    async save() {

        try {

            let errormsg = "";
            if (!this.state.mail || !this.state.mail.trim() || !isValidEMail(this.state.mail)) errormsg = "Bitte gültige E-Mail Adresse eingeben";
            if (!this.state.name || !this.state.name.trim()) errormsg = "Bitte Namen eingeben";

            if (errormsg) {
                this.setState({message: errormsg});
                return;
            }

            this.setState({busy: true});

            const user: IEditableUserData = {
                _id: this.state.uid,
                name: this.state.name.trim(),
                mail: this.state.mail.toLowerCase().trim(),
                nomails: this.state.nomails,
                active: this.state.active,
                courses: this.state.courses,
                role: this.state.role
            }

            const isNew = this.isNewRecord();

            let result = await (isNew
                ? post(`/api/users`, user)
                : put(`/api/users/${this.state.uid}`, user));

            // Re-fetch references
            const references = await get(`/api/users/${result._id}/references`);

            // Re-fetch orders for new records
            let orders: IOrder[] = this.state.orders;
            if (isNew) {
                const contacts = (references as IReferences)?.contacts;
                if (contacts && contacts.length === 1) orders = await get(`/api/contacts/${contacts[0].id}/orders`);
            }

            this.checksum = this.calcChecksum(result);

            this.setState({
                busy: false,
                message: '',
                history: result.history,
                references: references,
                orders,
                person: result.person,
                uid: result._id,
                name: result.name,
                mail: result.mail
            });

            if (this.calcChecksum(user) === this.calcChecksum(result)) {
                const url = `/users/detail/${result._id}`;
                if (window.location.pathname !== url) window.history.replaceState({}, "", url);
            } else {
                this.setState({message: "Die Daten konnten nicht vollständig gespeichert werden."});
            }

        } catch (err) {
            this.setState({busy: false});
            this.setState({message: (err as any).message});
        }
    }

    render() {

        const logLink = (this.props.uid !== "neu") ? <div className="mt-4 container">
            <Link className="link" to={`/users/detail/${this.props.uid}/log`}>Zugriffsstatistik anzeigen</Link>
        </div> : undefined;

        let title = this.state.name || "Neuer Benutzer";
        let info = this.state.mail;
        if (this.state.person) info += ` | ${this.state.person}`;

        return (
            <><
                DetailViewTemplate
                busy={this.state.busy}
                dirty={this.isChanged()}
                redirect={this.state.redirect}
                history={this.state.history}
                references={this.state.references}
                id={this.state.uid}
                onNavigate={async (uid: string) => {
                    await this.fetch(uid);
                    window.history.pushState({}, "", `/users/detail/${uid}`);
                }}
                title={title}
                info={info}
                hint={this.state.message}
                link="/users/list"
                content={this.getContent()}
                dialog={this.getDialog()}
                buttons={this.getButtons()}
            />
                {logLink}
            </>
        );
    }
}