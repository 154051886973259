import React, { useEffect, useState } from 'react';
import './TextMarker.scss';

interface ITextProps {
    highlight?: string;
    text?: string;
}

interface ITextPart {
    text?: string;
    highlight: boolean;
}

export const TextMarker: React.FunctionComponent<ITextProps> = (props) => {
    
    const [parts, setParts] = useState<ITextPart[]>([{text: props.text, highlight: false}]);

    useEffect(() => {

        const filter = props.highlight ? (props.highlight || '').toLowerCase().trim().split(' ').map((value) => value.trim()) : undefined;
        if (filter && filter.length && props.text && props.text.length) {

            const text = props.text.toLowerCase();
            const marks = new Array(props.text.length);

            filter.forEach((item) => {
        
                if (item.length > 0) {
                    let pos = 0;
                    while (true) {
                        pos = text.indexOf(item, pos);
                        if (pos < 0) break;
                        for (let i = 0; i < item.length; i++) marks[pos + i] = true;
                        pos++;
                    }
                }
            });

            let content = '';
            let highlight = false;

            const newparts: ITextPart[] = [];

            for (let i = 0; i < marks.length; i++) {

                if ((i === 0) || marks[i] !== marks[i-1]) {
                    
                    if (i > 0) newparts.push({highlight: highlight, text: content});
                    highlight = marks[i];
                    content = '';
                }
                content += props.text[i];
            }

            newparts.push({highlight: highlight, text: content});
            setParts(newparts);

        } else {
            
            const newparts: ITextPart[] = [{text: props.text || "", highlight: false}];
            setParts(newparts);
        }

    }, [props.text, props.highlight]);

    const spans = parts.map((p, idx) => <span key={(++idx).toString()} className={p.highlight ? 'highlight' : ''} >{p.text}</span>);
    return <div>{spans}</div>;
}