import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {get, patch, post, put} from "../misc/communication";
import {IFileCourse} from "@institutsitya/sitya-common/types/model/file";
import {
    ICourseFilePatch,
    ICourseFilePatchResult,
    ISnippetPatch,
    ISnippetPatchResult
} from "@institutsitya/sitya-common/types/api/course";
import {getAuthController} from "./auth";
import {ISnippet} from "@institutsitya/sitya-common/types/model/snippet";
import {SnippetDataType, SnippetType} from "@institutsitya/sitya-common/types/model/snippet";

let courses: ICourse[] | undefined;

interface ICourseFileData {

    submodule?: string;
    type: string;
    timestamp: string;
    size: number;
    path: string;
}

interface ICourseSnippetData {
    submodule?: string;
    data: SnippetDataType;
}

export interface IEditableFile {

    id?: string;
    name: string;
    modulekey: string;
    deleted: boolean;

    code?: number;
    message?: string;

    status: 'unchanged' | 'changed' | 'new';

    initial: ICourseFileData;
    current: ICourseFileData;
}

export interface IEditableSnippet {

    id?: string;
    modulekey: string;
    deleted: boolean;
    code?: number;
    message?: string;
    timestamp: Date;

    status: 'unchanged' | 'changed' | 'new';
    type: SnippetType;

    initial: ICourseSnippetData;
    current: ICourseSnippetData;
}

export const LERNBEGLEITUNG = "0";
export const SELBSTSTUDIUM = "1";

export function isSameCourse(key1: string, key2: string) {

    let k1 = key1?.trim()?.toLowerCase();
    if (k1 === "humanenergetiker-6") k1 = "humanenergetiker";
    if (k1 === "humanenergetiker-7") k1 = "humanenergetiker";

    let k2 = key2?.trim()?.toLowerCase();
    if (k2 === "humanenergetiker-6") k2 = "humanenergetiker";
    if (k2 === "humanenergetiker-7") k2 = "humanenergetiker";

    return (k1 === k2);
}

export async function getCourses(): Promise<ICourse[]> {

    try {

        if (courses) return courses;

        courses = await get('/api/courses') as ICourse[];
        return courses;

    } catch (error) {

        throw error;
    }
}

export async function getCourse(key: string): Promise<ICourse | undefined> {

    try {

        const courses = await getCourses();
        return courses.find((c) => c.key === key);

    } catch (error) {

        throw error;
    }
}

export async function getCourseName(key: string): Promise<string> {

    try {
        if (key === "humanenergetiker") key = "humanenergetiker-6";
        return (await getCourse(key))?.name || "Unbekannter Fernlehrgang";
    } catch (error) {
        throw error;
    }
}

export async function updateCourseFiles(coursekey: string, patchset: ICourseFilePatch[]): Promise<ICourseFilePatchResult[]> {
    try {

        const url = `/api/courses/${coursekey}/files`;
        return await patch(url, patchset) as ICourseFilePatchResult[];

    } catch (error) {

        throw error;
    }
}

export async function updateSnippets(coursekey: string, patchset: ISnippetPatch[]): Promise<ISnippetPatchResult[]> {
    try {
        const url = `/api/courses/${coursekey}/snippets`;
        return await patch(url, patchset) as ISnippetPatchResult[];
    } catch (error) {
        throw error;
    }
}

export async function getCourseFiles(coursekey: string): Promise<IFileCourse[]> {

    try {

        // Currently only links are supported
        const url = `/api/courses/${coursekey}/files`;
        return await get(url) as IFileCourse[];

    } catch (error) {

        throw error;
    }
}

export async function getSnippets(coursekey: string): Promise<ISnippet[]> {

    try {

        // Currently only links are supported/returned
        const url = `/api/courses/${coursekey}/snippets`;
        return await get(url) as ISnippet[];

    } catch (error) {

        throw error;
    }
}

export async function updateCourse(course: ICourse): Promise<ICourse> {

    try {
        const result = await put(`/api/courses/${course._id}`, course) as ICourse;
        return result;

    } catch (error) {

        throw error;
    }
}

export function resetCourses() {
    courses = undefined;
}

export async function createCourse(course: ICourse): Promise<ICourse> {

    try {

        const result = await post('/api/courses', course) as ICourse;
        return result;

    } catch (error) {

        throw error;
    }
}