import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

export interface IPasswordFieldProps {
    id?: string,
    disabled: boolean;
    value: string;
    placeholder?:string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const PasswordField: React.FunctionComponent<IPasswordFieldProps> = (props) => {

    const [visible, setVisible] = useState(false);
    const [id, setId] = useState("");

    const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>  {
        setVisible(!visible);
        e.preventDefault();
    }

    useEffect(() => {
        setId(props.id || "another-password");
    }, [props.id]);

    return (
        <div className="field has-addons">
            <div className="control has-icons-left" style={{ width: "100%" }}>
                <input id={id} className="input" type={visible ? "text" : "password"} placeholder={props.placeholder || "Passwort"}
                       disabled={props.disabled} value={props.value}
                       onChange={(e) => { if (props.onChange) props.onChange(e) }}
                       onKeyPress={(e) => { if (props.onKeyPress) props.onKeyPress(e) }} />
                <span className="icon is-small is-left"><FontAwesomeIcon icon={faKey} /></span>
            </div>
            <div className="control">
                <button className="button is-outlined" disabled={props.disabled} onClick={(e) => togglePasswordVisibility(e)} tabIndex={-1}>
                    <FontAwesomeIcon icon={visible ? faEyeSlash : faEye} />
                </button>
            </div>
        </div>);
}