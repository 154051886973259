import React, {useEffect, useState} from 'react';
import Calendar from "./Calendar";
import {
    add,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    startOfMonth,
    startOfToday,
    startOfWeek,
    startOfYear
} from "date-fns";
import {de} from "date-fns/locale";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/pro-light-svg-icons";
import {faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";

interface IDateRangePickerProps {
    onChanged?: (start?: Date, end?: Date) => void;
    start?: Date;
    end?: Date;
}

interface IOverlayProps {
    onCancel: () => void;
    onOk: (start?: Date, end?: Date, name?: string) => void;
    start?: Date;
    end?: Date;
}

interface IPresetsListProps {
    onSelect: (start: Date, end: Date) => void;
    onHover?: (start: Date, end: Date) => void;
}

const PresetsList: React.FunctionComponent<IPresetsListProps> = (props) => {

    const rows: JSX.Element[] = [];

    const today = startOfToday();

    let idx = 0;

    const addPreset = (start: Date, end: Date, name: string) => {
        rows.push(
            <div className="preset" key={`preset-${++idx}`}
                 onMouseOver={() => props.onHover && props.onHover(start, end)}
                 onClick={() => props.onSelect(start, end)}>{name}</div>
        );
    }

    addPreset(today, today, "Heute");
    addPreset(add(today, {days: -1}), add(today, {days: -1}), "Gestern");
    addPreset(add(today, {days: -7}), today, "7 Tage");

    rows.push(<div key="separator-1" className="preset mt-2 pt-1" style={{width: "100%", borderTop: "1px solid gainsboro"}}/>);

    addPreset(startOfWeek(today, {weekStartsOn: 1}), endOfWeek(today, {weekStartsOn: 1}), "Aktuelle Woche");
    addPreset(startOfMonth(today), endOfMonth(today), "Aktuelles Monat");
    addPreset(startOfYear(today), endOfYear(today), "Aktuelles Jahr");

    rows.push(<div key="separator-2" className="preset mt-2 pt-1" style={{width: "100%", borderTop: "1px solid gainsboro"}}/>);

    addPreset(startOfWeek(add(today, {weeks: -1}), {weekStartsOn: 1}), endOfWeek(add(today, {weeks: -1}), {weekStartsOn: 1}), "Letzte Woche");
    addPreset(startOfMonth(add(today, {months: -1})), endOfMonth(add(today, {months: -1})), "Letztes Monat");
    addPreset(startOfYear(add(today, {years: -1})), endOfYear(add(today, {years: -1})), "Letztes Jahr");

    return <>{rows}</>
}

export const DateRangePicker: React.FunctionComponent<IDateRangePickerProps> = (props) => {

    const [overlay, setOverlay] = useState<boolean>(false);

    const o = overlay ? <div style={{position: "absolute", left: 0, top: "35px", zIndex: 10}}><Overlay
        start={props.start}
        end={props.end}
        onCancel={() => setOverlay(false)}
        onOk={(start?: Date, end?: Date, name?: string) => {
            setOverlay(false);
            if (props.onChanged) props.onChanged(start, end);
        }}
    /></div> : undefined;

    let value = "";

    const start = props.start ? format(props.start, "dd.MM.yyyy") : "";
    const end = props.end ? format(props.end, "dd.MM.yyyy") : "";

    if (start && end) {
        if (start === end) value = start;
        else value = start + " - " + end;
    }

    return (
        <div className="field has-addons" style={{position: "relative"}}>
            <div className="control">
                <input style={{width: "150px"}} readOnly={true} className="input  is-small" type="text" placeholder=""
                       value={value}/>
            </div>
            <div className="control">
                <button onClick={() => {
                    setOverlay(!overlay);
                }} className="button  is-small">
                    <FontAwesomeIcon icon={faCalendar}/>
                </button>
            </div>
            {o}
        </div>
    );
}

export const Overlay: React.FunctionComponent<IOverlayProps> = (props) => {

    const [first, setFirst] = useState<Date | undefined>(undefined);
    const [second, setSecond] = useState<Date | undefined>(undefined);
    const [hover, setHover] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if (props.start && props.end) setDates(props.start, props.end);
    }, [props.start, props.end]);

    const [monthLeft, setMonthLeft] = useState<Date>(add(startOfMonth(new Date()), {months: 0, days: 10}));
    const [monthRight, setMonthRight] = useState<Date>(add(startOfMonth(new Date()), {months: 1, days: 10}));

    const onSelect = (date: Date) => {

        if (!first) {
            setFirst(date);
            setHover(undefined);
            return;
        }

        if (first && !second) {
            setSecond(date);
            setHover(undefined);
            return;
        }

        setFirst(date);
        setHover(undefined);
        setSecond(undefined);
    }

    const onHover = (date: Date) => {
        if (first && !second) setHover(date);
    }

    const setDates = (start: Date, end: Date) => {

        setFirst(start);
        setSecond(end);

        setHover(undefined);

        const startLeft = startOfMonth(start);
        let startRight = startOfMonth(end);

        if (startLeft.getTime() === startRight.getTime()) startRight = add(startRight, {months: 1});

        setMonthLeft(startLeft);
        setMonthRight(startRight);
    }

    let start: Date | undefined;
    let end: Date | undefined;
    let selected = false;

    if (first && second) {
        selected = true;
        if (first > second) {
            start = second;
            end = first;
        } else {
            start = first;
            end = second;
        }
    } else if (first && hover) {
        if (first > hover) {
            start = hover;
            end = first;
        } else {
            start = first;
            end = hover;
        }
    } else if (first && !second && !hover) start = first;

    return (
        <div className="p-4" style={{background: "white", border: "1px solid gainsboro", borderRadius: "3px"}}>
            <div style={{display: "flex", userSelect: "none"}}>
                <div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <div>
                            <button className="button is-small" onClick={() => {
                                setMonthLeft(add(monthLeft, {months: -1}));
                            }}><FontAwesomeIcon size="xs" icon={faChevronLeft}/>
                            </button>
                            <button className="ml-2 button is-small" onClick={() => {
                                let d = add(monthLeft, {months: 1});
                                if (d >= monthRight) setMonthRight(add(d, {months: 1}));
                                setMonthLeft(d);
                            }}><FontAwesomeIcon size="xs" icon={faChevronRight}/>
                            </button>
                        </div>
                        <span className="ml-2">{format(monthLeft, "MMMM yyyy", {locale: de})}</span>
                    </div>
                    <Calendar month={monthLeft} start={start} end={end} selected={selected}
                              onSelect={(date) => onSelect(date)}
                              onHover={(date) => onHover(date)}/>
                </div>
                <div className="ml-4 pl-4" style={{borderLeft: "1px solid gainsboro"}}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <span className="ml-2">{format(monthRight, "MMMM yyyy", {locale: de})}</span>
                        <div>
                            <button className="button is-small" onClick={() => {
                                let d = add(monthRight, {months: -1});
                                if (d <= monthLeft) setMonthLeft(add(d, {months: -1}));
                                setMonthRight(d);
                            }}><FontAwesomeIcon size="xs" icon={faChevronLeft}/>
                            </button>
                            <button className="ml-2 button is-small" onClick={() => {
                                setMonthRight(add(monthRight, {months: 1}));
                            }}><FontAwesomeIcon size="xs" icon={faChevronRight}/>
                            </button>
                        </div>
                    </div>
                    <Calendar month={monthRight} start={start} end={end} selected={selected}
                              onSelect={(date) => onSelect(date)}
                              onHover={(date) => onHover(date)}/>
                </div>
                <div className="ml-4 pl-4" style={{borderLeft: "1px solid gainsboro", width: "150px"}}>
                    <PresetsList
                        onSelect={(start, end) => {
                            setDates(start, end)
                        }}
                    />
                </div>
            </div>
            <div className="mt-4"
                 style={{borderTop: "1px solid gainsboro", display: "flex", justifyContent: "flex-end"}}>
                <div className="mt-4" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <button className="button is-small is-text" onClick={() => {
                        setFirst(undefined);
                        setSecond(undefined);
                        setHover(undefined);
                        props.onOk(undefined, undefined);
                    }}>Löschen
                    </button>
                    <div>
                        <button className="button is-small is-text" onClick={() => {
                            props.onCancel();
                        }}>Abbrechen
                        </button>
                        <button disabled={!first || !second} className="button is-small is-purple" onClick={() => {
                            if (first!.getTime() > second!.getTime()) props.onOk(second!, first!);
                            else props.onOk(first!, second!);
                        }}>Übernehmen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DateRangePicker;