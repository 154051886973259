import format from "date-fns/format";
import {de} from "date-fns/locale";

export function getDefaultFormatString() {
    return "dd. LLLL yyyy";
}

export function formatDate(date: Date | string | undefined) {
    if (!date) return "";
    return format(new Date(date), "dd. LLLL yyyy", {locale: de});
}

export function formatDateTime(date: Date | string | undefined) {
    if (!date) return "";
    return format(new Date(date), "dd. LLLL yyyy HH:mm", {locale: de});
}