import React from 'react';
import {post} from "../misc/communication";
import failureController from "../controller/failure";
import {isStrongPassword} from "@institutsitya/sitya-common/misc/validator";
import {PasswordField} from "../components/PasswordField";
import DialogTemplate from "../templates/DialogTemplate";
import {getAuthController} from "../controller/auth";

interface IPasswortSetDialogState {
    newpassword1: string;
    newpassword2: string;
    busy: boolean;
    message?: string;
    countdown?: number;
    finished: boolean;
}

interface IPasswortSetDialogProps {
    onCancel: () => void;
    onDone: () => void;

    name: string;
    username: string;
}

export class PasswortSetDialog extends React.Component<IPasswortSetDialogProps, IPasswortSetDialogState> {

    private mounted = false;
    private timer: NodeJS.Timeout | undefined;

    state: IPasswortSetDialogState = {
        busy: false,
        finished: false,
        message: '',
        newpassword1: '',
        newpassword2: '',
    }

    constructor(props: IPasswortSetDialogProps) {
        super(props);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    componentDidMount() {
        // @ts-ignore
        document.addEventListener("keydown", this.onKeyPressed);
        this.mounted = true;
    }

    componentWillUnmount() {

        this.mounted = false;

        // @ts-ignore
        document.removeEventListener("keydown", this.onKeyPressed);
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (this.mounted && (e.key === 'Escape')) this.onCancel();
    }

    private onCancel() {
        if (this.state.busy) return;
        this.props.onCancel();
    }

    private closeDialog() {
        this.props.onDone();
    }

    private async onAction() {

        if (this.state.finished) this.closeDialog();

        if (!this.state.newpassword1) {
            this.setState({message: 'Bitte geben Sie das neue Passwort ein.'});
            return;
        }

        if (!this.state.newpassword2) {
            this.setState({message: 'Bitte geben Sie das neue Passwort erneut ein.'});
            return;
        }

        if (this.state.newpassword1 !== this.state.newpassword2) {
            this.setState({message: 'Die eingegebenen Passwörter sind nicht gleich.'});
            return;
        }

        if (!isStrongPassword(this.state.newpassword1)) {
            this.setState({message: 'Bitte wählen Sie ein starkes Passwort.'});
            return;
        }

        try {

            this.setState({busy: true});
            await post('/api/auth/setpwd', {
                username: this.props.username.toLowerCase().trim(),
                password: this.state.newpassword1.trim()
            });

            this.setState({busy: false, message: '', finished: true, countdown: 3});

            this.timer = setInterval(() => {
                if (this.mounted) {
                    const val = this.state.countdown || 0;
                    if (val > 1) this.setState({countdown: this.state.countdown! - 1});
                    else this.closeDialog();
                }
            }, 1000);

        } catch (err) {
            failureController.failure("PasswordSetDialog.tsx/save", err);
        }
    }

    private getButtons() {
        return (
            <div className="buttons">
                <button disabled={this.state.busy} className="button is-text" style={{width: "120px"}}
                        onClick={() => this.onCancel()}>Abbrechen
                </button>
                <button disabled={this.state.busy}
                        className="button is-purple"
                        onClick={() => this.onAction()}>{this.state.finished ? "Schliessen" : "Passwort setzen"}
                </button>
            </div>
        );
    }

    private getContent() {

        let msg: JSX.Element | null = null;
        if (this.state.busy) msg = <div />;
        else if (this.state.finished) msg = (<div style={{fontSize: "0.9rem"}}>
            <span className="translate">Passwort geändert. Das Fenster wird in</span>
            <span> {this.state.countdown} </span>
            <span className="translate">Sekunden geschlossen.</span>
        </div>);
        else if (this.state.message) msg = <div style={{fontSize: "0.9rem", color: "red"}}>{this.state.message}</div>;

        return (
            <form>
                <div className="block" style={{paddingBottom: "0.1rem"}}>
                    <span className="translate">Mit dieser Funktionen können Sie das Passwort des Schülers festlegen. Bitte vergessen Sie nicht den Schüler über sein neues Passwort zu informieren.</span>
                </div>

                <PasswordField
                    id="old-password"
                    placeholder="Neues Passwort"
                    onKeyPress={(e) => this.onKeyPressed(e)}
                    disabled={this.state.busy}
                    value={this.state.newpassword1}
                    onChange={(e: any) => this.setState({...this.state, newpassword1: e.target.value})}>
                </PasswordField>

                <PasswordField
                    id="new-password"
                    placeholder="Wiederholung"
                    onKeyPress={(e) => this.onKeyPressed(e)}
                    disabled={this.state.busy}
                    value={this.state.newpassword2}
                    onChange={(e: any) => this.setState({...this.state, newpassword2: e.target.value})}>
                </PasswordField>
                <div className="block" style={{height: "24px"}}>
                    {msg}
                </div>
            </form>
        );
    }

    render() {

        return <DialogTemplate
                    buttons={this.getButtons()}
                    content={this.getContent()}
                    title={"Passwort setzen für " + this.props.name}
                    onCancel={() => this.onCancel()}

        />;
    }
}