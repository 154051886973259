import React from 'react';
import {Link, useLocation} from "react-router-dom";

export const TopNav: React.FunctionComponent<{}> = (props) => {
    return (
        <>
            <NavItem name="Kurse" root="courses" target="list"/>
            <NavItem name="Benutzer" root="users" target="list"/>
            <NavItem name="Kontakte" root="contacts" target="list"/>
            <NavItem name="Rechnungen" root="invoices" target="list"/>
            <NavItem name="Bestellungen" root="orders" target="list"/>
        </>
    );
}

const NavItem: React.FunctionComponent<{root: string, name: string, target: string}> = (props) => {

    const location = useLocation();

    return (

        <Link to={`/${props.root}/${props.target}`} className="ml-5">
            <span className={`link ${location.pathname.startsWith("/" + props.root) ? "selected" : ""}`}>
                {props.name}
            </span>
        </Link>
    );
}