import React from 'react';
import {get} from "../../misc/communication";
import {Spinner} from "../../components/Spinner";
import failureController from './../../controller/failure';
import {IInvoice} from "@institutsitya/sitya-common/types/model/invoice";
import {formatDate} from "../../misc/date";
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {getCourses, SELBSTSTUDIUM} from "../../controller/courses";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {getStatusColor, getStatusText} from "../../controller/orders";
import {Link} from "react-router-dom";
import {formatCurrency} from "../../misc/currency";
import {OrderCancellationDialog} from "../../dialogs/OrderCancellationDialog";
import {OrderRefundDialog} from "../../dialogs/OrderRefundDialog";
import {Label} from "../../components/Label";
import {Status} from "../../components/Status";

interface IContactDetailOrdersProps {
    uid: string;
}

interface IContactDetailOrdersState {
    busy: boolean;
    courses?: ICourse[];
    orders?: IOrder[];
    invoices?: IInvoice[];

    selectedOrder?: IOrder,
    cancel?: boolean;
    refund?: boolean
}

export class ContactDetailOrders extends React.Component<IContactDetailOrdersProps, IContactDetailOrdersState> {

    private mounted = false;

    state: IContactDetailOrdersState = {
        busy: true
    };

    componentDidMount() {

        this.mounted = true;
        this.fetch();
    }

    componentDidUpdate(prevProps: Readonly<IContactDetailOrdersProps>, prevState: Readonly<IContactDetailOrdersState>, snapshot?: any) {
        if (prevProps.uid !== this.props.uid) {
            this.fetch();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private async fetch() {
        try {

            const p1 = get(`/api/contacts/${this.props.uid}/orders`);
            const p2 = get(`/api/contacts/${this.props.uid}/invoices`);
            const p3 = getCourses();

            const [orders, invoices, courses] = await Promise.all([p1, p2, p3]);

            this.setState({busy: false, invoices: invoices, orders: orders, courses: courses});

        } catch (error) {
            failureController.failure("ContactDetailOrders.tsx/fetch", error);
            this.setState({busy: false});
        }
    }

    render() {

        let content: JSX.Element;
        let dialog: JSX.Element | null = null;

        if (!this.state.invoices || !this.state.orders) {
            content =
                <div style={{
                    width: "100%",
                    height: "rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}><Spinner text="Daten werden geladen"/></div>;
        } else {

            if (this.state.cancel && this.state.selectedOrder) {
                dialog = <OrderCancellationDialog order={this.state.selectedOrder}
                                                  onDone={() => {
                                                      this.setState({cancel: false});
                                                      this.fetch();
                                                  }}
                                                  onCancel={() => this.setState({cancel: false})}
                />
            }

            if (this.state.refund && this.state.selectedOrder) {
                dialog = <OrderRefundDialog order={this.state.selectedOrder}
                                            onDone={() => {
                                                this.setState({refund: false});
                                                this.fetch();
                                            }}
                                            onCancel={() => this.setState({refund: false})}
                />
            }

            const orders = this.state.orders!
                .sort((a, b) => (a.orderDate > b.orderDate) ? 1 : ((b.orderDate > a.orderDate) ? -1 : 0))
                .map((o, orderIdx) => {

                    const positions = o.courses.map((pos, posIdx) => {

                        const key = (pos.key === "humanenergetiker") ? "humanenergetiker-6" : pos.key;
                        const name = this.state.courses!.find((c) => c.key === key)?.name || "Fernlehrgang";
                        const style = (pos.style === SELBSTSTUDIUM) ? "im Selbststudium" : "mit Lernbegleitung";

                        return (
                            <div key={`pos-${orderIdx}-${posIdx}`}>
                                <span>{name} {style}</span>
                            </div>
                        );
                    });

                    const invoiceRows = this.state.invoices!
                        .filter((x) => x.order === o._id)
                        .sort((a, b) => (a.invoiceDate > b.invoiceDate) ? 1 : ((b.invoiceDate > a.invoiceDate) ? -1 : 0))
                        .map((i, invoiceIdx) => {

                            let invoiceType = "Rechnung";
                            if (i.type === "cancellation") invoiceType = "Storno";
                            if ((i.type as any) === "refund") invoiceType = "Gutschrift";

                            return (
                                <tr key={`invoice-${i.name}`}>
                                    <td>
                                        <Link to={`/invoices/detail/${i._id}`}><span
                                            className="link">{i.name}</span></Link>
                                    </td>
                                    <td>
                                        <span>{i.history.createdby}</span>
                                    </td>
                                    <td>
                                        <span>{invoiceType} </span>
                                    </td>
                                    <td>
                                        <span>{formatDate(i.invoiceDate)}</span>
                                    </td>
                                    <td>
                                        <span>{formatCurrency(i.totalGross)}</span>
                                    </td>
                                </tr>
                            );
                        });

                    const remainingValue = o.totalValueGross + o.totalRefundGross;

                    let buttons = (
                        <div className="mt-2 buttons">
                            <button disabled={remainingValue <= 0} className="button is-small" style={{width: "120px"}}
                                    onClick={() => this.setState({refund: true, selectedOrder: o})}>
                                <span>Gutschrift</span>
                            </button>
                            <button disabled={o.status === "cancelled"} className="button is-small"
                                    style={{width: "120px"}}
                                    onClick={() => this.setState({cancel: true, selectedOrder: o})}>
                                <span>Stornieren</span>
                            </button>
                        </div>
                    );

                    return (
                        <div key={o.name}>
                            {orderIdx > 0 ?
                                <div className="my-5" style={{borderBottom: "1px solid gainsboro"}}></div> : null}
                            <div>
                                <span style={{fontSize: "larger"}}>Bestellung </span>
                                <Link to={`/orders/detail/${o._id}`}><span className="link"
                                                                           style={{fontSize: "larger"}}>{o.name}</span></Link>
                                <span style={{fontSize: "larger"}}> vom {formatDate(o.orderDate)}</span>
                            </div>
                            <Label className="mt-2" text="Status"/>
                            <Status color={getStatusColor(o.status)} text={getStatusText(o.status)}/>
                            {buttons}
                            <Label className="mt-2" text="Kurse"/>
                            {positions}
                            <table className="mt-4 table is-bordered is-narrow" style={{fontSize: "0.9rem"}}>
                                <thead>
                                  <tr>
                                    <td><Label text="Rechnung"/></td>
                                    <td><Label text="Benutzer"/></td>
                                    <td><Label text="Art"/></td>
                                    <td><Label text="Rechnungsdatum"/></td>
                                    <td><Label text="Brutto"/></td>
                                  </tr>
                                </thead>
                                <tbody>
                                {invoiceRows}
                                </tbody>
                            </table>

                        </div>
                    );
                })

            content = <div>{orders}</div>;
        }
        return (
            <>
                {content}
                {dialog}
            </>
        );
    }
}
