import React from "react";

import logo from './../assets/logo.svg';

interface ILogoProps {
    width?: string;
}

export const Logo: React.FunctionComponent<ILogoProps> = (props) => {
    return <img
        alt=""
        src={logo}
        style={{width: props.width || "5rem"}}
    />;
}