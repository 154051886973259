import {IInvoice} from "@institutsitya/sitya-common/types/model/invoice";

let memory: IInvoice | undefined;

export function rememberInvoice(invoice: IInvoice) {
    memory = invoice;
}

export function getInvoiceName(invoice: IInvoice | string): string | undefined {
    if (typeof invoice === 'string') {
        if (invoice === memory?._id) return getInvoiceName(memory);
        return "";
    } else {
        let name = invoice.name;
        if (invoice.type === "cancellation") name += " (Storno)";
        if ((invoice.type as any) === "refund") name += " (Gutschrift)";
        return name;
    }
}

export function getInvoiceDate(invoice: IInvoice | string): Date | undefined {
    if (typeof invoice === 'string') {
        if (invoice === memory?._id) return getInvoiceDate(memory);
        return new Date();
    } else {
        return new Date(invoice.invoiceDate);
    }
}