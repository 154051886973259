import React from 'react';
import {IInvoice} from "@institutsitya/sitya-common/types/model/invoice";
import {formatDate} from "../../misc/date";
import {formatCurrency} from "../../misc/currency";
import {Link} from "react-router-dom";
import {Label} from "../../components/Label";

interface IOrderDetailInvoicesProps {
    invoices: IInvoice[];
}

interface IOrderDetailInvoicesState {
}

export class OrderDetailInvoices extends React.Component<IOrderDetailInvoicesProps, IOrderDetailInvoicesState> {

    render() {

        const invoices = this.props.invoices
            .sort((a, b) => (a.invoiceDate > b.invoiceDate) ? 1 : ((b.invoiceDate > a.invoiceDate) ? -1 : 0))
            .map((invoice) => {

            let invoiceType = "Rechnung";
            if ((invoice.type as any) === "refund") invoiceType = "Gutschrift";
            else if (invoice.type === "cancellation") invoiceType = "Storno";

            return (
                <tr key={invoice.name}>
                    <td>
                        <Link to={`/invoices/detail/${invoice._id}`}>
                            <span className="link">{invoice.name}</span>
                        </Link>

                    </td>
                    <td>
                        <span>{invoice.history.createdby}</span>
                    </td>
                    <td>
                        <span>{invoiceType}</span>
                    </td>
                    <td>
                        <span>{formatDate(invoice.invoiceDate)}</span>
                    </td>
                    <td>
                        <span>{formatCurrency(invoice.totalNet)}</span>
                    </td>
                    <td>
                        <span>{formatCurrency(invoice.totalGross)}</span>
                    </td>
                </tr>);
        });

        return (
            <div>
                <table className="table is-narrow mt-2 is-bordered" style={{fontSize: "0.9rem"}}>
                    <thead>
                    <tr>
                        <td><Label text="Rechnung"/></td>
                        <td><Label text="Benutzer"/></td>
                        <td><Label text="Art"/></td>
                        <td><Label text="Rechnungsdatum"/></td>
                        <td><Label text="Netto"/></td>
                        <td><Label text="Brutto"/></td>
                    </tr>
                    </thead>
                    <tbody>
                        {invoices}
                    </tbody>
                </table>
            </div>
        );
    }
}
