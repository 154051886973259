import {IContact} from "@institutsitya/sitya-common/types/model/contact";

let memory: IContact | undefined;

export function rememberContact(contact: IContact) {
    memory = contact;
}

export function getContactName(contact: IContact | string): string | undefined {
    if (typeof contact === 'string') {
        if (contact === memory?._id) return getContactName(memory);
        return undefined;
    } else {
        return contact.fullName;
    }
}

export function getContactMail(contact: IContact | string): string | undefined {
    if (typeof contact === 'string') {
        if (contact === memory?._id) return getContactMail(memory);
        return undefined;
    } else {
        return contact.mail;
    }
}