import {host, post} from '../misc/communication';

export interface ISystemUser {
    userid: string;
    username: string;
    name: string;
    role: string;
}

export type AuthChangedArgs = {
    user: ISystemUser | undefined;
}

export interface IAuthObserver {
    onAuthChanged?: (args: AuthChangedArgs) => void;
}

export interface IAuthController {

    // Adds an observer. Do not forget to remove the observer when not needed any longer.
    addHandler: (observer: IAuthObserver) => void;

    // Removes an observer, which has been previously registered by addHandler(...).
    removeHandler: (observer: IAuthObserver) => void;

    isLoggedIn: () => boolean;

    getUserName: () => string;

    getUserId: () => string;

    login: (login?: string, password?: string, persist?: boolean) => Promise<ISystemUser>;

    getAccessToken: () => Promise<string | undefined>;

    logout: () => Promise<boolean>;
}

class AuthController implements IAuthController {

    private user?: ISystemUser;

    private accessToken?: { token: string, ts: number, ttl: number, exp: number };

    private observers: IAuthObserver[] = [];

    public addHandler(observer: IAuthObserver) {
        this.observers.push(observer);
    }

    private processAccessToken(token: string) {
        let valid = false;
        if (token) {
            try {
                const parts = token.split(".");
                if (parts.length === 3) {
                    const decoded = JSON.parse(window.atob(parts[1]));
                    const now = new Date().getTime() / 1000;
                    valid = true;
                    this.accessToken = {
                        token,
                        ttl: decoded.ttl || 600,
                        exp: decoded.exp,
                        ts: now
                    }
                }
            } catch (e) {}
        }

        if (!valid) this.accessToken = undefined;
    }

    public async getAccessToken() {
        try {

            let renew = true;
            if (this.accessToken) {

                renew = false;

                // Current timestamp in seconds
                const now = new Date().getTime() / 1000;

                // Check expiry timestamp of token
                if (this.accessToken.exp - now < 60) renew = true;

                // Check ttl based against local issue time to avoid clock-time issues
                if (this.accessToken.ts + this.accessToken.ttl - now < 60) renew = true;
            }

            if (renew) await this.renewAccessToken();

        } catch (e) {}

        return this.accessToken?.token;
    }

    public removeHandler(observer: IAuthObserver) {
        const index = this.observers.findIndex((x) => x === observer);
        if (index >= 0) this.observers.splice(index, 1);
    }

    public isLoggedIn() {
        return this.user !== undefined;
    }

    public getUserName() {
        return this.user?.name ?? "Unbekannt";
    }

    public getUserId() {
        return this.user?.userid ?? "";
    }

    public async login(login?: string, password?: string, persist?: boolean) {
        if (login && password) return await this.loginWithCredentials(login, password, persist);
        else return await this.loginWithJwt();
    }

    public async logout() {

        try {
            await this.post('/api/auth/logout');
            this.user = undefined;
            this.sendOnAuthChanged({ user: this.user });
            return true;
        } catch (error) {
            throw error;
        }
    }

    private async loginWithCredentials(login: string, password: string, persist?: boolean) {

        const body = {
            username: login.toLowerCase().trim(),
            password: password.trim(),
            persist: persist
        }

        try {

            const response = await this.post('/api/auth/login/credentials', body) as any;

            if (response.user.role !== "admin") throw new Error("ERR_NO_ADMIN");

            this.user = {
                username: response.user.mail,
                userid: response.user._id.toString(),
                name: response.user.name,
                role: response.user.role
            }

            this.processAccessToken(response.token);

            this.sendOnAuthChanged({ user: this.user });
            return this.user;

        } catch (error) {
            throw error;
        }
    }

    private async loginWithJwt() {
        try {

            const response = await this.post('/api/auth/login/token') as any;

            if (response.user.role !== "admin") throw new Error("ERR_NO_ADMIN");

            this.user = {
                username: response.user.mail,
                userid: response.user._id.toString(),
                name: response.user.name,
                role: response.user.role
            }

            this.processAccessToken(response.token);

            this.sendOnAuthChanged({ user: this.user });
            return this.user;

        } catch (error) {
            throw error;
        }
    }

    private async renewAccessToken() {
        try {
            const response = await this.post('/api/auth/login/token') as any;
            this.processAccessToken(response.token);
        } catch (error) {
            throw error;
        }
    }

    private async post(endpoint: string, body?: any) {

        const headers: HeadersInit  = {'Content-Type': 'application/json'}

        const options: RequestInit = {
            method: "POST",
            headers
        };

        options.credentials = 'include';
        if (body) options.body = JSON.stringify(body);

        let errorMessage = "";

        try {

            const response = await fetch(host + endpoint, options);

            let body: any = null;

            try {
                if (response.status !== 204) body = await response.json();
            } catch (e) {
                console.error(e);
            }

            if ((response.status >= 200) && (response.status < 300)) return body;
            else errorMessage = body?.error?.code;

        } catch (e) {

            console.error(e);
            errorMessage = "ERR_CONNECTION";
        }

        throw new Error(errorMessage);
    }

    private sendOnAuthChanged(args: AuthChangedArgs) {
        this.observers
            .filter((o) => o.onAuthChanged !== undefined)
            .forEach((o) => o.onAuthChanged!(args));
    }
}

// Provides functions and events related to authentication. Use addHandler(...) to add an observer.
let controller: IAuthController | undefined;
export function getAuthController() {
    if (!controller) controller = new AuthController();
    return controller;
}