import React from 'react';
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {CourseAssignmentStyle} from "@institutsitya/sitya-common/types/model/user";
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {formatDate} from "../../misc/date";
import {Link} from "react-router-dom";
import {isSameCourse} from "../../controller/courses";

interface IUserCourseAssignmentProps {
    disabled?: boolean;
    course: ICourse;
    name: string;
    style: CourseAssignmentStyle
    order?: IOrder;
    onDelete: () => void;
    onToggleStyle: () => void;
}

export const UserCourseAssignment: React.FunctionComponent<IUserCourseAssignmentProps> = (props) => {

    let hint: JSX.Element | undefined = undefined;
    const pos = props.order?.courses.find((x) => isSameCourse(x.key, props.course.key));
    if (pos) {
        hint = (
          <div style={{fontSize: "0.80rem"}}>
              <Link to={`/orders/detail/${props.order?._id}`}>
                  <span className="link">{props.order?.name}</span>
              </Link>
          </div>
        );
    }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
                <div className="mb-2" style={{fontSize: "larger"}}>
                    <div>
                        <span>{props.name}</span><br/>
                    </div>
                    {hint}
                </div>
                <button className="button is-text" onClick={() => props.onDelete()} disabled={props.disabled}>
                    <span className="translate">Kurs löschen</span>
                </button>
            </div>
            <div>
                <div className="pretty p-default p-curve p-primary">
                    <input id="persist" type="checkbox" disabled={props.disabled} checked={props.style === CourseAssignmentStyle.WithoutSupport} onChange={(e: any) => {props.onToggleStyle()}} />
                    <div className="state p-primary">
                        <label>Selbststudium</label>
                    </div>
                </div>
            </div>
        </div>
    );
}