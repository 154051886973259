import React from 'react';
import {put} from "../misc/communication";
import DialogTemplate from "../templates/DialogTemplate";
import {IContact} from "@institutsitya/sitya-common/types/model/contact";
import {Label} from "../components/Label";
import stringify from "json-stable-stringify";
import hash from "../misc/hash";
import {isValidEMail} from "@institutsitya/sitya-common/misc/validator";
import {getCountries} from "../misc/country";

interface IFormFailure {
    field: string;
    message?: string;
}

interface IContactEditDialogState {
    busy: boolean;
    fullName: string;
    mail: string;
    phone?: string;
    street?: string;
    postalCode?: string;
    country?: string;
    city?: string;
    error?: string;
    mailAlreadyIsInUse: boolean;
}

interface IContactEditDialogProps {
    contact: IContact;
    onCancel: () => void;
    onDone: () => void;
}

export class ContactEditDialog extends React.Component<IContactEditDialogProps, IContactEditDialogState> {

    private mounted = false;
    private checksum: number;

    state: IContactEditDialogState = {
        busy: false,
        fullName: this.props.contact.fullName,
        mail: this.props.contact.mail,
        phone: this.props.contact.phone,
        street: this.props.contact.street,
        city: this.props.contact.city,
        postalCode: this.props.contact.postalCode?.toString(),
        country: this.props.contact.country,
        mailAlreadyIsInUse: false
    }

    private calcChecksum(data: any) {
        const values: any = {};
        const fieldNames = ["fullName", "phone", "mail", "street", "postalCode", "city", "country"];
        fieldNames.forEach((field) => values[field] = data[field]?.toString().trim() ?? "");
        return hash(stringify(values));
    }

    private getContactData(contact?: IContact) {

        const data: any = {
            fullName: this.state.fullName.trim(),
            mail: this.state.mail.trim(),
            phone: this.state.phone?.trim(),
            street: this.state.street?.trim(),
            postalCode: this.state.postalCode?.trim(),
            city: this.state.city?.trim(),
            country: this.state.country?.trim()
        };

        if (contact) {
            if (data.fullName === contact.fullName) delete data["fullName"];
            if (data.mail === contact.mail) delete data["mail"];
            if (data.phone === contact.phone) delete data["phone"];
            if (data.street === contact.street) delete data["street"];
            if (data.postalCode === contact.postalCode) delete data["postalCode"];
            if (data.city === contact.city) delete data["city"];
            if (data.country === contact.country) delete data["country"];
        }

        return data;
    }

    constructor(props: IContactEditDialogProps) {
        super(props);
        this.checksum = this.calcChecksum(props.contact);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    componentDidMount() {
        // @ts-ignore
        document.addEventListener("keydown", this.onKeyPressed);
        this.mounted = true;
    }

    componentWillUnmount() {

        this.mounted = false;

        // @ts-ignore
        document.removeEventListener("keydown", this.onKeyPressed);
    }

    private async onKeyPressed(e: React.KeyboardEvent) {
        if (this.mounted && (e.key === 'Escape')) this.onCancel();
        if (this.mounted && (e.key === 'Enter')) {
            const checksum = this.calcChecksum(this.state);
            if (checksum !== this.checksum && !this.state.busy) {
                const failures = this.getFailures();
                if (failures.length === 0) await this.onAction();
            }
        }
    }

    private onCancel() {
        if (this.state.busy) return;
        this.props.onCancel();
    }

    private async onAction() {

        this.setState({error: undefined, busy: true});

        try {
            const body = this.getContactData(this.props.contact);
            await put(`/api/contacts/${this.props.contact._id}`, body);
            this.props.onDone();

            if (this.mounted) this.setState({mailAlreadyIsInUse: false, error: undefined, busy: false});

        } catch (error: any) {

            let errorCode = error?.message?.toString() || "";
            const mailAlreadyIsInUse = (errorCode === "ERR_MAIL_NOT_UNIQUE");
            let errorMessage = (errorCode === "ERR_MAIL_NOT_UNIQUE") ?
                "Die E-Mailadresse ist bereits in Verwendung" :
                "Die Eingabe konnte nicht verarbeitet werden";

            if (this.mounted) this.setState({mailAlreadyIsInUse, error: errorMessage, busy: false});
        }
    }

    private getFailures() {
        const failures: IFormFailure[] = [];
        if (this.state.fullName.trim().length === 0) failures.push({field: "fullName"});
        if (!isValidEMail(this.state.mail)) failures.push({field: "mail"});
        if (this.state.postalCode && !parseInt(this.state.postalCode)) failures.push({field: "postalCode"});
        if (this.state.mailAlreadyIsInUse && !failures.find((x) => x.field === "mail")) failures.push({field: "mail"});

        return failures;
    }

    private getContent() {

        const failures = this.getFailures();
        const hasFailure = (field: string) => failures.some((y) => y.field === field);

        const countries = getCountries()
            .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            .map((country) => <option key={country.key} value={country.key}>{country.name}</option>);

        if (!this.props.contact.country) countries.unshift( <option key="none" value=""></option>);

        let content: JSX.Element | undefined = undefined;
        content = (
            <div>
                <div>
                    <div>
                        <Label text="Name"/>
                    </div>
                    <div className="mt-1">
                        <input
                            className={`input ${hasFailure("fullName") ? "is-danger" : ""}`}
                            type="text"
                            value={this.state.fullName}
                            onChange={(e) => this.setState({fullName: e.target.value})}/>
                    </div>
                </div>
                <div className="mt-2">
                    <div>
                        <Label text="Mail"/>
                    </div>
                    <div className="mt-1">
                        <input
                            className={`input ${hasFailure("mail") ? "is-danger" : ""}`}
                            type="text"
                            value={this.state.mail}
                            onChange={(e) => this.setState({mail: e.target.value, mailAlreadyIsInUse: false})}/>
                    </div>
                </div>
                <div className="mt-2">
                    <div>
                        <Label text="Telefon"/>
                    </div>
                    <div className="mt-1">
                        <input
                            className={`input ${hasFailure("phone") ? "is-danger" : ""}`}
                            type="text"
                            value={this.state.phone}
                            onChange={(e) => this.setState({phone: e.target.value})}/>
                    </div>
                </div>
                <div className="mt-2">
                    <div>
                        <Label text="Adresse"/>
                    </div>
                    <div className="mt-1">
                        <input
                            className={`input ${hasFailure("street") ? "is-danger" : ""}`}
                            type="text"
                            value={this.state.street}
                            onChange={(e) => this.setState({street: e.target.value})}/>
                    </div>
                </div>
                <div className="mt-2" style={{display: "flex"}}>
                    <div style={{width: "100px"}}>
                        <div>
                            <Label text="PLZ"/>
                        </div>
                        <div className="mt-1">
                            <input
                                className={`input ${hasFailure("postalCode") ? "is-danger" : ""}`}
                                type="text"
                                value={this.state.postalCode}
                                onChange={(e) => this.setState({postalCode: e.target.value})}/>
                        </div>
                    </div>
                    <div style={{flex: 1}} className="ml-2">
                        <div>
                            <Label text="Ort"/>
                        </div>
                        <div className="mt-1">
                            <input
                                className={`input ${hasFailure("city") ? "is-danger" : ""}`}
                                type="text"
                                value={this.state.city}
                                onChange={(e) => this.setState({city: e.target.value})}/>
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <div>
                        <Label text="Land"/>
                    </div>
                    <div className="mt-1">
                        <div className="select" style={{width: "100%"}}>
                            <select style={{width: "100%"}} value={this.state.country}
                                    onChange={(e) => {
                                        this.setState({country: e.target.value})
                                    }}>
                                {countries}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );

        const message = this.state.error ? <span>{this.state.error}</span> : undefined;
        const style = this.state.error ? {background: "#f14668", color: "white", fontWeight: "bold"} : undefined;
        return (
            <div>
                {content}
                <div className="pl-2 mt-2" style={{
                    minHeight: "2rem",
                    display: "flex",
                    marginBottom: "-1rem",
                    alignItems: "center", ...style
                }}>
                    {message}
                </div>
            </div>
        );
    }

    private getButtons() {

        const failures = this.getFailures();
        const checksum = this.calcChecksum(this.state);

        return (
            <div className="buttons" style={{justifyContent: "flex-end"}}>
                <button disabled={this.state.busy} className="button is-text" style={{width: "120px"}}
                        onClick={() => this.onCancel()}>Abbrechen
                </button>
                <button disabled={this.state.busy || this.checksum === checksum || failures.length > 0}
                        className="button is-purple"
                        style={{width: "120px"}} onClick={() => this.onAction()}>Aktualisieren
                </button>
            </div>
        );
    }

    render() {
        return (
            <DialogTemplate
                content={this.getContent()}
                buttons={this.getButtons()}
                title={"Kontakt bearbeiten"}
                onCancel={() => this.onCancel()}
            />
        );
    }
}

