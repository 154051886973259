import React from 'react';
import {IHistory} from "@institutsitya/sitya-common/types/model/history";
import format from "date-fns/format";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDoubleUp} from "@fortawesome/pro-regular-svg-icons";
import {Label} from "./Label";

export interface IHistoryProps {
    history?: IHistory;
}

export const HistoryContainer: React.FunctionComponent<IHistoryProps> = (props) => {

    if (!props.history) return <div/>;

    return (
        <div className="box">
            <div className="pb-2" style={{borderBottom: "2px solid #f5f5f5"}}>
                <span>HISTORIE</span>
            </div>
            <div className="mt-2">
                <History history={props.history}/>
            </div>
        </div>
    );
}

export const History: React.FunctionComponent<IHistoryProps> = (props) => {

    if (!props.history) return <div/>;

    const result: JSX.Element[] = [];

    if (props.history.createdby && props.history.createdat) {
        result.push(
            <div key="history-created">
                <Label text="Erzeugt"/>
                <div>{format(new Date(props.history.createdat), "dd.LL.yyyy HH:mm")}</div>
                <div>{props.history.createdby}</div>
            </div>
        );
    }

    if (props.history.modifiedby && props.history.modifiedat) {
        result.push(
            <div key="history-modified" className="mt-1">
                <Label text="Geändert"/>
                <div>{format(new Date(props.history.modifiedat), "dd.LL.yyyy HH:mm")}</div>
                <div>
                    {props.history.modifiedby}
                </div>
            </div>
        );
    }

    return (
        <>
            {result}
        </>
    );
}