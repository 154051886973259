import React from 'react';

import './PdfPreviewDialog.scss';
import {Spinner} from "../components/Spinner";
import DialogTemplate from "../templates/DialogTemplate";

export interface IPdfProvider {

    getPdfBlob?: () => Blob;
    getPdfPath?: () => string;
}

interface IPdfPreviewProps {
    onClose?: () => void;
    title: string;
    provider: IPdfProvider;
}

interface IPdfPreviewState {
    url: string;
    busy: boolean;
}

export default class PdfPreviewDialog extends React.Component<IPdfPreviewProps, IPdfPreviewState> {

    public state: IPdfPreviewState = {
        url: "",
        busy: true
    };

    private objectURL = "";
    private ismounted = false;

    private closeDialog() {
        if (this.props.onClose) this.props.onClose();
    }

    public constructor(props: IPdfPreviewProps) {
        super(props);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    public componentDidMount() {

        this.ismounted = true;
        document.addEventListener("keydown", this.onKeyDown);

        if (this.props.provider.getPdfPath) {
            this.setState({url: this.props.provider.getPdfPath(), busy: false});
            return;
        }

        if (this.props.provider.getPdfBlob) {

            // Decouple as otherwise no spinner is shown
            setTimeout(() => this.createPdf(), 500);
        }
    }

    public createPdf() {

        try {
            const renderer = this.props.provider.getPdfBlob as () => Blob;
            const data = renderer();

            this.objectURL = URL.createObjectURL(data);
            this.setState({url: this.objectURL, busy: false});

        } catch (e) {
            console.error(e);
        }
    }

    public componentWillUnmount() {

        this.ismounted = false;
        document.removeEventListener("keydown", this.onKeyDown);

        if (this.objectURL) {
            URL.revokeObjectURL(this.objectURL);
            this.objectURL = "";
        }
    }

    private onKeyDown(ev: KeyboardEvent) {
        if ((ev.key === "Escape") && (this.props.onClose)) this.props.onClose();
    }

    private getButtons() {
        return (
            <div className="buttons">
                <button disabled={this.state.busy} className="button is-purple" style={{width: "120px"}}
                        onClick={() => this.closeDialog()}>Schliessen
                </button>
            </div>
        );
    }


    render() {

        const content = this.state.busy ?
            <div style={{width: "100%", height: "60vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}><Spinner delay={0} text="Dokument wird generiert"/></div> :
            <embed style={{width: "100%", height: "60vh", padding: 0, margin: 0}} src={this.state.url}/>;

        return <DialogTemplate
            content={content}
            width="60vw"
            buttons={this.getButtons()}
            title={"Druckvorschau"}
            onCancel={() => this.closeDialog()}
        />;
    }
}
