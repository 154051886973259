import React from 'react';
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {formatCurrency} from "../misc/currency";
import failureController from "../controller/failure";
import {Label} from "../components/Label";
import DialogTemplate from "../templates/DialogTemplate";
import DatePicker from "../components/DatePicker";
import {post} from "../misc/communication";
import {getAuthController} from "../controller/auth";

interface IOrderPaymentDialogState {
    busy: boolean;
    extended: boolean;
    invalid: boolean;
    delta: number;
    text: string;
    date?: Date;
    valueError?: string;
    valueNumeric: number;
    value: string;
}

interface IOrderPaymentDialogProps {
    order: IOrder;
    onCancel: () => void;
    onDone: () => void;
}

export class OrderPaymentDialog extends React.Component<IOrderPaymentDialogProps, IOrderPaymentDialogState> {

    private mounted = false;

    state: IOrderPaymentDialogState = {
        extended: false,
        busy: false,
        delta: 0,
        date: new Date(),
        text: "",
        invalid: false,
        valueNumeric: this.props.order.totalValueGross + this.props.order.totalRefundGross - this.props.order.totalPaymentGross,
        value: formatCurrency(this.props.order.totalValueGross + this.props.order.totalRefundGross - this.props.order.totalPaymentGross, false)
    }

    constructor(props: IOrderPaymentDialogProps) {
        super(props);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    componentDidMount() {
        // @ts-ignore
        document.addEventListener("keydown", this.onKeyPressed);
        this.mounted = true;

        this.verify(this.state.value);
    }

    componentWillUnmount() {

        this.mounted = false;

        // @ts-ignore
        document.removeEventListener("keydown", this.onKeyPressed);
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (this.mounted && (e.key === 'Escape')) this.onCancel();
    }

    private onCancel() {
        if (this.state.busy) return;
        this.props.onCancel();
    }

    private async onAction() {

        this.setState({busy: true});

        try {

            let date = this.state.date || new Date();
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12, 0, 0);

            const body = {
                order: this.props.order._id,
                amount: this.state.valueNumeric,
                comment: this.state.text,
                date: date
            }

            await post(`/api/payments/`, body);
            this.props.onDone();

        } catch (error) {
            failureController.failure("OrderPaymentDialog.tsx/onAction", error);
            this.setState({busy: false});
        }
    }

    private onValueChanged(val: string) {
        this.verify(val);
    }

    private verify(value: string) {

        let error: string | undefined = undefined;
        let invalid = true;
        let delta = 0;
        let valueNumeric = 0;

        if (!value || !value.length) {
            error = "Bitte Wert eingeben";
        } else {

            let valueInternal = value.trim().replace(".", "");
            valueInternal = valueInternal.replace(",", ".");

            valueNumeric = parseFloat(valueInternal);
            const valueOrder = Math.round((this.props.order.totalValueGross + this.props.order.totalRefundGross - this.props.order.totalPaymentGross) * 100) / 100;

            if (isNaN(valueNumeric)) {
                error = "Bitte gültigen Wert eingeben";
            } else {
                delta = valueNumeric - valueOrder;
                invalid = false;
            }
        }

        this.setState({
            value: value,
            valueNumeric: valueNumeric,
            delta: delta,
            valueError: error,
            invalid: invalid
        });
    }

    private getContent() {
        let word = this.props.order.totalRefundGross ? " verbleibenden " : "";
        let hint = `Sie erstellen eine Zahlung zur Bestellung ${this.props.order.name} im ${word} Wert von ${formatCurrency(this.props.order.totalValueGross + this.props.order.totalRefundGross)} brutto.`;
        hint += " Die Kurse werden zum Zeitpunkt des Kursbeginns automatisch freigeschaltet, sofern die Bestellung vollständig bezahlt wurde.";

        let valueHint: JSX.Element | undefined = undefined;
        if (this.state.valueError) valueHint =
          <span style={{fontSize: "smaller", color: "red"}}>{this.state.valueError || "Unbekannter Fehler"}</span>;
        else {
            if (this.state.delta === 0) valueHint =
              <span style={{fontSize: "smaller", color: "green"}}>Vollständig bezahlt</span>;
            else if (this.state.delta > 0) valueHint = <span
              style={{fontSize: "smaller", color: "red"}}>{formatCurrency(this.state.delta)} zuviel erhalten</span>;
            else if (this.state.delta < 0) valueHint =
              <span style={{fontSize: "smaller", color: "red"}}>{formatCurrency(this.state.delta)} offen</span>;
        }

        let content: JSX.Element | undefined = undefined;
        content = (
          <div className="mt-4">
              <div style={{display: "flex", width: "100%"}}>
                  <div>
                      <div>
                          <Label text="Datum"/>
                      </div>
                      <div className="mt-1">
                          <DatePicker date={this.state.date} onChanged={(date) => this.setState({date: date})}/>
                      </div>
                  </div>
                  <div className="ml-2" style={{flexGrow: 1}}>
                      <div>
                          <Label text="Referenz"/>
                      </div>
                      <div className="mt-1">
                          <div className="control">
                              <input placeholder="Referenz oder Kommentar" className="input" type="text"
                                     value={this.state.text}
                                     onChange={(e) => this.setState({text: e.target.value})}/>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="mt-2">
                  <Label text="Betrag"/>
              </div>
              <div className="mt-1">
                  <div className="control" style={{width: "160px"}}>
                      <input className="input" type="text" value={this.state.value}
                             onChange={(e) => this.onValueChanged(e.target.value)}/>
                  </div>
                  <div>
                      {valueHint}
                  </div>
              </div>
          </div>
        );

        return (
          <div>
              <div className="mt-5">
                  <span>{hint}</span>
              </div>
              {content}
          </div>
        );
    }

    private getButtons() {
        return (
          <div className="buttons" style={{justifyContent: "flex-end"}}>
              <button disabled={this.state.busy} className="button is-text" style={{minWidth: "120px"}}
                      onClick={() => this.onCancel()}>Abbrechen
              </button>
              <button disabled={this.state.busy || this.state.invalid}
                      className="button is-purple"
                      style={{minWidth: "120px"}} onClick={() => this.onAction()}>Zahlung erfassen
              </button>
          </div>
        );
    }

    render() {
        return (
          <DialogTemplate
            content={this.getContent()}
            buttons={this.getButtons()}
            title={"Zahlung zu Bestellung " + this.props.order.name}
            onCancel={() => this.onCancel()}
          />
        );
    }
}