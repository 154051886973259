import React from "react";

interface IStatusProps {
    text: string;
    color: string;
}

export const Status: React.FunctionComponent<IStatusProps> = (props) => {

    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <span className="status-circle mr-2" style={{backgroundColor: props.color}}/>
            <span className="status-text">{props.text}</span>
        </div>
    );
}

