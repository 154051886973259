import React from 'react';

interface IModule {
    name: string;
    key: string;
}

export interface IModuleSelectorProps {
    onSelectionChanged?: (course?: IModule) => void;
    modules?: IModule[];
    modulesSelection?: IModule;
}

export const ModuleSelector: React.FunctionComponent<IModuleSelectorProps> = (props) => {

    const selectModule = (m?: IModule) => {
        if (m !== props.modulesSelection) {
            if (props.onSelectionChanged) props.onSelectionChanged(m); 
        }
    }
    
    const items = props.modules?.map((m) => {
        return <li key={m.key} className={(m.key === props.modulesSelection?.key) ? "is-active" : ""} onClick={(e) => {
            selectModule(m);
        }}>
            <a>{m.name}</a>
        </li>;
    });

    return <div className="tabs" style={{ width: "100%", marginBottom: "1rem" }}>
        <ul>
            {items}
        </ul>
    </div>;
}