import React from 'react';
import {get} from "../../misc/communication";

import format from 'date-fns/format'
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import failureController from "../../controller/failure";
import {IInvoice} from "@institutsitya/sitya-common/types/model/invoice";
import {getInvoiceName, rememberInvoice} from "../../controller/invoices";
import {formatCurrency} from "../../misc/currency";
import {getCountryName} from "../../misc/country";
import SearchText from "../../components/Search";
import {TextMarker} from "../../components/TextMarker";
import {Pagination} from "../../components/Pagination";
import {Currency} from "../../components/Currency";
import {Amount} from "../../components/Amount";
import ListViewTemplate from "../../templates/ListViewTemplate";
import {FilterController, IFilter, IFilterObserver} from "../../controller/filter";
import {add, startOfDay, startOfMonth, startOfToday, startOfWeek, startOfYear} from "date-fns";
import {Filter} from "../../components/Filter";
import navigationController from "../../controller/navigation";
import {IGetGenericResponse, IStats} from "@institutsitya/sitya-common/types/api/stats";

interface IInvoiceListViewProps {
}

interface IInvoiceListViewState {
    busy: boolean;
    redirect?: string;
    records: IInvoice[];
    stats?: IStats;
}

let lastLimit = 50;
let lastSkip = 0;

export class InvoiceListView extends React.Component<IInvoiceListViewProps, IInvoiceListViewState> implements IFilterObserver  {

    private mounted = false;
    private filterController = new FilterController("invoices");

    state: IInvoiceListViewState = {
        busy: true,
        records: [],
    };

    public constructor(props: IInvoiceListViewProps) {
        super(props);

        const now = new Date();

        this.filterController.addPreset("today", "Heute", [{name: "start", value: startOfToday().toISOString()}]);
        this.filterController.addPreset("yesterday", "Gestern", [{name: "start", value: startOfDay(add(now, {days: -1})).toISOString()}, {name: "end", value: startOfToday().toISOString()}]);
        this.filterController.addPreset("week", "Woche", [{name: "start", value: startOfWeek(now, {weekStartsOn: 1}).toISOString()}]);
        this.filterController.addPreset("month", "Monat", [{name: "start", value: startOfMonth(now).toISOString()}]);

        this.filterController.addDate({mode: "range", key: "invoiceDate", start: "start", end: "end", name: "Datum"});

        this.filterController.addSelector({
            key: "type", name: "Art", values: [
                {key: "regular", value: "Rechnung"},
                {key: "cancellation", value: "Storno"},
                {key: "refund", value: "Gutschrift"}
            ]
        });

        this.filterController.addSelector({
            key: "country", name: "Land", values: [
                {key: "at", value: "Österreich"},
                {key: "de", value: "Deutschland"},
                {key: "ch", value: "Schweiz"},
                {key: "it", value: "Italien"}
            ]
        });
    }

    componentDidMount() {
        this.mounted = true;
        this.filterController.addHandler(this);
        this.fetch(this.filterController.getFilter());
    }

    componentWillUnmount() {
        this.mounted = false;
        this.filterController.removeHandler(this);
    }

    onFilterChanged(): void {
        this.fetch(this.filterController.getFilter());
    }

    private async fetch(filter: IFilter[], options?: { limit?: number, skip?: number }) {
        try {

            let url = `/api/invoices`;
            let first = true;

            const addParam = (name: string, value: any) => {
                url += first ? "?" : "&";
                url += `${name}=${value}`;
                first = false;
            }

            for (const f of filter) addParam(f.name, f.value);
            if (options?.limit !== undefined) addParam("limit", options.limit);
            if (options?.skip !== undefined) addParam("skip", options.skip);

            this.setState({busy: true});

            const records = await get(url) as IGetGenericResponse<IInvoice>;

            this.setState({busy: false, records: records.data, stats: records.stats});

        } catch (error) {
            failureController.failure("InvoiceListView.tsx/fetch", error);
            this.setState({busy: false, records: []});
        }
    }

    private selectInvoice(invoice: IInvoice) {

        rememberInvoice(invoice);

        navigationController
          .reset()
          .records(this.state.records.map((r) => r._id!));

        const url = `/invoices/detail/${invoice._id}`;
        this.setState({redirect: url});
    }

    private getPagination() {

        if (!this.state.stats) return undefined;

        return (
            <Pagination
                disabled={this.state.busy}
                limit={this.state.stats.limit}
                skip={this.state.stats.skip}
                count={this.state.stats.count}
                onChange={(limit: number, skip: number) => this.applyPagination(limit, skip)}
            />
        );
    }

    private getFilter() {
        return <Filter controller={this.filterController} hint="Suche nach Rechnung oder Kunde"/>;
    }

    private getHeader() {
        return (
            <tr>
                <th>Rechnung</th>
                <th>Datum</th>
                <th>Kunde</th>
                <th>Land</th>
                <th>Brutto</th>
            </tr>
        );
    }

    private getRows() {

        if (!this.state.records) return undefined;

        return this.state.records.map((invoice) => {

            const order = invoice.order as IOrder;

            return (
                <tr key={invoice._id} onClick={() => this.selectInvoice(invoice)} style={{cursor: "pointer"}}>
                    <td><TextMarker text={getInvoiceName(invoice)} highlight={this.filterController.getText()}/></td>
                    <td><span>{format(new Date(invoice.invoiceDate), "dd.LL.yyyy HH:mm")}</span></td>
                    <td><TextMarker text={order.fullName} highlight={this.filterController.getText()}/></td>
                    <td><TextMarker text={getCountryName(invoice.country)} highlight={this.filterController.getText()}/></td>
                    <td><span>{formatCurrency(invoice.totalGross)}</span></td>
                </tr>
            );
        });
    }

    private getInfo() {

        if (!this.state.stats) return undefined;

        return (
            <>
                <Amount value={this.state.stats.count} singular="Rechnung" plural="Rechnungen"/>
                <Currency className="ml-4" value={this.state.stats.data?.sumGross || 0}/>
            </>
        );
    }

    render() {

        return (
            <ListViewTemplate
                pagination={this.getPagination()}
                busy={this.state.busy}
                redirect={this.state.redirect}
                filter={this.getFilter()}
                header={this.getHeader()}
                info={this.getInfo()}
                rows={this.getRows()}/>
        );
    }

    private applyPagination(limit: number, skip: number) {
        lastLimit = limit;
        lastSkip = skip;
        this.fetch(this.filterController.getFilter(), {limit: limit, skip: skip});
    }
}