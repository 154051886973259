import React from 'react';
import {useParams} from 'react-router-dom';
import failureController from './../../controller/failure';
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {
    createCourse,
    getCourse,
    getCourseFiles, getSnippets,
    IEditableFile,
    IEditableSnippet,
    resetCourses,
    updateCourse,
    updateCourseFiles,
    updateSnippets
} from "../../controller/courses";
import DetailViewTemplate from "../../templates/DetailViewTemplate";
import {IHistory} from "@institutsitya/sitya-common/types/model/history";

import {FileType, IFileCourse} from "@institutsitya/sitya-common/types/model/file";

import Uploader, {IUpload} from "../../misc/uploader";
import {faAngleDown} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModuleView} from "./ModuleView";
import {ModuleSelector} from "./ModuleSelector";
import UploadProgress from "./UploadProgress";
import {Label} from "../../components/Label";
import stringify from "json-stable-stringify";
import {ICourseFilePatch, ISnippetPatch} from "@institutsitya/sitya-common/types/api/course";
import {LinkCourseDialog} from "../../dialogs/LinkCourseDialog";
import * as uuid from 'uuid';
import {ISnippet} from "@institutsitya/sitya-common/types/model/snippet";

interface ICoursesDetailViewProps {
    courseKey: string;
}

interface IModule {
    key: string;
    name: string;
}

interface IUploadContext {
    modulekey: string;
    type: string;
    submodule?: string;
}

interface ISnippetContext {
    snippet: IEditableSnippet | undefined;
    modulekey: string;
    submodule?: string;
}

interface ICoursesDetailViewState {
    busy: boolean;
    uid: string | undefined;
    key: string;
    name: string;
    message: string;
    redirect?: string;
    linkDialog?: boolean;
    history?: IHistory;

    showUploadProgress: boolean;
    uploads: IUpload[];

    info?: string;

    // Array of all files of this course
    files: IEditableFile[];

    snippets: IEditableSnippet[];

    moduleSelection?: IModule;
    modules: IModule[];
}

export const CourseDetailViewWrapper: React.FunctionComponent = (props) => {
    const {key} = useParams();
    if (key) return <CourseDetailView courseKey={key}/>;
    return <div/>;
}

export class CourseDetailView extends React.Component<ICoursesDetailViewProps, ICoursesDetailViewState> {

    private checksum = 0;
    private uploader?: Uploader;
    private mounted = false;

    private uploadContext: IUploadContext = {
        modulekey: '',
        submodule: '',
        type: ''
    }

    private snippetContext: ISnippetContext | undefined = undefined;

    state: ICoursesDetailViewState = {
        busy: true,
        key: "",
        name: "",
        message: "",
        uid: undefined,
        showUploadProgress: false,
        uploads: [],
        modules: [],
        files: [],
        snippets: []
    };

    componentDidMount() {
        this.mounted = true;
        this.fetch(this.props.courseKey);
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate(prevProps: Readonly<ICoursesDetailViewProps>, prevState: Readonly<ICoursesDetailViewState>, snapshot?: any) {
        if (prevProps.courseKey !== this.props.courseKey) {
            this.fetch(this.props.courseKey);
        }
    }

    private isNewRecord() {
        return this.state.uid === undefined;
    }

    private isDisabled() {
        if (this.state.busy) return true;
        return false;
    }

    private isEditableFileChanged(f: IEditableFile) {

        if (f.status === 'unchanged') return false;
        if (f.status === 'new') return true;

        if (f.current.submodule !== f.initial.submodule) return true;
        if (f.current.type !== f.initial.type) return true;
        if (f.current.path !== f.initial.path) return true;
        if (f.deleted) return true;

        return false;
    }

    private isEditableSnippetChanged(f: IEditableSnippet) {

        if (f.status === 'unchanged') return false;
        if (f.status === 'new') return true;

        if (f.current.submodule !== f.initial.submodule) return true;
        if (f.current.data.name !== f.initial.data.name) return true;
        if (f.current.data.src !== f.initial.data.src) return true;
        if (f.deleted) return true;

        return false;
    }

    private isChanged(skipFilesAndSnippets: boolean = false) {
        if (this.isNewRecord()) return true;

        if (!skipFilesAndSnippets) {
            if (this.state.files.some((x) => this.isEditableFileChanged(x))) return true;
            if (this.state.snippets.some((x) => this.isEditableSnippetChanged(x))) return true;
        }

        const course: ICourse = {
            name: this.state.name,
            key: this.state.key,
            modules: this.state.modules
        };

        const checksum = this.calcChecksum(course);
        return checksum !== this.checksum;
    }

    protected getFiles(module?: string) {
        return this.state.files.filter((f) => f.modulekey === module && !f.deleted);
    }

    protected getSnippets(module?: string) {
        return this.state.snippets.filter((f) => f.modulekey === module && !f.deleted);
    }

    protected onDeleteFile(f: IEditableFile) {

        const files = JSON.parse(JSON.stringify(this.state.files)) as IEditableFile[];
        const file = files.find((file) => (f.name === file.name) && !f.deleted);

        if (file) {

            if (file.status === 'new') files.splice(files.indexOf(file), 1);
            else {
                file.deleted = true;
                file.status = 'changed';
            }

            this.setState({files: files});
        }
    }

    protected onChangeFile(f: IEditableFile, field: string, value: string) {

        const files = JSON.parse(JSON.stringify(this.state.files)) as IEditableFile[];
        const file = files.find((file) => (f.name === file.name) && !f.deleted);

        if (file) {
            if (file.status !== 'new') file.status = 'changed';
            if (field === 'type') file.current.type = value;
            if (field === 'submodule') file.current.submodule = value;
            this.setState({files: files});
        }
    }

    protected onChangeSnippet(s: IEditableSnippet, field?: string, value?: string) {
        if (field === 'submodule') {
            const snippets = JSON.parse(JSON.stringify(this.state.snippets)) as IEditableSnippet[];
            const snippet = snippets.find((x) => (x.id === s.id) && !s.deleted);
            if (snippet) {
                if (snippet.status !== 'new') snippet.status = 'changed';
                snippet.current.submodule = value;
                this.setState({snippets: snippets});
            }

        } else {
            this.snippetContext = {
                snippet: s,
                modulekey: s.modulekey,
                submodule: s.current.submodule
            }

            this.setState({linkDialog: true});

        }
    }

    protected onDeleteSnippet(s: IEditableSnippet) {

        const snippets = JSON.parse(JSON.stringify(this.state.snippets)) as IEditableSnippet[];
        const snippet = snippets.find((x) => (x.id === s.id) && !s.deleted);

        if (snippet) {
            if (snippet.status === 'new') snippets.splice(snippets.indexOf(snippet), 1);
            else {
                snippet.deleted = true;
                snippet.status = 'changed';
            }
            this.setState({snippets: snippets});
        }
    }

    private async uploadFiles(files: FileList | null) {

        if (!files) return;

        this.setState({showUploadProgress: true});

        const tmp = this.state.files.filter((f) => !f.deleted && f.modulekey !== this.uploadContext.modulekey);
        const unavailableFileNames = tmp.map((t) => t.name);

        this.uploader = new Uploader(files, unavailableFileNames);
        this.uploader.on('change', (err, data) => {

            const tmp = JSON.parse(JSON.stringify(this.uploader!.getUploads()));
            this.setState({uploads: tmp});

            if (this.uploader?.isFinished()) {

                if (this.uploader?.isCancelled()) this.setState({showUploadProgress: false});
                else {

                    const uploads = this.uploader!.getUploads().filter((u) => u.status === 'done' && u.target);
                    const files = JSON.parse(JSON.stringify(this.state.files)) as IEditableFile[];

                    uploads.forEach((u) => {

                        const existing = files.find((f) => f.name === u.name);

                        if (existing) {

                            if (existing.status !== 'new') existing.status = 'changed';
                            existing.modulekey = this.uploadContext.modulekey;
                            existing.current.submodule = this.uploadContext.submodule;
                            existing.current.type = this.uploadContext.type;
                            existing.current.path = u.target!;
                            existing.current.size = u.size;
                            existing.deleted = false;
                            existing.current.timestamp = new Date(u.lastModified).toISOString();

                        } else {

                            const ef: IEditableFile = {

                                name: u.name,
                                modulekey: this.uploadContext.modulekey,
                                deleted: false,
                                status: 'new',

                                initial: {
                                    submodule: this.uploadContext.submodule,
                                    type: this.uploadContext.type,
                                    timestamp: new Date(u.lastModified).toISOString(),
                                    size: u.size,
                                    path: u.target!
                                },

                                current: {
                                    submodule: this.uploadContext.submodule,
                                    type: this.uploadContext.type,
                                    timestamp: new Date(u.lastModified).toISOString(),
                                    size: u.size,
                                    path: u.target!
                                }
                            }

                            files.push(ef);
                        }
                    });

                    this.setState({files: files});
                }

                const input = document.querySelector('[name=file]') as HTMLInputElement;
                if (input) input.value = '';

                this.uploader?.reset();
                this.uploader = undefined;
            }
        });

        this.uploader.start();
    }

    private cancelUpload() {
        if (this.uploader) this.uploader.cancel();
    }

    private getContent() {

        const key = this.state.moduleSelection?.key;
        const files = this.getFiles(key);
        const snippets = this.getSnippets(key);
        let content: JSX.Element;

        let canDelete = false;

        if (!files?.length && !snippets?.length) {
            canDelete = (key?.startsWith("B") || key?.startsWith("C")) || false;
            if (key?.startsWith("A")) {
                const regularModules = this.state.modules.filter((m) => m.key.startsWith('A'));
                regularModules.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
                const lastRegularModule = (regularModules && regularModules.length) ? regularModules[regularModules.length - 1] : undefined;
                if (lastRegularModule?.key === key) canDelete = true;
            }
        }

        const onDeleteModule = canDelete ? () => {

            const modules = JSON.parse(JSON.stringify(this.state.modules)) as IModule[];
            const idx = modules.findIndex((m) => m.key === this.state.moduleSelection?.key);
            if (idx >= 0) modules.splice(idx, 1);
            this.setState({modules: modules, moduleSelection: modules[idx > 0 ? idx - 1 : 0]});

        } : undefined;

        const module = this.state.moduleSelection ?
            <ModuleView
                files={files}
                snippets={snippets}
                modulekey={this.state.moduleSelection.key}
                name={this.state.moduleSelection.name}
                onDeleteModule={onDeleteModule}
                onChangeFile={(f: IEditableFile, field: string, value: string) => this.onChangeFile(f, field, value)}
                onDeleteFile={(f: IEditableFile) => this.onDeleteFile(f)}

                onChangeSnippet={(snippet: IEditableSnippet, field?: string, value?: string) => this.onChangeSnippet(snippet, field, value)}
                onDeleteSnippet={(snippet: IEditableSnippet) => this.onDeleteSnippet(snippet)}

            /> : undefined;

        content = <div>
            <div>
                <div className="mt-4">
                    <Label text="Name"/>
                    <input className="input mt-2" type="text" placeholder="Name" disabled={this.isDisabled()}
                           value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
                </div>
                <div className="mt-4">
                    <ModuleSelector
                        modules={this.state.modules}
                        modulesSelection={this.state.moduleSelection}
                        onSelectionChanged={(m) => this.setState({moduleSelection: m})}
                    />
                </div>
                {module}
            </div>
        </div>;

        const uploadform = <form method="POST" encType="multipart/form-data">
            <input id="invisibleinput" type="file" name="file" multiple={true}
                   style={{display: "block", visibility: "hidden", width: 0, height: 0}}
                   onChange={(e) => {
                       e.preventDefault();
                       this.uploadFiles(e.target.files);
                   }}
            /></form>;

        let dialog: JSX.Element | undefined = undefined;
        if (this.state.showUploadProgress) {
            dialog = <UploadProgress uploads={this.state.uploads}
                                     onClose={(cancel: boolean) => {
                                         if (cancel) this.cancelUpload();
                                         else this.setState({showUploadProgress: false});
                                     }}/>;
        }
        ;

        return (
            <div>
                {uploadform}
                {dialog}
                {content}
            </div>
        );
    }

    private calcChecksum(course: ICourse) {

        // Perhaps not required, but to make sure
        const modules = JSON.parse(JSON.stringify(course.modules)) as IModule[];
        modules.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));

        // Use a deterministic stringify for constant sort order of keys to get a consistent hash
        const value = stringify({
            name: course.name.trim(),
            modules: modules
        });

        // Create a super simple hash
        // https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
        const hash = (text: string) => {
            var hash = 0, i, chr;
            for (i = 0; i < text.length; i++) {
                chr = text.charCodeAt(i);
                hash = ((hash << 5) - hash) + chr;
                hash |= 0;
            }
            return hash;
        }

        return hash(value);
    }

    async fetch(key: string) {

        try {

            const p1 = (key !== 'neu' ? getCourse(key) : new Promise<ICourse>((r, j) => {

                const course: ICourse = {
                    _id: undefined,
                    name: '',
                    modules: [
                        {key: 'A01', name: 'Modul 1'},
                        {key: 'A02', name: 'Modul 2'},
                        {key: 'A03', name: 'Modul 3'},
                        {key: 'A04', name: 'Modul 4'},
                        {key: 'A05', name: 'Modul 5'},
                        {key: 'A06', name: 'Modul 6'},
                        {key: 'C01', name: 'Abschlussprüfung'}
                    ],
                    key: ''
                };

                r(course);
            }));

            const p2 = (key !== 'neu' ? getCourseFiles(key) : new Promise<IFileCourse[]>((r, j) => r([])));
            const p3 = (key !== 'neu' ? getSnippets(key) : new Promise<ISnippet[]>((r, j) => r([])));

            const [course, files, links] = await Promise.all([p1, p2, p3]);

            if (this.mounted) {

                const editableFiles = files.map((x) => this.getEditableFile(x));
                const editableSnippets = links.map((x) => this.getEditableSnippet(x));

                this.checksum = this.calcChecksum(course!);

                this.setState({
                    busy: false,
                    history: course!.history,
                    uid: course!._id,
                    name: course!.name,
                    key: course!.key,
                    modules: course!.modules,
                    files: editableFiles,
                    snippets: editableSnippets,
                    moduleSelection: course!.modules[0]
                });
            }

        } catch (err) {
            failureController.failure("CourseDetailView.tsx/fetch", err);
            this.setState({busy: false});
        }
    }

    private getEditableFile(f: IFileCourse) {

        const ef: IEditableFile = {

            name: f.name,
            id: f._id,
            deleted: false,
            modulekey: f.modulekey,
            status: 'unchanged',

            initial: {
                submodule: f.submodule,
                path: f.path,
                type: f.type,
                timestamp: new Date(f.timestamp).toISOString(),
                size: f.size,
            },

            current: {
                submodule: f.submodule,
                path: f.path,
                type: f.type,
                timestamp: new Date(f.timestamp).toISOString(),
                size: f.size,
            }
        }

        return ef;
    }

    private getEditableSnippet(s: ISnippet) {

        const el: IEditableSnippet = {

            id: s._id,
            deleted: false,
            modulekey: s.modulekey,
            timestamp: new Date(s.history?.modifiedat || s.history?.createdat || new Date()),
            status: 'unchanged',

            type: s.type,

            initial: {
                submodule: s.submodule,
                data: s.data,
            },

            current: {
                submodule: s.submodule,
                data: s.data,
            }
        }

        return el;
    }

    private getButtons() {

        const changed = this.isChanged();

        return (
            <div className="buttons">
                <button className="button is-purple mr-2" style={{width: "150px"}}
                        disabled={!changed || this.isDisabled()} onClick={() => this.save()}>
                    <span className="translate">Speichern</span>
                </button>
                {this.createNewDropdown()}
                {this.createUploadButtons()}
                {this.createLinkButtons()}

            </div>);
    }

    private createNewDropdown() {

        const modules = JSON.parse(JSON.stringify(this.state.modules)) as IModule[];
        modules.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));

        const regularModules = modules.filter((m) => m.key.startsWith('A'));
        regularModules.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));

        const lastRegularModule = (regularModules && regularModules.length) ? regularModules[regularModules.length - 1] : undefined;
        const semesterExamen = this.state.modules.find((m) => m.key === "B01");
        const finalExamen = this.state.modules.find((m) => m.key === "C01");

        let lastModuleNr = lastRegularModule ? parseInt(lastRegularModule.key.replace("A", "")) : 0;
        if (isNaN(lastModuleNr)) lastModuleNr = 0;

        const createNewModule = (key: string, name: string) => {

            const m = {
                key: key,
                name: name
            };

            modules.push(m);

            modules.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
            this.setState({modules: modules, moduleSelection: m});
        };

        const newRegularModuleHandler = (lastModuleNr < 9) ? () => {
            const number = lastModuleNr + 1;
            const key = "A0" + number.toString();
            const name = `Modul ${number}`;
            createNewModule(key, name);
        } : undefined;

        const newSemesterExamenHandler = semesterExamen ? undefined : () => createNewModule("B01", "Semesterprüfung");
        const newFinaleExamenHandler = finalExamen ? undefined : () => createNewModule("C01", "Abschlussprüfung");

        return (
            <div className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                    <button className="button mr-2" aria-haspopup="true" aria-controls="dropdown-menu"
                            style={{width: "150px"}} disabled={this.isDisabled()}>
                        <span className="translate">Neues Modul</span>
                        <span className="icon is-small">
                                <FontAwesomeIcon className="mt-1" icon={faAngleDown}/>
                            </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content" style={{width: "250px"}}>
                            <span key="A"
                                  className={"translate dropdown-item link navbar-item " + (newRegularModuleHandler ? "menulink" : "is-disabled")}
                                  onClick={newRegularModuleHandler}>
                                {`Modul ${lastModuleNr + 1}`} anlegen
                            </span>
                        <span key="B"
                              className={"translate dropdown-item link navbar-item " + (newSemesterExamenHandler ? "menulink" : "is-disabled")}
                              onClick={newSemesterExamenHandler}>
                                Semesterprüfung anlegen
                        </span>
                        <span key="C"
                              className={"translate dropdown-item link navbar-item " + (newFinaleExamenHandler ? "menulink" : "is-disabled")}
                              onClick={newFinaleExamenHandler}>
                                Abschlussprüfung anlegen
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    private createUploadButtons() {

        if (!this.state.moduleSelection) return undefined;
        const key = this.state.moduleSelection.key;

        const result: JSX.Element[] = [];

        if (key.startsWith("A")) {
            result.push(this.createUploadButtonWithSubmodule(FileType.Regular, key, "Lehrunterlagen",));
            result.push(this.createUploadButtonWithSubmodule(FileType.Attachment, key, "Beiblatt"));
            result.push(this.createUploadButtonWithSubmodule(FileType.Test, key, "Wochentest"));
        }

        if (key.startsWith("B")) result.push(this.createUploadButton(FileType.Test, key, "Semesterprüfung hochladen"));
        if (key.startsWith("C")) result.push(this.createUploadButton(FileType.Test, key, "Abschlussprüfung hochladen"));

        return result;
    }

    private createLinkButtons() {

        if (!this.state.moduleSelection) return undefined;
        const key = this.state.moduleSelection.key;

        const result: JSX.Element[] = [];

        if (key.startsWith("A")) result.push(this.createLinkButtonWithSubmodule(key));
        if (key.startsWith("B")) result.push(this.createLinkButton(key, "Link"));
        if (key.startsWith("C")) result.push(this.createLinkButton(key, "Link"));


        return result;
    }

    private selectFiles(type: FileType, modulekey: string, submodule?: string) {

        this.uploadContext = {
            modulekey: modulekey,
            type: type,
            submodule: submodule
        }

        const input = document.querySelector('[name=file]') as HTMLInputElement;
        if (input) input.click();
    }

    private createSnippets(modulekey: string, submodule?: string) {
        this.snippetContext = {
            snippet: undefined,
            modulekey: modulekey,
            submodule: submodule
        }
        this.setState({linkDialog: true});
    }

    private createUploadButton(type: FileType, key: string, name: string) {

        return (
            <button key={key + type.toString()} className="button" onClick={() => this.selectFiles(type, key)}>
                <span className="translate">{name}</span>
            </button>);
    }

    private createLinkButton(key: string, name: string) {

        return (
            <button key={key} className="button" style={{width: "150px"}} onClick={() => {
                this.createSnippets(key)

            }} >
                <span className="translate">{name}</span>
            </button>);
    }

    private createUploadButtonWithSubmodule(type: FileType, key: string, name: string) {

        const number = parseInt(key.substr(1));

        const rows = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'].map((submodule) => {
            return (
                <span key={submodule} className="dropdown-item link navbar-item menulink translate" onClick={(e) => {
                    this.selectFiles(type, key, `${number}${submodule}`);
                }}>
                    {name} für Modul {number}{submodule} hochladen
            </span>
            )
        });

        return (
            <div key={key + type.toString()} className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                    <button className="button mr-2" aria-haspopup="true" aria-controls="dropdown-menu"
                            style={{width: "150px"}} disabled={this.isDisabled()}>
                        <span>{name}</span>
                        <span className="icon is-small">
                                <FontAwesomeIcon className="mt-1" icon={faAngleDown}/>
                            </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content" style={{width: "330px"}}>
                        {rows}
                    </div>
                </div>
            </div>);
    }

    private createLinkButtonWithSubmodule(key: string) {

        const number = parseInt(key.substr(1));

        const rows = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'].map((submodule) => {
            return (
                <span key={submodule} className="dropdown-item link navbar-item menulink translate" onClick={(e) => {
                    this.createSnippets(key, `${number}${submodule}`);
                }}>
                    Link für Modul {number}{submodule} erstellen
            </span>
            )
        });

        return (
            <div key={`link-${key.toString()}`} className="dropdown is-hoverable">
                <div className="dropdown-trigger">
                    <button className="button mr-2" aria-haspopup="true" aria-controls="dropdown-menu"
                            style={{width: "150px"}} disabled={this.isDisabled()}>
                        <span>Link</span>
                        <span className="icon is-small">
                                <FontAwesomeIcon className="mt-1" icon={faAngleDown}/>
                            </span>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <div className="dropdown-content" style={{width: "330px"}}>
                        {rows}
                    </div>
                </div>
            </div>);
    }

    async save() {
        try {

            let errormsg = "";
            if (!this.state.name || !this.state.name.trim()) errormsg = "Bitte Namen eingeben";

            if (errormsg) {
                this.setState({message: errormsg});
                return;
            }

            this.setState({busy: true});

            const course: ICourse = {
                _id: this.state.uid,
                name: this.state.name.trim(),
                modules: this.state.modules,
                key: this.state.key
            }

            // Reset only if there is a "real" change to the course structure
            if (this.isChanged(true)) resetCourses();

            // Save always to update history
            let updatedCourse = await (this.isNewRecord() ? createCourse(course) : updateCourse(course));

            // Create patchset with changes to documents (added, changed, removed)
            // Files have been uploaded previously
            const filesPatchSet = this.createFilesPatchset();
            let filesPatchResult = await updateCourseFiles(updatedCourse.key, filesPatchSet);

            // Update files with patch result
            const files = JSON.parse(JSON.stringify(this.state.files)) as IEditableFile[];
            filesPatchResult.forEach((r) => {

                const f = files.find((f) => f.name === r.name);
                if (f) {

                    f.code = r.code;
                    f.message = r.message;

                    if ((r.code === 200) && (r.file)) {
                        if (f.status === 'changed' && f.deleted) files.splice(files.indexOf(f), 1);
                        else this.updateEditableFile(f, r.file);
                    } else console.error(`Fehler bei Verarbeitung von ${r.name}: ${r.message}`);
                }
            });

            // Sort after save to avoid records changing position during edit
            this.sortFiles(files);

            // Create patchset with changes to snippets (added, changed, removed)
            const snippetsPatchSet = this.createLinksPatchset();
            let snippetsPatchResult = await updateSnippets(updatedCourse.key, snippetsPatchSet);

            // Update links with patch result
            const snippets = JSON.parse(JSON.stringify(this.state.snippets)) as IEditableSnippet[];
            snippetsPatchResult.forEach((r) => {
                const s = snippets.find((x) => x.id === r.id);
                if (s) {

                    s.code = r.code;
                    s.message = r.message;

                    if ((r.code === 200) && r.snippet) {
                        if (r.snippet._id) s.id = r.snippet._id.toString();
                        if (s.status === 'changed' && s.deleted) snippets.splice(snippets.indexOf(s), 1);
                        else this.updateEditableSnippet(s, r.snippet);
                    } else console.error(`Fehler bei Verarbeitung von ${r.snippet?.data?.name ?? "einem Link"}: ${r.message}`);
                }
            });

            this.sortSnippets(snippets);

            // Simply sum error message for files
            const errors1 = filesPatchResult.filter((r) => r.code !== 200)?.length || 0;
            const errors2 = snippetsPatchResult.filter((r) => r.code !== 200)?.length || 0;
            const total1 = filesPatchResult?.length || 0;
            const total2 = snippetsPatchResult?.length || 0;

            const totalCount = total1 + total2;
            const errorCount = errors1 + errors2;
            const message = (errorCount) ? `${errorCount} von ${totalCount} Lehrunterlagen konnten nicht gespeichert werden` : undefined;

            this.checksum = this.calcChecksum(updatedCourse);

            this.setState({
                busy: false,
                files: files,
                snippets: snippets,
                message: message || '',
                uid: updatedCourse._id,
                key: updatedCourse.key
            });

            if ((this.calcChecksum(course) === this.calcChecksum(updatedCourse)) && !message) {
                const url = `/courses/detail/${updatedCourse.key}`;
                if (window.location.pathname !== url) window.history.replaceState({}, "", url);
            } else {
                this.setState({message: "Die Daten konnten nicht vollständig gespeichert werden."});
            }

        } catch (err) {
            this.setState({busy: false, message: (err as any).message});
        }
    }

    private createFilesPatchset(): ICourseFilePatch[] {

        const patchSet: ICourseFilePatch[] = [];

        this.state.files.forEach((f) => {

            if (f.status !== 'unchanged' || this.isEditableFileChanged(f)) {

                const p: ICourseFilePatch = {
                    status: f.status,
                    id: f.id,
                    name: f.name,
                    deleted: f.deleted,
                    modulekey: f.modulekey,
                };

                if (!f.deleted) {

                    if ((f.current.submodule !== f.initial.submodule) || (f.status === 'new')) p.submodule = f.current.submodule;
                    if ((f.current.type !== f.initial.type) || (f.status === 'new')) p.type = f.current.type;

                    if ((f.current.path !== f.initial.path) || (f.status === 'new')) {
                        p.path = f.current.path;
                        p.size = f.current.size;
                        p.timestamp = f.current.timestamp;
                    }
                }

                patchSet.push(p);
            }
        });

        return patchSet;
    }

    private createLinksPatchset(): ISnippetPatch[] {

        const patchSet: ISnippetPatch[] = [];

        this.state.snippets.forEach((l) => {

            if (l.status !== 'unchanged' || this.isEditableSnippetChanged(l)) {

                const p: ISnippetPatch = {
                    status: l.status,
                    id: l.id,
                    type: "link",
                    deleted: l.deleted,
                    modulekey: l.modulekey,
                };

                if (!l.deleted) {
                    if ((l.current.submodule !== l.initial.submodule) || (l.status === 'new')) p.submodule = l.current.submodule;
                    if ((l.current.data.name !== l.initial.data.name) || (l.current.data.src !== l.initial.data.src) || (l.status === 'new')) {
                        p.data = {
                            name: l.current.data.name,
                            src: l.current.data.src
                        };
                    }
                }

                patchSet.push(p);
            }
        });

        return patchSet;
    }

    private updateEditableFile(ef: IEditableFile, f: IFileCourse) {

        ef.name = f.name;
        ef.id = f._id;
        ef.deleted = f.deleted;
        ef.modulekey = f.modulekey;
        ef.status = 'unchanged';

        ef.initial = {
            submodule: f.submodule,
            path: f.path,
            type: f.type,
            timestamp: new Date(f.timestamp).toISOString(),
            size: f.size,
        };

        ef.current = {
            submodule: f.submodule,
            path: f.path,
            type: f.type,
            timestamp: new Date(f.timestamp).toISOString(),
            size: f.size,
        };
    }

    private updateEditableSnippet(el: IEditableSnippet, s: ISnippet) {

        el.id = s._id;
        el.deleted = false;
        el.modulekey = s.modulekey;
        el.status = 'unchanged';
        el.type = s.type;

        el.initial = {
            submodule: s.submodule,
            data: s.data
        }

        el.current = {
            submodule: s.submodule,
            data: s.data
        };
    }

    private sortFiles(files: IEditableFile[]) {
        files.sort((a, b) => {
            const sa = (a.initial.submodule || '') + "|" + a.initial.type + "|" + a.name;
            const sb = (b.initial.submodule || '') + "|" + b.initial.type + "|" + b.name;
            if (sa > sb) return 1;
            if (sb > sa) return -1;
            return 0;
        });
    }

    private sortSnippets(snippets: IEditableSnippet[]) {
        snippets.sort((a, b) => {
            const sa = (a.initial.submodule || '') + "|" + a.initial.data.name;
            const sb = (b.initial.submodule || '') + "|" + b.initial.data.name;
            if (sa > sb) return 1;
            if (sb > sa) return -1;
            return 0;
        });
    }

    private getDialog() {

        if (this.state.linkDialog && this.snippetContext) {

            const linkData = {
                src: this.snippetContext.snippet?.current?.data.src || "",
                name: this.snippetContext.snippet?.current?.data.name || "",
            }

            return <LinkCourseDialog link={linkData}
                                     heading={this.snippetContext.snippet ? "Link bearbeiten" : "Link erstellen"}
                                     onOk={(link) => {
                                         const snippets = JSON.parse(JSON.stringify(this.state.snippets)) as IEditableSnippet[];

                                         if (this.snippetContext?.snippet) {
                                             const existingSnippet = snippets.find((x) => x.id === this.snippetContext?.snippet?.id);
                                             if (existingSnippet) {
                                                 if (existingSnippet.status !== 'new') existingSnippet.status = 'changed';
                                                 existingSnippet.current.data = {
                                                     src: link.src,
                                                     name: link.name
                                                 };
                                             }
                                         } else {
                                             const snippetData = {
                                                 submodule: this.snippetContext?.submodule || "",
                                                 type: "link",
                                                 data: {
                                                     src: link.src,
                                                     name: link.name
                                                 },
                                                 timestamp: new Date().toISOString()
                                             }
                                             const newSnippet: IEditableSnippet = {
                                                 id: `temp-${uuid.v4()}`,
                                                 timestamp: new Date(),
                                                 modulekey: this.snippetContext?.modulekey || "A01",
                                                 deleted: false,
                                                 status: "new",
                                                 type: "link",
                                                 initial: snippetData,
                                                 current: snippetData
                                             }

                                             snippets.push(newSnippet);
                                             this.snippetContext = undefined;
                                         }

                                         this.snippetContext = undefined;
                                         this.setState({linkDialog: false, snippets});
                                     }}
                                     onCancel={() => {
                                         this.snippetContext = undefined;
                                         this.setState({linkDialog: false});
                                     }}
            />;
        }
    }

    render() {
        return (
            <DetailViewTemplate
                busy={this.state.busy}
                dirty={this.isChanged()}
                redirect={this.state.redirect}
                history={this.state.history}
                id={this.state.key}
                onNavigate={async (key: string) => {
                    await this.fetch(key);
                    window.history.pushState({}, "", `/courses/detail/${key}`);
                }}
                title={this.state.name || "Neuer Kurs"}
                hint={this.state.message}
                info={`${this.state.modules.length} Module`}
                link="/courses/list"
                content={this.getContent()}
                dialog={this.getDialog()}
                buttons={this.getButtons()}
            />
        );
    }
}

