import React from "react";
import {formatCurrency} from "../misc/currency";

interface IAmountProps extends React.HTMLAttributes<HTMLSpanElement> {
    value: number;
    singular: string;
    plural?: string;
}

export const Amount: React.FunctionComponent<IAmountProps> = (props) => {

    let { value, singular, plural, ...rest } = props;
    let word = value > 1 ? plural || singular : singular;

    return (
        <span {...rest}>
            {formatAmount(props.value)} {word}
        </span>
    );
}

export function formatAmount(val: number) {

    val = Math.round(val);
    val = Math.abs(val);

    const thousands = Math.floor(val / 1000);
    const rest = Math.floor(val - thousands * 1000);

    let result = "";
    if (thousands) result = `${thousands}.${rest.toString().padStart(3, "0")}`;
    else result = `${rest.toString()}`;

    return result;
}