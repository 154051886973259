import React from "react";

interface IDialogTemplateProps {
    buttons?: JSX.Element;
    content: JSX.Element;
    width?: string;
    title: string;
    onCancel: () => void;
}

export const DialogTemplate: React.FunctionComponent<IDialogTemplateProps> = (props) => {

    const style = props.width ? {width: props.width} : undefined;

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={() => props.onCancel()}></div>
            <div className="modal-content" style={style}>
                <div style={{background: "white", padding: "2rem", borderRadius: "5px"}}>
                    <div style={{fontSize: "larger"}}>
                        <b>{props.title}</b>
                    </div>
                    <div className="mt-5" style={{borderBottom: "2px solid rgb(245, 245, 245)"}}></div>
                    <div className="mt-5">
                        {props.content}
                    </div>
                    <div className="mt-5" style={{borderBottom: "2px solid rgb(245, 245, 245)"}}></div>
                    <div className="mt-5" style={{justifyContent: "flex-end"}}>
                        {props.buttons}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DialogTemplate;
