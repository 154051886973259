import React from "react";
import {formatCurrency} from "../misc/currency";

interface ICurrencyProps extends React.HTMLAttributes<HTMLSpanElement> {
    value: number;
    style?: React.CSSProperties;
}

export const Currency: React.FunctionComponent<ICurrencyProps> = (props) => {

    let { value, style, ...rest } = props;

    return (
        <span style={style} {...rest}>
            {formatCurrency((props.value))}
        </span>
    );
}