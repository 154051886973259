import React, { useEffect, useState } from 'react';

export interface ISpinnerProps {
    text: string;
    delay?: number;
}

export const Spinner: React.FunctionComponent<ISpinnerProps> = (props) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {

        const delay = props.delay ?? 250;

        const timer = setTimeout(() => {
            setVisible(true);
        }, delay);

        return () => clearTimeout(timer);

    }, [props.delay]);

    if (!visible) return null;

    return (
        <>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="hint" style={{marginTop: "1rem"}} >
                <span>{props.text}</span>
            </div>
        </>
    );
}