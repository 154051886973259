import React from 'react';

import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import {IUpload} from "../../misc/uploader";
import {Message} from "../../components/Message";
import DialogTemplate from "../../templates/DialogTemplate";

interface IUploadProgressProps {
    onClose?: (cancel: boolean) => void;
    uploads: IUpload[];
}

interface IUploadProgressState {
    countdown: number;
    cancelled: boolean;
}

export default class UploadProgress extends React.Component<IUploadProgressProps, IUploadProgressState> {

    public state: IUploadProgressState = {
        countdown: 5,
        cancelled: false
    }

    private ismounted = false;
    private timer: NodeJS.Timeout | undefined;

    private closeDialog(cancel: boolean) {

        if (cancel) this.setState({cancelled: true});
        if (this.props.onClose) this.props.onClose(cancel);
    }

    public componentDidMount() {
        this.ismounted = true;
    }

    public componentWillUnmount() {

        this.ismounted = false;

        if (this.timer) clearInterval(this.timer);
        this.timer = undefined;
    }

    private getContent() {

        let totalsize = 0;
        let progress = 0;

        let finished = true;
        let errors = false;

        this.props.uploads.forEach((u) => {
            totalsize += (u.status !== 'failed' ? u.size : 0);
            progress += (u.status !== 'failed' ? u.progress : 0);
            if (u.status !== 'done' && u.status !== 'failed') finished = false;
            if (u.status === 'failed') errors = true;
        });

        if (finished && !errors && !this.timer) {
            this.timer = setInterval(() => {
                if (this.ismounted) {
                    const val = this.state.countdown || 0;
                    if (val > 1) this.setState({countdown: this.state.countdown - 1});
                    else this.closeDialog(false);
                }
            }, 1000);
        }

        const formatBytes = (bytes: number, decimals: number = 1) => {
            if (bytes <= 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }

        const trimText = (text: string) => {
            if (text.length > 40) return text.substr(0, 20) + "..." + text.substring(text.length-20);
            else return text;
        }



        const rows = this.props.uploads
            .map((upload) => {

                let icon: JSX.Element | undefined;
                let text: JSX.Element | undefined;
                let color = 'black';

                switch (upload.status) {
                    case 'failed':
                        color = 'red';
                        text = <span className="translate">{upload.message || 'Unbekannt'}</span>;
                        icon = <FontAwesomeIcon icon={faExclamationTriangle} />;
                        break;

                    case 'pending':
                        icon = <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} />;
                        break;

                    case 'active':
                        icon = <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} />;
                        text = <progress className="progress is-success is-small" value={upload.progress} max={upload.size} />;
                        break;

                    case 'done':
                        icon = <FontAwesomeIcon icon={faCheckCircle} />;
                        text = <span>{formatBytes(upload.progress)}</span>;
                        color = "green";
                        break;
                }

                return (
                    <tr key={upload.uid} style={{color: color}}>
                        <td>
                            {icon}<span style={{marginLeft: "0.5rem"}}>{trimText(upload.name)}</span>
                        </td>
                        <td>
                            {text}
                        </td>
                    </tr>
                );
            });

        return (
            <table className="table is-narrow is-fullwidth mt-4">
                <thead>
                <tr>
                    <th><span className="translate">Name</span></th>
                    <th><span className="translate">Fortschritt</span></th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
    }

    private getProgress() {

        let totalsize = 0;
        let progress = 0;

        let finished = true;
        let errors = false;

        this.props.uploads.forEach((u) => {
            totalsize += (u.status !== 'failed' ? u.size : 0);
            progress += (u.status !== 'failed' ? u.progress : 0);
            if (u.status !== 'done' && u.status !== 'failed') finished = false;
            if (u.status === 'failed') errors = true;
        });

        return {
            finished: finished,
            totalsize: totalsize,
            progress: progress,
            errors: errors
        };
    }

    private getButtons() {

        const p = this.getProgress();

        let info: JSX.Element | undefined;

        if (this.state.cancelled && !p.finished) info = <Message category="busy" text="Upload wird abgebrochen" />;
        else if (!p.finished) info = <progress className="progress is-success is-small" value={p.progress} max={p.totalsize} />;
        else {
            if (p.errors) info = <Message category="error" text="Upload mit Fehler abgeschlossen" />;
            else info = <Message category="success" >
                <span className="translate">Fertig, das Fenster schliesst in</span>
                <span> {this.state.countdown} </span>
                <span className="translate">Sekunden</span>
            </Message>;
        }

        return (
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div>
                    {info}
                </div>
                <div className="buttons">
                    <button className="button is-text" onClick={() => this.closeDialog(!p.finished)}>
                        <span className="translate">{p.finished ? 'Schliessen' : 'Abbrechen'}</span>
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <DialogTemplate
                content={this.getContent()}
                buttons={this.getButtons()}
                title={"Upload"}
                onCancel={() => {}}
            />
        );
    }
}