import {IOrder} from "@institutsitya/sitya-common/types/model/order";

let memory: IOrder | undefined;

export function rememberOrder(order: IOrder) {
    memory = order;
}

export function getStatusText(status: string) {
    switch (status) {
        case "prepared":
            return "Vorbereitet";
        case "created":
            return "Neu";
        case "cancelled":
            return "Storniert";
        case "active":
            return "Bezahlt";
    }
    return "";
}

export function getStatusColor(status: string) {
    switch (status) {
        case "created":
        case "prepared":
            return "gray";

        case "active":
            return "#86bc25";

        case "cancelled":
            return "#ec6608";
    }
    return "";
}

export function getOrderName(order: IOrder | string): string | undefined {
    if (typeof order === 'string') {
        if (order === memory?._id) return getOrderName(memory);
        return undefined;
    } else {
        return order.name;
    }
}

export function getOrderDate(order: IOrder | string): Date | undefined {
    if (typeof order === 'string') {
        if (order === memory?._id) getOrderDate(memory);
        return undefined;
    } else {
        return new Date(order.orderDate);
    }
}
