import React from 'react';
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {formatCurrency} from "../misc/currency";
import {get, post} from "../misc/communication";
import failureController from "../controller/failure";
import {getCourses} from "../controller/courses";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {Amount} from "../components/Amount";
import {Label} from "../components/Label";
import {IUsage} from "@institutsitya/sitya-common/types/api/order";
import DialogTemplate from "../templates/DialogTemplate";

interface IOrderCancellationDialogState {
    busy: boolean;
    usage: IUsage[];
    usageFee: string[];
    courses: ICourse[];
}

interface IOrderCancellationDialogProps {
    order: IOrder;
    onCancel: () => void;
    onDone: () => void;
}

export class OrderCancellationDialog extends React.Component<IOrderCancellationDialogProps, IOrderCancellationDialogState> {

    private mounted = false;

    state: IOrderCancellationDialogState = {
        courses: [],
        usageFee: [],
        busy: false,
        usage: []
    }

    constructor(props: IOrderCancellationDialogProps) {
        super(props);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    componentDidMount() {
        // @ts-ignore
        document.addEventListener("keydown", this.onKeyPressed);
        this.mounted = true;

        this.fetch();
    }

    private async fetch() {
        try {

            this.setState({busy: true});

            const p1 = get(`/api/orders/${this.props.order._id}/usage`);
            const p2 = getCourses();

            const [usage, courses] = await Promise.all([p1, p2]);

            const fee: string[] = [];
            if (usage && usage.length) usage.forEach((u: any) => fee.push(u.course));

            if (this.mounted) this.setState({busy: false, usage: usage, usageFee: fee, courses: courses});

        } catch (error) {
            failureController.failure("OrderCancellationDialog.tsx/fetch", error);
            if (this.mounted) this.setState({busy: false});
        }
    }

    componentWillUnmount() {

        this.mounted = false;

        // @ts-ignore
        document.removeEventListener("keydown", this.onKeyPressed);
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (this.mounted && (e.key === 'Escape')) this.onCancel();
    }

    private onCancel() {
        if (this.state.busy) return;
        this.props.onCancel();
    }

    private async onAction() {

        this.setState({busy: true});

        try {

            const usageFee = this.state.usageFee.map((u) => {
                const record = this.state.usage.find((x) => x.course === u);
                const key = (u === "humanenergetiker-6") ? "humanenergetiker" : u;
                return (
                    {
                        course: key,
                        submodules: record?.submodules || 0,
                        size: record?.size || 0
                    }
                );
            });

            await post(
                `/api/orders/${this.props.order._id}/cancel`,
                usageFee.length ? {usageFee: usageFee} : undefined
            );

            this.props.onDone();
        } catch (error) {
            failureController.failure("OrderCancelDialog.tsx/onAction", error);
            this.setState({busy: false});
        }
    }

    private hasUsageFee(course: string) {

        const usageFee = this.state.usageFee;
        return usageFee.indexOf(course) >= 0;
    }

    private toggleUsageFee(course: string, enable: boolean) {

        const usageFee = this.state.usageFee;
        const idx = usageFee.indexOf(course);

        if (idx === -1 && enable) usageFee.push(course);
        else if (idx >= 0 && !enable) usageFee.splice(idx, 1);

        this.setState({usageFee: usageFee});
    }

    private getContent() {

        let hint = `Bitte bestätigen Sie die endgültige Stornierung der Bestellung ${this.props.order.name} im Wert von ${formatCurrency(this.props.order.totalValueGross)} brutto. Diese Aktion kann nicht rückgängig gemacht werden.`;

        const rows = this.props.order.courses.map((course) => {

            const key = (course.key === "humanenergetiker") ? "humanenergetiker-6" : course.key;
            const name = this.state.courses.find((c) => c.key === key)?.name || "Fernlehrgang";
            let record = this.state.usage.find((x) => x.course === key);

            let info: JSX.Element | undefined = undefined;
            let price: JSX.Element | undefined = undefined;

            if (record) {
                info = (
                    <>
                        <Amount value={record.submodules} singular="Modul" plural="Module"/>
                    </>
                );

                const fee = this.hasUsageFee(key);

                price = <div className="field has-addons">
                    <p className="control">
                        <button className={`button is-small ${fee ? "is-dark" : ""}`}
                                onClick={() => this.toggleUsageFee(key, true)}>
                            <span style={{width: "50px"}}>Ja</span>
                        </button>
                    </p>
                    <p className="control">
                        <button className={`button is-small ${!fee ? "is-dark" : ""}`}
                                onClick={() => this.toggleUsageFee(key, false)}>
                            <span style={{width: "50px"}}>Nein</span>
                        </button>
                    </p>
                </div>;
            } else {
                info = <span>kein Download</span>;
            }

            return (
                <tr key={course.key}>
                    <td style={{verticalAlign: "middle"}} width="50%">
                        <span>{name}</span>
                    </td>
                    <td style={{verticalAlign: "middle"}} width="25%">
                        {info}
                    </td>
                    <td style={{verticalAlign: "middle"}} width="25%">
                        {price}
                    </td>
                </tr>
            );
        });

        return (

            <div>
                <div className="mt-5">
                    <span>{hint}</span>
                </div>
                <div className="mt-5">
                    <table className="table is-fullwidth is-bordered">
                        <thead>
                        <td width="50%">
                            <Label text="Kurs"/>
                        </td>
                        <td width="25%">
                            <Label text="Nutzung"/>
                        </td>
                        <td width="25%">
                            <Label text="Entgelt"/>
                        </td>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    private getButtons() {
        return (
            <div className="buttons" style={{justifyContent: "flex-end"}}>
                <button disabled={this.state.busy} className="button is-text" style={{width: "120px"}}
                        onClick={() => this.onCancel()}>Abbrechen
                </button>
                <button disabled={this.state.busy}
                        className={`button is-purple ${this.state.busy ? "is-loading" : ""}`}
                        style={{width: "120px"}} onClick={() => this.onAction()}>Stornieren
                </button>
            </div>
        );
    }

    render() {
        return (
            <DialogTemplate
                content={this.getContent()}
                buttons={this.getButtons()}
                title={"Storno Bestellung " +this.props.order.name}
                onCancel={() => this.onCancel()}
            />
        );
    }
}