import React from 'react';
import {get, post} from "../../misc/communication";
import failureController from "../../controller/failure";
import ListViewTemplate from "../../templates/ListViewTemplate";
import {Amount} from "../../components/Amount";
import {IHubDbCacheStatusInformation, IHubDbCacheStatusResponse} from "@institutsitya/sitya-common/types/api/hubdb";
import {CalculatePricesDialog} from "../../dialogs/CalculatePricesDialog";
import {getAuthController} from "../../controller/auth";

interface IHubDbStatusViewProps {
}

interface IHubDbStatusViewState {
    counter: number;
    response?: IHubDbCacheStatusResponse;
    prices?: boolean;
}

export class HubDbStatusView extends React.Component<IHubDbStatusViewProps, IHubDbStatusViewState> {

    private mounted = false;
    private timer: undefined | NodeJS.Timer;

    state: IHubDbStatusViewState = {
        counter: 0
    };

    componentDidMount() {
        this.mounted = true;
        this.fetch();

        this.timer = setInterval(
            () => {
              const counter = this.state.counter + 1;
              if (counter % 60 === 0) this.fetch();
              else this.setState({counter})
            },
            1000
        );
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.timer) clearInterval(this.timer);
    }

    private async fetch() {
        try {
            const response = await get(`/api/hubdb/cache/status`) as any;
            this.setState({response, counter: 0});

        } catch (error) {
            failureController.failure("HubDbStatusView.tsx/fetch", error);
        }
    }

    private getHeader() {
        return (
            <tr>
                <th>Tabelle</th>
                <th>Gültigkeit</th>
            </tr>
        );
    }

    private getButtons() {

        return (
            <div className="buttons">
                <button className="button mr-2" style={{width: "140px"}} onClick={async () => {

                    try {
                        await post('/api/hubdb/cache/invalidate');
                        await this.fetch();
                    } catch (error) {
                        failureController.failure("HubDbStatusView.tsx/invalidate", error);
                    }
                }}>
                    Zurücksetzen
                </button>
                <button className="button is-purple" style={{width: "140px"}} onClick={async () => {

                    try {
                        this.setState({prices: true});

                    } catch (error) {
                        failureController.failure("HubDbStatusView.tsx/prices", error);
                    }
                }}>
                    Berechnen
                </button>
            </div>
        );
    }

    private formatTableName(table: string) {
        switch (table) {
            case "course":
                return "Kurs";

            case "coupon":
                return "Rabattcodes";

            case "discount_global":
                return "Rabatte";
        }

        return "";
    }

    private formatInformation(table: IHubDbCacheStatusInformation) {

        if (table.ttl <= -2) return "-";
        if (table.ttl === -1) return "Permanent";

        const remaining = table.ttl as number - this.state.counter;
        if (remaining <= 0) return "-";

        const style = {display: "block", marginBottom: "2px"};

        let amount;
        if (remaining > 3600) amount = <Amount {...style} value={Math.floor(remaining / 3600)} singular="Stunde" plural="Stunden" />;
        else if (remaining > 60) amount = <Amount {...style}  value={Math.floor(remaining / 60)} singular="Minute" plural="Minuten" />;
        else amount =<Amount {...style} value={remaining} singular="Sekunde" plural="Sekunden" />;

        const total = table.info?.ttl as number;
        let bar: JSX.Element | undefined;

        if (total > 0) {
            bar = (
                <progress
                    style={{width: "200px", marginBottom: "0"}}
                    className="mr-4 progress is-purple"
                    value={remaining}
                    max={total}>
                </progress>
            );
        }

        return (
            <>
              {bar}
              {amount}
            </>
        );
    }

    private getRows() {

        if (!this.state.response) return undefined;

        return this.state.response.tables.map((table) => {

            return (
                <tr key={table.name}>
                    <td>
                        {this.formatTableName(table.name)}
                    </td>
                    <td>
                        <div style={{display: "flex", alignItems: "center", width: "400px"}}>
                            {this.formatInformation(table)}
                        </div>
                    </td>
                    <td>
                    </td>
                </tr>
            );
        });
    }


    render() {

        const dialog = this.state.prices ? <CalculatePricesDialog onClose={() => this.setState({prices: false})} /> : undefined;

        return (<ListViewTemplate
                busy={false}
                dialog={dialog}
                buttons={this.getButtons()}
                header={this.getHeader()}
                rows={this.getRows()}/>
        );
    }
}
