import {faExternalLinkAlt, faPencil, faTrashAlt} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {formatDistance} from 'date-fns'

import './ModuleView.scss';
import {de} from 'date-fns/locale';
import {IEditableFile, IEditableSnippet} from "../../controller/courses";

interface IModuleViewProps {

    disabled?: boolean;
    modulekey: string;
    name: string;

    onDeleteModule?: () => void;

    onDeleteFile?: (f: IEditableFile) => void;
    onChangeFile?: (f: IEditableFile, field: string, value: string) => void;

    onDeleteSnippet?: (f: IEditableSnippet) => void;
    onChangeSnippet?: (link: IEditableSnippet, field?: string, value?: string) => void;

    files: IEditableFile[];
    snippets: IEditableSnippet[];
}

export const ModuleView: React.FunctionComponent<IModuleViewProps> = (props) => {

    const deletebutton = props.onDeleteModule ? <button className="button is-text" onClick={props.onDeleteModule}
                                                        disabled={props.disabled || !props.onDeleteModule}>
        {props.name} löschen
    </button> : undefined;

    const getFileSubModulesControl = (file: IEditableFile) => {

        let items: { key: string, name: string }[];

        if (props.modulekey.startsWith('A')) {
            const number = parseInt(props.modulekey.substr(1));
            items = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'].map((submodule) => {
                return {key: `${number}${submodule}`, name: `Modul ${number}${submodule}`}
            });

            const rows = items.map((i) => <option key={"submodule" + i.key} value={i.key}>{i.name}</option>);

            return (
                <div className="select">
                    <select value={file.current.submodule} onChange={(e) => {
                        if (props.onChangeFile) props.onChangeFile(file, 'submodule', e.target.value);
                    }}>
                        {rows}
                    </select>
                </div>);
        }

        return "-";
    }

    const getSnippetSubModulesControl = (snippet: IEditableSnippet) => {

        let items: { key: string, name: string }[];

        if (props.modulekey.startsWith('A')) {
            const number = parseInt(props.modulekey.substr(1));
            items = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l'].map((submodule) => {
                return {key: `${number}${submodule}`, name: `Modul ${number}${submodule}`}
            });

            const rows = items.map((i) => <option key={"submodule" + i.key} value={i.key}>{i.name}</option>);

            return (
                <div className="select">
                    <select value={snippet.current.submodule} onChange={(e) => {
                        if (props.onChangeSnippet) props.onChangeSnippet(snippet, 'submodule', e.target.value);
                    }}>
                        {rows}
                    </select>
                </div>);
        }

        return "-";
    }

    const getTypeControl = (file: IEditableFile) => {

        let items: { key: string, name: string }[] = [];

        if (props.modulekey.startsWith('A')) {
            items.push({key: '10', name: 'Lehrunterlage'});
            items.push({key: '20', name: 'Beiblatt'});
            items.push({key: '30', name: 'Wochentest'});
        } else if (props.modulekey.startsWith('B')) {
            items.push({key: '20', name: 'Beiblatt'});
            items.push({key: '30', name: 'Semesterprüfung'});
        } else if (props.modulekey.startsWith('C')) {
            items.push({key: '20', name: 'Beiblatt'});
            items.push({key: '30', name: 'Abschlussprüfung'});
        }

        const rows = items.map((i) => <option key={"type" + i.key} value={i.key}>{i.name}</option>);

        return (
            <div className="select">

                <select value={file.current.type} onChange={(e) => {
                    if (props.onChangeFile) props.onChangeFile(file, 'type', e.target.value);
                }}>
                    {rows}
                </select>
            </div>);
    }

    const formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const now = new Date();

    const rowsFiles = props.files.map((f) => {
        let ts = new Date(f.current.timestamp);
        return <tr key={f.name} title={f.message}
                   style={f.message ? ({background: "rgba(255, 0, 0, 0.1)"}) : undefined}>
            <td>
                <div style={{height: "100%"}}><span>{f.name}</span></div>
            </td>
            <td>
                {getFileSubModulesControl(f)}
            </td>
            <td>
                {getTypeControl(f)}
            </td>
            <td>{formatDistance(ts, now, {locale: de, addSuffix: true})}</td>
            <td>{formatBytes(f.current.size, 1)}</td>
            <td style={{textAlign: "right"}}>
                <button className="button is-text" disabled={props.disabled}
                        onClick={() => {
                            if (props.onDeleteFile) props.onDeleteFile(f);
                        }}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </button>
            </td>
        </tr>
    });

    const rowsSnippets = props.snippets
        .filter((x) => x.type === "link")
        .map((snippet) => {

            const ts = new Date(snippet.timestamp);

            let src = snippet.current.data.src;
            // if (src.length > 64) src = src.substring(0, 64) + "...";

            return <tr key={snippet.current.data.name} title={snippet.message}
                       style={snippet.message ? ({background: "rgba(255, 0, 0, 0.1)"}) : undefined}>
                <td>
                    <div style={{height: "100%"}}><span className="snippet-name">{snippet.current.data.name}</span></div>
                </td>
                <td>
                    <div style={{height: "100%"}}><span className="snippet-src">{src}</span></div>
                </td>
                <td>
                    {getSnippetSubModulesControl(snippet)}
                </td>
                <td>{formatDistance(ts, now, {locale: de, addSuffix: true})}</td>
                <td style={{textAlign: "right"}}>

                    <button className="button is-text" title="Link öffnen"
                            onClick={() => {
                                window.open(snippet.current.data.src, "_blank");
                            }}>
                        <FontAwesomeIcon icon={faExternalLinkAlt}/>
                    </button>

                    <button className="button is-text" disabled={props.disabled} title="Link bearbeiten"
                            onClick={() => {
                                if (props.onChangeSnippet) props.onChangeSnippet(snippet);
                            }}>
                        <FontAwesomeIcon icon={faPencil}/>
                    </button>

                    <button className="button is-text" disabled={props.disabled} title="Link löschen"
                            onClick={() => {
                                if (props.onDeleteSnippet) props.onDeleteSnippet(snippet);
                            }}>
                        <FontAwesomeIcon icon={faTrashAlt}/>
                    </button>
                </td>
            </tr>
        });

    return (
        <div className="module-view">
            <div className="is-flex is-justify-content-space-between is-fullwidth">
                <div>
                </div>
                {deletebutton}
            </div>

            {rowsFiles.length ?
                <table className="table is-narrow is-fullwidth mt-4">
                    <thead>
                    <tr>
                        <th><span className="translate">Name</span></th>
                        <th><span className="translate" style={{marginLeft: "1rem"}}>Untermodul</span></th>
                        <th><span className="translate" style={{marginLeft: "1rem"}}>Type</span></th>
                        <th><span className="translate">Datum</span></th>
                        <th><span className="translate">Größe</span></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {rowsFiles}
                    </tbody>
                </table> : undefined}

            {rowsSnippets.length ?
                <table className="table is-narrow is-fullwidth mt-4">
                    <thead>
                    <tr>
                        <th><span className="translate">Name</span></th>
                        <th><span className="translate">Ziel</span></th>
                        <th><span className="translate" style={{marginLeft: "1rem"}}>Untermodul</span></th>
                        <th><span className="translate">Datum</span></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {rowsSnippets}
                    </tbody>
                </table> : undefined}
        </div>
    );
}
