import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import getErrorText from "@institutsitya/sitya-common/misc/errors";

type MessagePropsType = {
    text?: string,
    category: 'info' | 'success' | 'busy' | 'error' 
}

export const Message: React.FunctionComponent<MessagePropsType> = (props) => {

    const [visible, setVisible] = useState(() => props.category !== "busy");
    useEffect(() => {
        if (props.category === "busy") {
            const timer = setTimeout(() => setVisible(true), 200);
            return () => clearTimeout(timer);
        } else {
            setVisible(true);
        }
    }, [props.category]);
    
    let icon: JSX.Element | null = null;
    let color = 'black';
    let text = props.text;

    if (!visible) return null;

    switch (props.category) {

        case 'success':
            color = 'green';
            icon = <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: "0.5rem" }} />;;
            break;

        case 'info':
            color = 'black';
            icon = null;
            break;

        case 'error':
            color = 'red';
            icon = <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: "0.5rem" }} />;
            text = getErrorText(props.text);
            break;

        case 'busy':
            color = 'black';
            icon = <FontAwesomeIcon  className="fa-spin" icon={faCircleNotch} style={{ marginRight: "0.5rem" }} />;
            break;
    }
    
    return <div id="msgtext" style={{color: color}}>{icon}{props.children ? props.children :<span className="translate">{text}</span>}</div>
}
