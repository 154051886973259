import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/pro-regular-svg-icons";
import failureController, {FailureOccuredArgs, IFailureObserver} from './../controller/failure';

interface IFailureDialogState {
    visible: boolean;
    source: string;
    error: any;
}

interface IFailureDialogProps {
}

export class FailureDialog extends React.Component<IFailureDialogProps, IFailureDialogState> implements IFailureObserver {

    state: IFailureDialogState = {
        source: "",
        visible: false,
        error: {}
    }

    componentDidMount() {
        failureController.addHandler(this);
    }

    componentWillUnmount() {
        failureController.removeHandler(this);
    }

    public onFailureOccured(args: FailureOccuredArgs) {
        this.setState({visible: true, source: args.source, error: args.error});
    }

    render() {

        return (
            <div className={`modal ${this.state.visible ? "is-active" : ""}`}>
                <div className="modal-background" onClick={() => this.setState({visible: false})}></div>
                <div className="modal-content">
                    <div className="card p-6">
                        <div className="is-size-2" style={{cursor: "pointer", display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon className="mr-2" icon={faExclamationCircle}/>
                            <span>Uups</span>
                        </div>
                        <div className="mt-2">
                            <span>{`Das hätte nicht passieren sollen. Bei der Verarbeitung von ${this.state.source} ist ein nicht erwarteter Fehler aufgetreten.`}</span>
                        </div>
                        <div className="mt-2">
                            <span>Weitere Informationen finden sich aktuell in der Konsole.</span>
                        </div>
                    </div>
                </div>
                <button onClick={() => this.setState({visible: false})} className="modal-close is-large"
                        aria-label="close"></button>
            </div>
        );
    }
}