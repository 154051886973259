import React from 'react';
import {useParams} from 'react-router-dom';
import {del, get} from "../../misc/communication";
import {getOrderDate, getOrderName, getStatusColor, getStatusText} from "../../controller/orders";
import {IOrder} from "@institutsitya/sitya-common/types/model/order";
import {getCountryName} from "../../misc/country";
import {getCourses} from "../../controller/courses";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import failureController from './../../controller/failure';
import {IReferences} from "@institutsitya/sitya-common/types/api/references";
import {formatDate, formatDateTime} from "../../misc/date";
import {OrderCancellationDialog} from "../../dialogs/OrderCancellationDialog";
import {Currency} from "../../components/Currency";
import {OrderDetailCourses} from "./OrderDetailCourses";
import {OrderDetailInvoices} from "./OrderDetailInvoices";
import {IInvoice} from "@institutsitya/sitya-common/types/model/invoice";
import {OrderRefundDialog} from "../../dialogs/OrderRefundDialog";
import {Label} from "../../components/Label";
import {Status} from "../../components/Status";
import DetailViewTemplate from "../../templates/DetailViewTemplate";
import {OrderPaymentDialog} from "../../dialogs/OrderPaymentDialog";
import {OrderDetailPayment} from "./OrderDetailPayment";
import {IPayment} from "@institutsitya/sitya-common/types/model/payment";
import {endOfDay} from "date-fns";
import {hasFeature} from "../../misc/features";

interface IOrderDetailViewProps {
    uid: string;
}

interface IOrderDetailViewState {
    uid: string;
    busy: boolean;
    payment?: boolean;
    redirect?: string;
    cancel?: boolean;
    refund?: boolean;
    order?: IOrder;
    invoices?: IInvoice[];
    payments?: IPayment[];
    courses?: ICourse[];
    selectedTab: "invoices" | "courses" | "payment";
    references?: IReferences;
}

export const OrderDetailViewWrapper: React.FunctionComponent = (props) => {
    const {uid} = useParams();
    if (uid) return <OrderDetailView uid={uid}/>;
    return <div/>;
}

export class OrderDetailView extends React.Component<IOrderDetailViewProps, IOrderDetailViewState> {

    private mounted = false;

    state: IOrderDetailViewState = {
        uid: this.props.uid,
        busy: true,
        selectedTab: "courses"
    };

    componentDidMount() {

        this.mounted = true;
        this.fetch(this.props.uid);
    }

    componentDidUpdate(prevProps: Readonly<IOrderDetailViewProps>, prevState: Readonly<IOrderDetailViewState>, snapshot?: any) {
        if (prevProps.uid !== this.props.uid) {
            this.fetch(this.props.uid);
        }
    }

    private async fetch(uid: string) {
        try {
            const p1 = get(`/api/orders/${uid}`);
            const p2 = getCourses();
            const p3 = get(`/api/orders/${uid}/references`);
            const p4 = get(`/api/orders/${uid}/invoices`);
            const p5 = get(`/api/payments?order=${uid}`);

            const [order, courses, references, invoices, payments] = await Promise.all([p1, p2, p3, p4, p5]);

            this.setState({
                uid: uid,
                busy: false,
                order: order,
                courses: courses,
                references: references,
                invoices: invoices,
                payments: payments
            });

        } catch (error) {
            failureController.failure("OrderDetailView.tsx/fetch", error);
            this.setState({busy: false});
        }
    }

    private cancel() {
        this.setState({cancel: true});
    }

    private payment() {
        this.setState({payment: true});
    }

    private refund() {
        this.setState({refund: true});
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private getMainSection() {

        if (!this.state.order) return undefined;
        if (!this.state.courses) return undefined;

        let coupon: JSX.Element | null = null;
        let invoiceAddress: JSX.Element | null = null;
        let additionalData: JSX.Element | null = null;

        if (this.state.order.couponInternal || this.state.order.coupon) {
            coupon = (
                <div>
                    <Label className="mt-2" text="Gutschein"/>
                    <div><span>{this.state.order.couponInternal || this.state.order.coupon}</span></div>
                </div>
            );
        }

        if (this.state.order.invoiceAddress) {

            const rows: JSX.Element[] = [];

            if (this.state.order.invoiceAddress.category === "company") {
                if (this.state.order.invoiceAddress.company) rows.push(<div
                    key="r1">{this.state.order.invoiceAddress.company}</div>);

                // Support both old and new model
                if (this.state.order.invoiceAddress.vatId) rows.push(<div
                    key="r2">{this.state.order.invoiceAddress.vatId}</div>);
                else if (this.state.order.vatId) rows.push(<div key="r2">{this.state.order.vatId}</div>);

                if (this.state.order.invoiceAddress.contact) rows.push(<div
                    key="r3">{this.state.order.invoiceAddress.contact}</div>);
            }

            if (this.state.order.invoiceAddress.category === "private") {
                if (this.state.order.invoiceAddress.name) rows.push(<div
                    key="r7">{this.state.order.invoiceAddress.name}</div>);
            }

            if (this.state.order.invoiceAddress.street) rows.push(<div
                key="r5">{this.state.order.invoiceAddress.street}</div>);

            if (this.state.order.invoiceAddress.postalCode && this.state.order.invoiceAddress.city) {
                rows.push(<div
                    key="r6">{this.state.order.invoiceAddress.postalCode} {this.state.order.invoiceAddress.city}</div>);
            }

            invoiceAddress = (
                <div className="mr-6 pr-6" style={{borderRight: "2px solid #f5f5f5"}}>
                    <Label text="Rechnungsanschrift"/>
                    {rows}
                </div>
            );
        }

        let paidStyle: React.CSSProperties = {};
        const orderValue = Math.round((this.state.order.totalValueGross + this.state.order.totalRefundGross) * 100) / 100;
        if (orderValue > 0) {
            const paidValue = Math.round(this.state.order.totalPaymentGross * 100) / 100;
            if (paidValue === orderValue) paidStyle = {fontWeight: "bold", color: "#86bc25"};
            if (paidValue < orderValue) {
                const today = new Date();
                const target = new Date(this.state.order.targetDate);
                if (today > endOfDay(target)) paidStyle = {fontWeight: "bold", color: "#ec6608"};
            }
        }

        let host = this.state.order.host;
        const utmFields: JSX.Element[] = [];

        let utm = this.state.order.data && this.state.order.data["utm"];
        if (utm) {

            const names = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "adgroupname", "gad"];
            if (names.some((x) => utm[x] !== undefined)) {

                const style: any = {};
                style["font-size"] = "small";
                style["color"] = "gray";

                utmFields.push(<Label key="heading" className="mt-2" text="UTM"/>);
                names.forEach((x) => {
                    const value = utm[x] as (string | undefined);
                    if (value && value.length) {
                        utmFields.push(<div key={x}><span>{value}</span><span style={style}
                                                                              className="ml-1">[{x}]</span></div>);
                    }
                });
            }
        }

        return (
            <div>
                <Label text="Status"/>
                <Status color={getStatusColor(this.state.order.status)} text={getStatusText(this.state.order.status)}/>

                <div className="mt-4" style={{display: "flex"}}>
                    <div className="pr-6 mr-6" style={{borderRight: "2px solid #f5f5f5"}}>
                        <Label text="Adresse"/>
                        <div>{this.state.order.fullName}</div>
                        <div>{this.state.order.street}</div>
                        <div>{this.state.order.postalCode} {this.state.order?.city}</div>
                        <div>{getCountryName(this.state.order.country)}</div>
                    </div>
                    <div className="pr-6 mr-6" style={{borderRight: "2px solid #f5f5f5"}}>
                        <Label text="Geburtsdatum"/>
                        <div>{this.state.order.birthDay ? formatDate(this.state.order.birthDay) : "-"}</div>

                        <Label className="mt-2" text="Kontakt"/>
                        <div><span>{this.state.order.phone}</span></div>
                        <div><span>{this.state.order.mail}</span></div>
                    </div>
                    {invoiceAddress}
                    <div className="pr-6 mr-6" style={{borderRight: "2px solid #f5f5f5"}}>
                        <Label text="Wert"/>
                        <div>
                            <Currency value={orderValue}/>
                        </div>
                        <div style={{fontSize: "smaller"}}>fällig {formatDate(this.state.order.targetDate)}</div>
                        {coupon}
                        {hasFeature("payment") && <>
                            <Label className="mt-2" text="Bezahlt"/>
                            <div style={paidStyle}>
                                <Currency value={this.state.order.totalPaymentGross}/>
                            </div>
                        </>}
                    </div>
                    <div className="pr-6 mr-6">
                        <Label text="Host"/>
                        <div><span>{host || "-"}</span></div>
                        {utmFields}
                    </div>
                </div>
            </div>
        );
    }

    private getTabs() {
        return (
            <div className="mt-2 tabs">
                <ul>
                    <li className={this.state.selectedTab === "courses" ? "is-active" : ""}><a
                        onClick={(e) => this.setState({selectedTab: "courses"})}>Kurse
                    </a></li>
                    <li className={this.state.selectedTab === "invoices" ? "is-active" : ""}><a
                        onClick={(e) => this.setState({selectedTab: "invoices"})}>
                        Rechnungen
                    </a></li>
                    {hasFeature("payment") && <li className={this.state.selectedTab === "payment" ? "is-active" : ""}><a
                        onClick={(e) => this.setState({selectedTab: "payment"})}>
                        Zahlungen
                    </a></li>}
                </ul>
            </div>
        );
    }

    private getSelectedTab() {

        if (!this.state.order) return undefined;
        if (!this.state.courses) return undefined;

        if (this.state.selectedTab === "courses") return <OrderDetailCourses order={this.state.order}
                                                                             courses={this.state.courses}/>;

        if (this.state.selectedTab === "invoices") return <OrderDetailInvoices invoices={this.state.invoices || []}/>;

        if (this.state.selectedTab === "payment") return <OrderDetailPayment payments={this.state.payments || []}
                                                                             onDelete={async (id) => {

                                                                                 await del(`/api/payments/${id}`);
                                                                                 this.fetch(this.state.uid);
                                                                             }}/>;

        return undefined;
    }

    private getDialog() {

        if (!this.state.order) return undefined;

        if (this.state.cancel) {
            return <OrderCancellationDialog order={this.state.order}
                                            onDone={() => {
                                                this.setState({cancel: false});
                                                this.fetch(this.state.uid);
                                            }}
                                            onCancel={() => this.setState({cancel: false})}
            />;
        }
        if (this.state.payment) {
            return <OrderPaymentDialog order={this.state.order}
                                       onDone={() => {
                                           this.setState({payment: false});
                                           this.fetch(this.state.uid);
                                       }}
                                       onCancel={() => this.setState({payment: false})}
            />;
        }

        if (this.state.refund) {
            return <OrderRefundDialog order={this.state.order}
                                      onDone={() => {
                                          this.setState({refund: false});
                                          this.fetch(this.state.uid);
                                      }}
                                      onCancel={() => this.setState({refund: false})}
            />;
        }

        return undefined;
    }

    private getButtons() {

        const remainingValue = (this.state.order?.totalValueGross || 0) + (this.state.order?.totalRefundGross || 0);

        return (
            <div className="buttons">
                <button disabled={remainingValue <= 0} onClick={() => this.refund()}
                        className="button" style={{width: "150px"}}>Gutschrift
                </button>
                <button disabled={this.state.order?.status === "cancelled"} onClick={() => this.cancel()}
                        className="button" style={{width: "150px"}}>Stornieren
                </button>
                <button onClick={() => this.payment()}
                        className="button" style={{width: "150px"}}>Zahlung
                </button>
            </div>
        );
    }

    private getContent() {
        return (
            <>
                {this.getMainSection()}
                {this.getTabs()}
                {this.getSelectedTab()}
            </>
        );
    }

    render() {

        const name = getOrderName(this.state.order || this.props.uid) || "";
        const date = getOrderDate(this.state.order || this.props.uid);
        const info = date ? formatDateTime(date) : undefined;

        return <DetailViewTemplate
            busy={this.state.busy}
            redirect={this.state.redirect}
            content={this.getContent()}
            id={this.state.uid}
            onNavigate={async (uid: string) => {
                await this.fetch(uid);
                window.history.pushState({}, "", `/orders/detail/${uid}`);
            }}
            history={this.state.order?.history}
            references={this.state.references}
            title={name}
            info={info}
            buttons={this.getButtons()}
            dialog={this.getDialog()}
            link="/orders/list"
        />;
    }
}