export interface INavigationController {
}

type SettingsType = {
    ids: string[]
}

class NavigationController implements INavigationController {

    private settings: SettingsType = {
        ids: []
    };

    public reset() {
        this.settings.ids = [];
        return this;
    }

    public records(ids: string[]) {
        this.settings.ids = ids;
    }

    public has(id: string) {
        const idx = this.getIndex(id);
        return (idx >= 0);
    }

    public hasNext(id: string) {
        const idx = this.getIndex(id);
        return ((idx < this.settings.ids.length - 1) && (idx >= 0));
    }

    public hasPrevious(id: string) {
        const idx = this.getIndex(id);
        return (idx > 0);
    }

    public getNext(id: string) {

        // Check if record is available
        let idx = this.settings.ids.findIndex((x) => x === id);
        if (idx < 0) return undefined;

        // Check if next record is available within scope
        idx++;
        if ((idx >= 0) && (idx < this.settings.ids.length)) return this.settings.ids[idx];

        return undefined;
    }


    public getPrevious(id: string) {
        // Check if record is available
        let idx = this.settings.ids.findIndex((x) => x === id);
        if (idx < 0) return undefined;

        // Check if next record is available within scope
        idx--;
        if ((idx >= 0) && (idx < this.settings.ids.length)) return this.settings.ids[idx];

        return undefined;
    }

    private getIndex(id: string) {
        const idx = this.settings.ids.findIndex((x) => x === id);
        if (idx < 0) return -1;
        return idx;
    }
}

const controller = new NavigationController();
export default controller;