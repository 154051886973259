"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isStrongPassword = exports.isValidMongoId = exports.isValidEMail = void 0;
function isValidEMail(mail) {
    if (!mail)
        return false;
    if (mail.length < 6)
        return false;
    if ((mail.indexOf('@') < 1))
        return false;
    if ((mail.indexOf('.') < 1))
        return false;
    return true;
}
exports.isValidEMail = isValidEMail;
function isValidMongoId(id) {
    if (!id || id.length !== 24)
        return false;
    for (let i = 0; i < id.length; i++) {
        const c = id.charCodeAt(i);
        if (c < 48)
            return false;
        if (c > 102)
            return false;
        if ((c > 57) && (c < 97))
            return false;
    }
    return true;
}
exports.isValidMongoId = isValidMongoId;
function isStrongPassword(password) {
    if (!password)
        return false;
    password = password.trim();
    if (password.length < 8)
        return false;
    let numeric = 0;
    let uppercase = 0;
    let lowercase = 0;
    let symbol = 0;
    for (let i = 0; i < password.length; i++) {
        const code = password.charCodeAt(i);
        if (code < 32)
            return false;
        if ((code >= 32) && (code <= 47))
            symbol++;
        if ((code >= 58) && (code <= 64))
            symbol++;
        if ((code >= 91) && (code <= 96))
            symbol++;
        if ((code >= 123) && (code <= 126))
            symbol++;
        if (code >= 127)
            symbol++;
        if ((code >= 48) && (code <= 57))
            numeric++;
        if ((code >= 65) && (code <= 90))
            uppercase++;
        if ((code >= 97) && (code <= 122))
            lowercase++;
    }
    if ((symbol + numeric) === 0)
        return false;
    if (uppercase === 0)
        return false;
    if (lowercase === 0)
        return false;
    return true;
}
exports.isStrongPassword = isStrongPassword;
