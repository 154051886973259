"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileType = void 0;
var FileType;
(function (FileType) {
    FileType["Regular"] = "10";
    FileType["Attachment"] = "20";
    FileType["Test"] = "30";
    FileType["External"] = "40";
})(FileType = exports.FileType || (exports.FileType = {}));
