import React from 'react';

export interface IRecordHeaderProps {
    title: string;
    info?: string;
}

export const RecordHeader: React.FunctionComponent<IRecordHeaderProps> = (props) => {

    return (
        <div>
            <div><span style={{fontSize: "2rem", fontWeight: "bolder", color: "#444"}}>{props.title}</span></div>
            <div><span>{props.info || "\u00A0"}</span></div>
        </div>
    );
}
