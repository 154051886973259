export type FailureOccuredArgs = {
    source: string;
    error: any;
}

export interface IFailureObserver {
    onFailureOccured?: (args: FailureOccuredArgs) => void;
}

export interface IFailureController {

    // Adds an observer. Do not forget to remove the observer when not needed any longer.
    addHandler: (observer: IFailureObserver) => void;

    // Removes an observer, which has been previously registered by addHandler(...).
    removeHandler: (observer: IFailureObserver) => void;

    failure: (source: string, error: any) => void;
}

class FailureController implements IFailureController {

    private observers: IFailureObserver[] = [];

    public addHandler(observer: IFailureObserver) {
        this.observers.push(observer);
    }

    public removeHandler(observer: IFailureObserver) {
        const index = this.observers.findIndex((x) => x === observer);
        if (index >= 0) this.observers.splice(index, 1);
    }

    private sendOnFailureOccured(args: FailureOccuredArgs) {
        this.observers
            .filter((o) => o.onFailureOccured !== undefined)
            .forEach((o) => o.onFailureOccured!(args));
    }

    public failure(source: string, error: any) {
        console.error(`Failure within ${source}`);
        console.error(error);
        this.sendOnFailureOccured({source: source, error: error});
    }
}

// Provides functions and events related to error handling. Use addHandler(...) to add an observer.
const controller: IFailureController = new FailureController();
export default controller;