import React from 'react';
import {Link} from "react-router-dom";
import {
    IContactReference,
    IReferences,
    IInvoiceReference,
    IOrderReference, IUserReference
} from "@institutsitya/sitya-common/types/api/references";
import {Label} from "./Label";

export interface IReferencesProps {
    references?: IReferences;
}

export const ReferencesContainer: React.FunctionComponent<IReferencesProps> = (props) => {

    let sum = props.references?.contacts?.length ?? 0;
    sum += props.references?.orders?.length ?? 0;
    sum += props.references?.invoices?.length ?? 0;
    sum += props.references?.users?.length ?? 0;

    if (!sum) return <div className="mt-4" />;

    return (
        <div className="mt-4 box">
            <div className="pb-2" style={{borderBottom: "2px solid #f5f5f5"}}>
                <span>BEZIEHUNGEN</span>
            </div>
            <div className="mt-2">
                <References references={props.references}/>
            </div>
        </div>
    );
}

export const References: React.FunctionComponent<IReferencesProps> = (props) => {

    if (!props.references) return <div/>;

    const sections: JSX.Element[] = [];

    let first = true;

    if (props.references.invoices && props.references.invoices.length) {
        sections.push(
            <div key="section-invoices">
                <Label text="Rechnungen"/>
                {props.references.invoices.map((r) => <InvoiceReference key={r.id} invoiceRef={r}/>)}
            </div>
        );
        first = false;
    }

    if (props.references.orders && props.references.orders.length) {
        sections.push(
            <div key="section-orders" className={first ? "" : "mt-2"}>
                <Label text="Bestellungen"/>
                {props.references.orders.map((r) => <OrderReference key={r.id} orderRef={r}/>)}
            </div>
        );
        first = false;
    }

    if (props.references.contacts && props.references.contacts.length) {
        sections.push(
            <div key="section-contacts" className={first ? "" : "mt-2"}>
                <Label text="Kontakte"/>
                {props.references.contacts.map((r) => <ContactReference key={r.id} contactRef={r}/>)}
            </div>
        );
    }

    if (props.references.users && props.references.users.length) {
        sections.push(
            <div key="section-users" className={first ? "" : "mt-2"}>
                <Label text="Benutzer"/>
                {props.references.users.map((r) => <UserReference key={r.id} userRef={r}/>)}
            </div>
        );
    }

    return (
        <>
            {sections}
        </>
    );
}

const InvoiceReference: React.FunctionComponent<{ invoiceRef: IInvoiceReference }> = (props) => {

    let postfix = ""
    if (props.invoiceRef.type === "cancellation") postfix = " (Storno)";
    if (props.invoiceRef.type === "refund") postfix = " (Gutschrift)";

    return (
        <div>
            <Link to={`/invoices/detail/${props.invoiceRef.id}`}>
                <span className="link">{`${props.invoiceRef.name}${postfix}`}</span>
            </Link>
        </div>
    );
}

const OrderReference: React.FunctionComponent<{ orderRef: IOrderReference }> = (props) => {
    return (
        <div>
            <Link to={`/orders/detail/${props.orderRef.id}`}>
                <span className="link">{props.orderRef.name}</span>
            </Link>
        </div>
    );
}

const ContactReference: React.FunctionComponent<{ contactRef: IContactReference }> = (props) => {
    return (
        <div>
            <Link to={`/contacts/detail/${props.contactRef.id}`}>
                <span className="link">{props.contactRef.name}</span>
            </Link>
        </div>
    );
}

const UserReference: React.FunctionComponent<{ userRef: IUserReference }> = (props) => {
    return (
        <div>
            <Link to={`/users/detail/${props.userRef.id}`}>
                <span className="link">{props.userRef.name}</span>
            </Link>
        </div>
    );
}