import React from 'react';
import {Label} from "../components/Label";
import DialogTemplate from "../templates/DialogTemplate";
import hash from "../misc/hash";
import stringify from "json-stable-stringify";

interface ILinkData {
    src: string;
    name: string;
}

interface ILinkCourseDialogState {
    src: string;
    name: string;

    enableSrcError?: boolean;
    enableNameError?: boolean;
}

interface ILinkCourseDialogProps {
    heading: string;
    link: ILinkData;
    onCancel: () => void;
    onOk: (link: ILinkData) => void;
}

interface IFormFailure {
    field: string;
    message?: string;
}


export class LinkCourseDialog extends React.Component<ILinkCourseDialogProps, ILinkCourseDialogState> {

    private mounted = false;
    private readonly checksum: number;

    state: ILinkCourseDialogState;

    constructor(props: ILinkCourseDialogProps) {
        super(props);
        this.state = {
            src: props.link.src,
            name: props.link.name
        }

        this.checksum = this.calcChecksum(props.link);
        this.onKeyPressed = this.onKeyPressed.bind(this);
    }

    private calcChecksum(data: any) {
        const values: any = {};
        const fieldNames = ["src", "name"];
        fieldNames.forEach((field) => values[field] = data[field]?.toString().trim() ?? "");
        return hash(stringify(values));
    }

    componentDidMount() {
        // @ts-ignore
        document.addEventListener("keydown", this.onKeyPressed);
        this.mounted = true;
    }

    componentWillUnmount() {

        this.mounted = false;

        // @ts-ignore
        document.removeEventListener("keydown", this.onKeyPressed);
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (this.mounted && (e.key === 'Escape')) this.onCancel();
        if (this.mounted && (e.key === 'Enter')) {
            const checksum = this.calcChecksum(this.state);
            if (checksum !== this.checksum) {
                const failures = this.getFailures();
                if (failures.length === 0) this.onOk();
            }
        }
    }

    private onCancel() {
        this.props.onCancel();
    }

    private onOk() {
        const link = {src: this.state.src, name: this.state.name};
        this.props.onOk(link);
    }

    private getFailures() {
        const failures: IFormFailure[] = [];
        if (!this.state.name.trim()) failures.push({field: "name"});
        if (!this.state.src.trim()) {
            failures.push({field: "src"});
        } else {
            const src  = this.state.src.toLowerCase().trim();
            const valid = ["http", "https", "ftp", "mail", "tel"].some((x) => src.startsWith(`${x}://`));
            if (!valid) failures.push({field: "src"});
        }
        return failures;
    }

    private getContent() {

        const failures = this.getFailures();
        const hasFailure = (field: string) => failures.some((y) => y.field === field);

        let content: JSX.Element | undefined = undefined;
        content = (
            <div className="mt-4">
                <div>
                    <Label text="Name"/>
                </div>
                <div className="mt-1">
                    <input placeholder="Name" className={`input ${this.state.enableNameError && hasFailure("name") ? "is-danger" : ""}`} type="text"
                           value={this.state.name}
                           onChange={(e) => this.setState({name: e.target.value, enableNameError: true})}
                           onBlur={(e) => this.setState({enableNameError: true})}/>
                </div>
                <div className="mt-2">
                    <Label text="Ziel"/>
                </div>
                <div className="mt-1">
                    <input placeholder="Ziel" className={`input ${this.state.enableSrcError && hasFailure("src") ? "is-danger" : ""}`} type="text"
                           value={this.state.src}
                           onChange={(e) => this.setState({src: e.target.value, enableSrcError: true})}
                           onBlur={(e) => this.setState({enableSrcError: true})}/>
                </div>
                <div className="mt-1" style={{display: "flex", justifyContent: "flex-end"}}>
                    <button className="button is-text is-small" disabled={hasFailure("src")} onClick={() => {
                        window.open(this.state.src,'_blank');
                    }}>
                        Link testen
                    </button>
                </div>
            </div>
        );

        return (
            <div>
                {content}
            </div>
        );
    }

    private getButtons() {

        const failures = this.getFailures();
        const checksum = this.calcChecksum(this.state);

        return (
            <div className="buttons" style={{justifyContent: "flex-end"}}>
                <button className="button is-text" style={{minWidth: "120px"}} onClick={() => this.onCancel()}>Abbrechen
                </button>
                <button disabled={this.checksum === checksum || failures.length > 0} className="button is-purple"
                        style={{minWidth: "120px"}} onClick={() => this.onOk()}>Ok
                </button>
            </div>
        );
    }

    render() {
        return (
            <DialogTemplate
                content={this.getContent()}
                buttons={this.getButtons()}
                title={this.props.heading}
                onCancel={() => this.onCancel()}
            />
        );
    }
}
