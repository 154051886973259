import {
    IActivity,
    IActivityAdminUserData,
    IActivityAdminContactData,
    IActivityAssignmentData,
    IActivityAuthData,
    IActivityContactData,
    IActivityElearningData,
    IActivityInvoiceData, IActivityOrderData,
    IActivityUserData
} from "@institutsitya/sitya-common/types/model/activity";

import React from "react";
import {format} from "date-fns";

import {
    faCogs,
    faDownload,
    faEnvelope,
    faEuroSign,
    faFileInvoiceDollar,
    faKey,
    faLock,
    faLockOpen,
    faPause,
    faPlay,
    faPlusSquare,
    faQuestion,
    faSignInAlt,
    faUniversity
} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CourseAssignmentStyle} from "@institutsitya/sitya-common/types/model/user";
import {faPencil, IconDefinition} from "@fortawesome/pro-light-svg-icons";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {formatDate} from "../../misc/date";

interface IFormatter {

    getRowsData(): any[];

    getRows(idx: number): JSX.Element[];

    getTimestamp(): string;

    getUsername(): string;

    getIcon(): IconDefinition;

    getAction(): string;

    getDetails(rowsData: any): string;
}

export class FormatterFactory {

    public static getFormatter(activity: IActivity, courses: ICourse[]) {

        switch (activity.category as string) {
            case "assignment":
                return new AssignmentFormatter(activity, courses);

            case "admin-user":
            case "admin":
                return new AdminUserFormatter(activity);

            case "admin-contact":
                return new AdminContactFormatter(activity);

            case "elearning":
                return new ElearningFormatter(activity);

            case "user":
                return new UserFormatter(activity);

            case "order":
                return new OrderFormatter(activity, courses);

            case "auth":
                return new AuthFormatter(activity);

            case "contact":
                return new ContactFormatter(activity, courses);

            case "finance":
                return new InvoiceFormatter(activity);
        }

        return new BaseFormatter(activity);
    }
}

type AssignmentRowDataType = {
    key: string;
    mode: "added" | "changed" | "removed";
    style: string;
    styleChanged?: boolean;
    addedModules?: string[];
    removedModules?: string[];
}

class BaseFormatter implements IFormatter {

    protected activity: IActivity;

    constructor(activty: IActivity) {
        this.activity = activty;
    }

    public getRowsData(): any[] {
        return [0];
    }

    public getRows(idx: number) {

        const rows: JSX.Element[] = [];
        const rowsData = this.getRowsData();

        try {

            for (let i = 0; i < rowsData.length; i++) {

                rows.push(
                    <tr key={`row-${idx+i}`}>
                        <td>
                            <span className="icon is-small mr-2"><FontAwesomeIcon icon={this.getIcon()}
                                                                                  className="fa-sm"/></span>
                            <span className="nowrap mr-2">{this.getTimestamp()}</span>
                        </td>
                        <td><span className="nowrap mr-2">{this.getUsername()}</span></td>
                        <td><span className="nowrap mr-2">{this.getAction()}</span></td>
                        <td><span className="mr-2">{this.getDetails(rowsData[i])}</span></td>
                    </tr>);
            }

            return rows;
        } catch (err) {
            console.error(err);
        }

        return [];
    }

    public getIcon(): IconDefinition {
        return faQuestion;
    }

    public getTimestamp(): string {
        return format(new Date(this.activity.timestamp), "dd. LLL yyyy HH:mm:ss");
    }

    public getUsername(): string {
        return this.activity.mail;
    }

    public getAction(): string {
        return this.activity.category + "/" + this.activity.data.type;
    }

    public getDetails(idx: number): string {
        return "";
    }
}

class AdminUserFormatter extends BaseFormatter implements IFormatter {

    private data: IActivityAdminUserData;

    constructor(activty: IActivity) {
        super(activty);
        this.data = activty.data as IActivityAdminUserData;
    }

    public getUsername() {
        return this.data.admin;
    }

    public getIcon(): IconDefinition {
        switch (this.data.type) {
            case "pause":
                return faPause;
            case "unpause":
                return faPlay;
            case "lock":
                return faLock;
            case "unlock":
                return faLockOpen;
            case "create":
                return faPlusSquare;
            case "update":
                return faCogs;
            case "setpwd":
                return faKey;
        }

        return super.getIcon();
    }

    public getAction() {

        switch (this.data.type) {
            case "pause":
                return "Lernen pausiert";
            case "unpause":
                return "Lernen fortgesetzt";
            case "lock":
                return "Benutzer gesperrt";
            case "unlock":
                return "Benutzer entsperrt";
            case "create":
                return "Benutzer angelegt";
            case "update":
                return "Benutzer geändert";
            case "setpwd":
                return "Passwort gesetzt";
        }

        return super.getAction();
    }

    public getDetails(rowsData: any): string {

        if (this.data.fields && this.data.fields.length) {

            let friendlyNames = "";

            this.data.fields.forEach((f, idx) => {

                if (friendlyNames.length) {
                    if (idx === (this.data.fields!.length - 1)) friendlyNames += " und ";
                    else friendlyNames += ", ";
                }

                friendlyNames += getFieldName(f);
            });

            return friendlyNames;
        }

        return "";
    }
}

class AdminContactFormatter extends BaseFormatter implements IFormatter {

    private data: IActivityAdminContactData;

    constructor(activty: IActivity) {
        super(activty);
        this.data = activty.data as IActivityAdminContactData;
    }

    public getUsername() {
        return this.data.admin;
    }

    public getIcon(): IconDefinition {
        switch (this.data.type) {
            case "update":
                return faCogs;
        }

        return super.getIcon();
    }

    public getAction() {

        switch (this.data.type) {
            case "update":
                return "Kontakt geändert";
        }

        return super.getAction();
    }

    public getDetails(rowsData: any): string {

        if (this.data.fields && this.data.fields.length) {

            let friendlyNames = "";

            this.data.fields.forEach((f, idx) => {

                if (friendlyNames.length) {
                    if (idx === (this.data.fields!.length - 1)) friendlyNames += " und ";
                    else friendlyNames += ", ";
                }

                friendlyNames += getFieldName(f);
            });

            return friendlyNames;
        }

        return "";
    }
}

class OrderFormatter extends BaseFormatter implements IFormatter {

    private readonly data: IActivityOrderData;
    private readonly courses: ICourse[]

    constructor(activty: IActivity, courses: ICourse[]) {
        super(activty);
        this.courses = courses;
        this.data = activty.data as IActivityOrderData;
    }

    public getUsername() {
        return this.data.admin!;
    }

    public getIcon() {
        return faPencil;
    }

    public getRowCount() {
        return this.data.courses.length;
    }

    public getAction(): string {
        return "Änderung";
    }

    public getDetails(rowData: any) {
        const idx = rowData as number;
        const c = this.data.courses[idx];
        const name = getCourseName(this.courses, c.key);
        return `Studienstart ${name} ${formatDate(c.start)}`;
    }
}

class AssignmentFormatter extends BaseFormatter implements IFormatter {

    private data: IActivityAssignmentData;
    private courses: ICourse[];

    constructor(activty: IActivity, courses: ICourse[]) {
        super(activty);
        this.courses = courses;
        this.data = activty.data as IActivityAssignmentData;
    }

    public getUsername() {
        return this.data.admin;
    }

    public getIcon() {
        return faUniversity;
    }

    public getRowCount() {
        return this.data.courses.length;
    }

    public getAction(): string {
        return "Zuordnung";
    }

    public getRowsData(): AssignmentRowDataType[] {

        const result: AssignmentRowDataType[] = [];

        this.data.courses.forEach((c) => {
            if (c.mode === "removed") {
                result.push({
                    key: c.key,
                    mode: c.mode,
                    style: c.style
                });
            } else {

                if (c.styleChanged) {
                    result.push({
                        key: c.key,
                        mode: c.mode,
                        style: c.style,
                        styleChanged: true
                    });
                }

                // History still shows module assignment, which is currently no longer available
                // Since Feb 2024 only courses are assigned
                if (c.modules !== undefined) {
                    const added = c.modules
                        .filter((m) => m.mode === "added")
                        .map((m) => m.key).sort();

                    const removed = c.modules
                        .filter((m) => m.mode === "removed")
                        .map((m) => m.key).sort();

                    if (added.length) result.push({
                        key: c.key,
                        mode: c.mode,
                        style: c.style,
                        addedModules: added
                    });

                    if (removed.length) result.push({
                        key: c.key,
                        mode: c.mode,
                        style: c.style,
                        removedModules: removed
                    });
                } else {
                    if (c.mode === "added") {
                        result.push({
                            key: c.key,
                            mode: c.mode,
                            style: c.style
                        });
                    }
                }
            }
        });

        return result;
    }

    private getRangeText(keys: string[]) {
        let result = ""
        keys.forEach((k) => result += (k + ","));
        return result;
    }

    private getModuleSummary(modules: string[]) {

        // Array of modules for later range detected
        let a0x: number[] = [];

        // Flag if 'Semester-/Abschlussprüfung' have been detected
        let b01: boolean = false;
        let c01: boolean = false;

        // Preprocessing
        modules.forEach((m) => {

            if (m.startsWith("A") && m.length === 3) {
                const nbr = parseInt(m[2]);
                a0x.push(nbr);

            } else {
                if (m === "B01") b01 = true;
                if (m === "C01") c01 = true;
            }
        });

        // Array with the words to be used in the result, but without separators
        let parts: string[] = [];

        // Simple range detection, maybe not super elegant
        if (a0x.length === 1) parts.push(`Modul ${a0x[0]}`);
        else if (a0x.length === 2) {
            parts.push(`Modul ${a0x[0]}`);
            parts.push(`${a0x[1]}`);
        } else if (a0x.length > 2) {
            if (a0x[a0x.length - 1] - a0x[0] === a0x.length - 1) {
                parts.push(`Modul ${a0x[0]} bis ${a0x[a0x.length - 1]}`);
            } else {
                parts.push(`Modul ${a0x[0]}`);
                for (let i = 1; i < a0x.length; i++) parts.push(a0x[i].toString());
            }
        }

        // Simple add those two as well
        if (b01) parts.push("Semesterprüfung");
        if (c01) parts.push("Abschlussprüfung");

        // Combine the parts with grammatically correct separators
        let result = "";
        for (let i = 0; i < parts.length; i++) {

            if (result.length) {
                if (i === parts.length - 1) result += " und ";
                else result += ", ";
            }

            result += parts[i];
        }

        // Puh, we are done
        return result;
    }

    public getDetails(rowData: any) {

        const rowDataTyped = rowData as AssignmentRowDataType;

        const courseName = getCourseName(this.courses, rowDataTyped.key);

        let courseStyle = "";
        if (rowDataTyped.style === CourseAssignmentStyle.WithSupport) courseStyle = "mit Lernbegleitung";
        if (rowDataTyped.style === CourseAssignmentStyle.WithoutSupport) courseStyle = "im Selbststudium";

        // If a course has been deleted, no information about modules required
        if (rowDataTyped.mode === "removed") return `${courseName} ${courseStyle} entfernt`;

        if (rowDataTyped.addedModules) {
            const summary = this.getModuleSummary(rowDataTyped.addedModules);
            if (summary) return `${courseName} ${courseStyle} - ${summary}`;
        }

        if (rowDataTyped.removedModules) {
            const summary = this.getModuleSummary(rowDataTyped.removedModules);
            if (summary) return `${courseName} ${courseStyle} - ${summary} entfernt`;
        }

        if (rowDataTyped.styleChanged) {
            return `${courseName} ${courseStyle} - Studienart geändert`;
        }

        if (rowDataTyped.mode === "added") return `${courseName} ${courseStyle}`;

        return `${courseName} ${courseStyle} geändert`;
    }
}

class ElearningFormatter extends BaseFormatter implements IFormatter {

    private data: IActivityElearningData;

    constructor(activty: IActivity) {
        super(activty);
        this.data = activty.data as IActivityElearningData;
    }

    public getIcon() {
        return faDownload;
    }

    public getAction() {
        switch (this.data.type) {
            case "download":
                return "Dokument geöffnet";
            case "preview":
                return "Dokument betrachtet";
        }
        return super.getAction();
    }

    public getDetails(data: any) {
        return this.data.file;
    }
}

class UserFormatter extends BaseFormatter implements IFormatter {

    private data: IActivityUserData;

    constructor(activty: IActivity) {
        super(activty);
        this.data = activty.data as IActivityUserData;
    }

    public getIcon() {
        if ((this.data.type === "changepwd") || (this.data.type === "resetpwd")) return faKey;
        return super.getIcon();
    }

    public getAction(): string {
        switch (this.data.type) {
            case "changepwd":
                return "Passwort geändert";
            case "resetpwd":
                return "Passwort zurückgesetzt";
        }

        return super.getAction();
    }
}

class AuthFormatter extends BaseFormatter implements IFormatter {

    private data: IActivityAuthData;

    constructor(activty: IActivity) {
        super(activty);
        this.data = activty.data as IActivityAuthData;
    }

    public getIcon() {
        return faSignInAlt;
    }

    public getAction() {
        if (this.data.type === "pwd") return "Login";
        else if (this.data.type === "register") return "Registrierung";
        return this.data.type;
    }
}

class InvoiceFormatter extends BaseFormatter implements IFormatter {

    private data: IActivityInvoiceData;

    constructor(activty: IActivity) {
        super(activty);
        this.data = activty.data as IActivityInvoiceData;
    }

    public getIcon() {
        return faFileInvoiceDollar;
    }

    public getUsername() {
        return this.data.admin || this.activity.mail;
    }

    public getAction() {
        return "Rechnung";
    }

    public getDetails(idx?: number) {

        switch (this.data.type) {
            case "invoice":
                return `Rechnung ${this.data.invoice.name} zu Bestellung ${this.data.order.name}`;

            case "cancellation":
                return `Storno ${this.data.invoice.name} zu Bestellung ${this.data.order.name}`;

            case "refund":
                return `Gutschrift ${this.data.invoice.name} zu Bestellung ${this.data.order.name}`;

            case "payment":
                return `Geldeingang ${this.data.invoice.name} zu Bestellung ${this.data.order.name}`;
        }

        return "";
    }
}

class ContactFormatter extends BaseFormatter implements IFormatter {

    private data: IActivityContactData;
    private courses: ICourse[];

    constructor(activty: IActivity, courses: ICourse[]) {
        super(activty);
        this.courses = courses;
        this.data = activty.data as IActivityContactData;
    }

    public getIcon() {
        if (this.data.type === "hubspot") {
            switch (this.data.detail) {
                case "request":
                case "enquiry":
                case "certificate":
                    return faEnvelope;

                case "order": {
                    return faEuroSign;
                }
            }
        }

        return faEnvelope;
    }

    public getAction() {

        if (this.data.type === "contact") return "Kontaktformular";

        switch (this.data.detail) {
            case "request":
            case "enquiry":
                return "Anfrage";

            case "certificate":
                return "Zertifikatsantrag";

            case "order": {
                return "Anmeldung";
            }
        }

        return super.getAction();
    }

    public getRowsData() {

        const courses = this.data.courses;
        return (courses && courses.length) ? courses : [undefined];
    }

    public getDetails(data: any) {
        if (this.data.type === "contact") {
            const key = data ? data as string : undefined;
            const course = getCourseName(this.courses, key);
            const question = getQuestionName(this.data.detail);
            if (course) return `${course} - ${question}`;
            return question;
        }

        if (this.data.type === "hubspot") {
            const key = data ? data as string : undefined;
            return getCourseName(this.courses, key);
        }

        return "";
    }
}

function getCourseKey(key?: string) {
    switch (key) {
        case "humanenergetiker":
            return "humanenergetiker-6";
        default:
            return key;
    }
}

function getCourseName(courses: ICourse[], key?: string) {
    const k = getCourseKey(key);
    if (!k) return "";
    const course = courses.find((x) => x.key === k);
    if (!course) return k;
    return course.name;
}

function getQuestionName(key?: string) {
    switch (key) {
        case "01":
            return "Lernfrage";

        case "02":
            return "Frage zu Wochentest";

        case "03":
            return "Wochentest pausieren";

        case "04":
            return "Lernen pausieren";

        case "05":
            return "Semester-/Abschlussprüfung";

        case "06":
            return "Technisches Problem";

        case "07":
            return "Sonstige Anfrage";
    }

    return key || "";
}

function getFieldName(key?: string) {
    switch (key) {
        case "name":
        case "fullName":
            return "Name";

        case "firstName":
            return "Vorname";

        case "lastName":
            return "Nachname";

        case "mail":
            return "E-Mail-Adresse";

        case "phone":
            return "Telefon";

        case "gender":
            return "Geschlecht";

        case "role":
            return "Rolle";

        case "title":
            return "Titel";

        case "birthday":
            return "Geburtstag";

        case "street":
            return "Straße";

        case "city":
            return "Ort";

        case "country":
            return "Land";

        case "postalCode":
            return "PLZ";

        case "nomails":
            return "Mailerhalt";
    }

    return key || "";
}

