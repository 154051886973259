import React from 'react';
import {Link} from 'react-router-dom';
import failureController from "../../controller/failure";
import SearchText from "../../components/Search";
import {TextMarker} from "../../components/TextMarker";
import {Amount} from "../../components/Amount";
import ListViewTemplate from "../../templates/ListViewTemplate";
import {ICourse} from "@institutsitya/sitya-common/types/model/course";
import {getCourses} from "../../controller/courses";
import {Pagination} from "../../components/Pagination";
import navigationController from "../../controller/navigation";

interface ICourseListViewProps {
}

interface ICourseListViewState {
    filter: string;
    busy: boolean;
    redirect?: string;
    records: ICourse[];
    stats?: any;
}

let lastFilter: string = "";

export class CourseListView extends React.Component<ICourseListViewProps, ICourseListViewState> {

    private mounted = false;

    state: ICourseListViewState = {
        filter: lastFilter,
        busy: true,
        records: [],
    };

    componentDidMount() {
        this.mounted = true;
        this.fetch(this.state.filter);
    }

    private async fetch(filter: string) {

        try {

            let courses = await getCourses();

            if (filter && filter.length) {
                const parts = filter.split(" ");
                courses = courses.filter((c) => {
                    return parts.every((p) => c.name.toLowerCase().indexOf(p.trim().toLowerCase()) >= 0);
                });
            }

            if (this.mounted) {

                this.setState({
                    busy: false,
                    records: courses,
                    filter: filter
                });
            }

        } catch (error) {
            failureController.failure("CourseListView.tsx/fetch", error);
            this.setState({busy: false, records: []});
        }
    }

    private selectRecord(record: ICourse) {
        const url = `/courses/detail/${record.key}`;

        navigationController
          .reset()
          .records(this.state.records.map((r) => r.key!));

        this.setState({redirect: url});
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    private getButtons() {
        return (
            <Link to="/courses/detail/neu" className="button is-purple">
                Neuen Kurs anlegen
            </Link>
        );
    }

    private getHeader() {
        return (
            <tr>
                <th>Kurs</th>
            </tr>
        );
    }

    private getRows() {

        const rows = this.state.records.map((record) => {

            return (
                <tr key={record._id} onClick={() => this.selectRecord(record)} style={{cursor: "pointer"}}>
                    <td><TextMarker text={record.name} highlight={this.state.filter}/></td>
                </tr>
            );
        });

        return rows;
    }

    private getInfo() {
        if (!this.state.records) return undefined;
        return <Amount value={this.state.records.length} singular="Kurs" plural="Kurse"/>
    }

    private getFilter() {
        return (
            <SearchText filter={this.state.filter} hint="Suche nach Kurs"
                        onTextChanged={(filter) => {
                            this.applyFilter(filter)
                        }} autoFocus={true}/>
        );
    }

    private applyFilter(filter: string) {
        lastFilter = filter;
        this.fetch(filter)
    }

    private getPagination() {

        if (!this.state.records) return undefined;

        return (
            <Pagination
                disabled={this.state.busy}
                limit={100}
                skip={0}
                count={this.state.records.length}
                onChange={(limit: number, skip: number) => {}}
            />
        );
    }

    render() {
        return (<ListViewTemplate
                busy={this.state.busy}
                pagination={this.getPagination()}
                buttons={this.getButtons()}
                filter={this.getFilter()}
                redirect={this.state.redirect}
                header={this.getHeader()}
                info={this.getInfo()}
                rows={this.getRows()}/>
        );
    }
}