import stringify from "json-stable-stringify";
import hash from "../misc/hash";

export interface IFilter {
  name: string;
  value: any;
}

export interface IPreset {
  key: string;
  name: string;
  filter: IFilter[];
}

export interface ISelector {
  key: string;
  name: string;
  values: { key: string, value: string }[];
}

export interface IDates {
  mode: "range" | "date";
  key: string;
  start: string;
  end: string;
  name: string;
}

export interface IFilterController {

  clear: () => void;

  addPreset: (key: string, name: string, filter: IFilter[]) => void;
  setPreset: (key: string) => void;
  getPreset: () => string | undefined;
  getPresets: () => IPreset[];

  addSelector: (selector: ISelector) => void;
  getSelectors: () => ISelector[];

  addDate: (date: IDates) => void;
  getDates: () => IDates[];

  setValue: (key: string, value: string | undefined) => void;
  setValues: (values: { key: string, value: string | undefined }[]) => void;
  getValue: (key: string) => string;

  getFilter: () => IFilter[];

  getText: () => string;
  setText: (name: string) => void;

  addHandler: (observer: IFilterObserver) => void;
  removeHandler: (observer: IFilterObserver) => void;
}

export interface IFilterObserver {
  onFilterChanged: () => void;
}

export class FilterController implements IFilterController {

  private observers: IFilterObserver[] = [];
  private presets: IPreset[] = [];
  private filter: IFilter[] = [];

  private preset: string | undefined = undefined;
  private text: string = "";
  private values: { [id: string]: string } = {};

  private selectors: ISelector[] = [];
  private dates: IDates[] = [];

  private readonly name: string;

  public constructor(name: string) {
    this.name = name;
    this.load();
  }

  public addHandler(observer: IFilterObserver) {
    this.observers.push(observer);
  }

  public removeHandler(observer: IFilterObserver) {
    const index = this.observers.findIndex((x) => x === observer);
    if (index >= 0) this.observers.splice(index, 1);
  }

  public addPreset(key: string, name: string, filter: IFilter[]) {
    this.presets.push({
      key: key,
      name: name,
      filter: filter
    });
  }

  public getPresets(): IPreset[] {
    return this.presets;
  }

  public getFilter(): IFilter[] {
    const filter: IFilter[] = [];

    if (this.text) filter.push({name: "text", value: this.text});

    if (this.preset) {
      const preset = this.presets.find((x) => x.key === this.preset);
      if (preset) preset.filter.forEach((f) => filter.push({name: f.name, value: f.value}));
    } else {
      for (const [key, value] of Object.entries(this.values)) filter.push({name: key, value: value});
    }

    return filter;
  }

  public getText(): string {
    return this.text;
  }

  public setText(text: string) {
    text = text.trim();
    if (text !== this.text) {
      this.text = text;
      this.save();
      this.sendOnFilterChanged();
    }
  }

  public setPreset(key: string): void {
    if (key !== this.preset) {
      this.preset = key;
      this.save();
      this.sendOnFilterChanged();
    }
  }

  private sendOnFilterChanged() {
    this.observers.forEach((o) => {
      if (o.onFilterChanged) o.onFilterChanged();
    });
  }

  public addSelector(selector: ISelector): void {
    this.selectors.push(selector);
  }

  public getSelectors(): ISelector[] {
    return this.selectors;
  }

  public getValue(key: string): string {
    return this.values[key];
  }

  public setValue(key: string, value: string | undefined) {
    this.setValues([{key: key, value: value}]);
  }

  public setValues(values: { key: string; value: string | undefined }[]) {

    const old = this.calcChecksum();

    this.preset = "";

    values.forEach((v) => {
      if (v.value) this.values[v.key] = v.value;
      else delete this.values[v.key];
    });

    if (this.calcChecksum() !== old) {
      this.save();
      this.sendOnFilterChanged();
    }
  }

  public addDate(date: IDates): void {
    this.dates.push(date);
  }

  public getDates(): IDates[] {
    return this.dates;
  }

  public getPreset(): string | undefined {
    return this.preset;
  }

  public clear() {

    const old = this.calcChecksum();

    this.preset = "";
    this.text = "";
    this.values = {};

    if (this.calcChecksum() !== old) {
      this.save();
      this.sendOnFilterChanged();
    }
  }

  private load() {

    try {

      const json = window.localStorage.getItem(`${this.name}.filter`);
      if (json) {
        const data = JSON.parse(json);
        if (data.text) this.text = data.text.toString().trim();
        if (data.preset) this.preset = data.preset.toString();
        if (data.values) this.values = data.values;
      }
    } catch (e) {
    }
  }

  private calcChecksum() {
    const value = stringify(this.getData());
    return hash(value);
  }

  private getData() {
    return ({
      text: this.text,
      preset: this.preset,
      values: this.values
    });
  }

  private save() {
    window.localStorage.setItem(`${this.name}.filter`, JSON.stringify(this.getData()));
  }
}
