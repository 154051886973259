import React from "react";

interface ILabelProps extends React.HTMLAttributes<HTMLSpanElement> {
    text: string;
}

export const Label: React.FunctionComponent<ILabelProps> = (props) => {

    let { text, className, ...rest } = props;

    if (!className) className = "value-label";
    else className += " value-label";

    return (
        <span className={className} {...rest} style={{

        }}>{props.text}</span>
    );
}