import React from 'react';
import {BrowserRouter, Link, Navigate, Route, Routes} from "react-router-dom";

import {getAuthController, AuthChangedArgs, IAuthObserver, ISystemUser} from './controller/auth';
import {Spinner} from "./components/Spinner";
import {LoginView} from "./views/auth/LoginView";
import {LogoutView} from "./views/auth/LogoutView";
import {OrderListView} from "./views/order/OrderListView";
import {OrderDetailViewWrapper} from "./views/order/OrderDetailView";
import {FailureDialog} from "./components/FailureDialog";
import {InvoiceListView} from "./views/invoice/InvoiceListView";
import {InvoiceDetailViewWrapper} from "./views/invoice/InvoiceDetailView";
import {ContactListView} from "./views/contact/ContactListView";
import {ContactDetailViewWrapper} from "./views/contact/ContactDetailView";
import {Logo} from "./components/Logo";
import {TopNav} from "./components/Navigation";
import {UserListView} from "./views/user/UserListView";
import {UserDetailViewWrapper} from "./views/user/UserDetailView";
import {UserActivityViewWrapper} from "./views/user/UserActivityView";
import {CourseListView} from "./views/course/CourseListView";
import {CourseDetailViewWrapper} from "./views/course/CourseDetailView";
import UAParser from "ua-parser-js";
import {HubDbStatusView} from "./views/hubdb/HubDbStatusView";

interface IAppState {
    initialized: boolean;
    user: ISystemUser | undefined;
    hint: boolean;
    browser: string;
    os: string;
}

export class App extends React.Component<{}, IAppState> implements IAuthObserver {

    public state: IAppState = {
        initialized: false,
        hint: true,
        browser: "",
        os: "",
        user: undefined
    }

    public componentDidMount() {

        const parser = new UAParser();
        const os = parser.getOS();
        const browser = parser.getBrowser();

        this.setState({browser: browser.name ?? "", os: os.name ?? ""});

        getAuthController().addHandler(this);
        getAuthController().login()
            .then((systemuser) => this.setState({initialized: true}))
            .catch((error) => this.setState({initialized: true}));
    }

    public onAuthChanged(args: AuthChangedArgs) {
        this.setState({user: args.user});
    }

    public render() {

        if (!this.state.initialized) return <div style={{
            width: "100vw",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        }}><Spinner text="E-Learning Admin Portal wird initialisiert"/></div>;

        let hint: JSX.Element | null = null;

        if (this.state.hint) {

            const location = window.location.href.toLowerCase().trim();

            let environment = "lokalen Entwicklungsumgebung";
            if (location.indexOf("minaxus.dev") >= 0) environment = "Testumgebung";
            if (location.indexOf("institut-sitya") >= 0) environment = "";

            if (environment) {
                hint = (
                    <div className="px-4" style={{
                        height: "2rem",
                        width: "100%",
                        background: "#ec6608",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div style={{color: "white"}}>
                            {`Sie befinden sich auf der ${environment}`}
                        </div>
                        <button className="delete" onClick={() => this.setState({hint: false})}></button>
                    </div>
                );
            }
        }

        if (getAuthController().isLoggedIn()) {

            let cn = "app";
            if (this.state.browser) cn += (" " + this.state.browser.toLowerCase());
            if (this.state.os) cn += (" " + this.state.os.toLowerCase());

            return (
                <BrowserRouter>

                    <FailureDialog/>

                    <div className={cn} style={{display: "grid", gridTemplateRows: "auto 1fr auto", minHeight: "100vh"}}>

                        {/* Header */}
                        <div>
                            {hint}
                            <div className="px-4 mt-4" style={{borderBottom: "2px solid #f5f5f5"}}>
                                <div className="container">
                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-end", height: "6.5rem"}}>
                                        <div style={{marginBottom: "0.1rem"}}>
                                            <Link to="/">
                                                <Logo width="7rem"/>
                                            </Link>
                                        </div>
                                        <div className="mb-1">
                                            <TopNav />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Content */}
                        <div className="mx-4">
                            <Routes>
                                <Route path="/login" element={<Navigate to="/"/>}/>
                                <Route path="/orders/list" element={<OrderListView/>}/>
                                <Route path="/orders/detail/:uid" element={<OrderDetailViewWrapper/>}/>

                                <Route path="/invoices/list" element={<InvoiceListView/>}/>
                                <Route path="/invoices/detail/:uid" element={<InvoiceDetailViewWrapper/>}/>

                                <Route path="/contacts/list" element={<ContactListView/>}/>
                                <Route path="/contacts/detail/:uid" element={<ContactDetailViewWrapper/>}/>

                                <Route path="/users/list" element={<UserListView/>}/>
                                <Route path="/users/detail/:uid" element={<UserDetailViewWrapper/>}/>
                                <Route path="/users/detail/:uid/log" element={<UserActivityViewWrapper/>}/>

                                <Route path="/courses/list" element={<CourseListView/>}/>
                                <Route path="/courses/detail/:key" element={<CourseDetailViewWrapper/>}/>


                                <Route path="/hubdb" element={<HubDbStatusView/>}/>

                                <Route path="/logout" element={<LogoutView/>}/>
                                <Route path="/" element={<Navigate to="/orders/list"/>}/>
                                <Route path="*" element={<div/>}/>
                            </Routes>
                        </div>

                        {/* Footer */}
                        <div className="pt-1" style={{height: "4rem", marginTop: "2rem", borderTop: "2px solid #f5f5f5"}}>
                            <div className="mx-4">
                                <div className="container" style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
                                    <div>
                                        <span>Institut SITYA E-Learning Admin Portal</span>
                                        <span> von </span>
                                        <a href="https://minaxus.at" target="_blank" rel="noreferrer"><span className="link">Minaxus</span></a>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                                        <div>{this.state.user?.name}</div>
                                        <div><Link to="/logout"><span className="link">Abmelden</span></Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </BrowserRouter>
            );

        } else {
            return (
                <div>
                    <BrowserRouter>

                        <FailureDialog/>

                        <Routes>
                            <Route path="/logout" element={<Navigate to="/"/>}/>
                            <Route path="*" element={<LoginView/>}/>
                        </Routes>
                    </BrowserRouter>
                </div>
            );
        }
    }
}
